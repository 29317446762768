import React, { useState, useEffect } from 'react';
import AbhisharCard2 from '../../components/abhisharCardComponent/AbhisharCard2';
import AbhisharData from './abhisharData.json';
import photo1 from '../../assets/Images/late-abhinav-saxena.webp';
import photo2 from '../../assets/Images/late-tushar-sharma.webp';

import './abhisharScreen.css';

const CardsPerPage = 4;
function AbhisharScreen() {
  const [currentPage, setCurrentPage] = useState(1);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 500);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const totalPages = Math.ceil(AbhisharData.length / CardsPerPage);

  const startIndex = (currentPage - 1) * CardsPerPage;
  const endIndex = startIndex + (isMobile ? CardsPerPage : AbhisharData.length);
  const visibleCards = AbhisharData.slice(startIndex, endIndex);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div>
      <div>
        <div className='abhisharpage-section'>
          <div className='abhisharpage-content-box'>
            <div className='abhisharpage-headings '>
              Unveiling the Heart and Soul of Abhishar
            </div>
            <div className='abhisharpage-text'>
              Abhishar is the annual magazine of AASF, embodying companionship,
              and evolving from the names of our late AASF members - Abhinav
              Saxena and Tushar Sharma - two indispensable driving forces behind
              AASF&apos;s endeavors.
            </div>
          </div>
          <div className='abhisharpage-image'>
            <div className='photo1'>
              <img src={photo1} alt='' />
              <div> Late Abhinav Saxena</div>
            </div>
            <div className='photo2'>
              <img src={photo2} alt='' />
              <div>Late Tushar Sharma</div>
            </div>
          </div>
        </div>
      </div>

      <div className='previous-abhishar-container'>
        <div className='all-abhishars'>Echoes of Abhishar</div>
        <div
          className={`abhishar-container  ${
            isMobile ? 'abhishar-container-mobile' : ''
          }`}
        >
          <div>
            <div className='abhisharpage-card-container'>
              {visibleCards.map((card) => (
                <AbhisharCard2
                  key={card.key}
                  image={card.image}
                  heading={card.heading}
                  content={card.content}
                  link={card.link}
                  version={card.version}
                />
              ))}
              {isMobile && (
                <div className='abhishar-pagination'>
                  {Array.from({ length: totalPages }, (_, index) => (
                    <button
                      key={index}
                      onClick={() => handlePageChange(index + 1)}
                      className={currentPage === index + 1 ? 'active' : ''}
                    >
                      {index + 1}
                    </button>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AbhisharScreen;
