import React, { useEffect, useState } from 'react';
import './navbar.css';
import AASFLogo from '../../assets/svgIcons/aasflogo';
import { NavLink } from 'react-router-dom';
import CloseVector from '../../assets/svgIcons/closeVector';

function Navbar() {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [scroll, setScroll] = useState(false);

  const handleOpenNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  const handleCloseNav = () => {
    setIsNavOpen(false);
  };

  const isActiveClass = (isActive) => {
    const activeClassName = isActive ? 'navbar-active' : '';
    return activeClassName;
  };

  const handleNavLinkClick = () => {
    if (isNavOpen) {
      handleOpenNav();
    }
  };
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 1000);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 1000);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  useEffect(() => {
    window.addEventListener('scroll', () => {
      setScroll(window.scrollY > 120);
    });
  }, []);

  return (
    <div
      className={
        scroll
          ? 'navbar-container navbar-backdrop'
          : 'navbar-container navbar-opaque'
      }
    >
      <div
        className={isNavOpen ? 'navbar-main-container' : ''}
        onClick={() => handleCloseNav()}
      />
      <nav
        className='navbar secondary-font flex-centre'
        onClick={(e) => e.stopPropagation()}
      >
        <div className='nav-container flex-centre'>
          <div className='nav'>
            <div className='nav-logo'>
              {isMobileView ? (
                <NavLink exact to='/'>
                  <AASFLogo width={27.81} height={32} />
                </NavLink>
              ) : (
                <NavLink exact to='/'>
                  <AASFLogo width={41} height={48} />
                </NavLink>
              )}
            </div>

            <ul
              className={
                isNavOpen ? 'nav-menu flex-centre navbar-active' : 'nav-menu'
              }
            >
              <li className='nav-item'>
                <NavLink
                  exact='true'
                  to='/'
                  className={({ isActive }) => isActiveClass(isActive)}
                  onClick={handleNavLinkClick}
                >
                  Home
                </NavLink>
              </li>
              <li className='nav-item'>
                <NavLink
                  exact='true'
                  to='/abhishar'
                  className={({ isActive }) => isActiveClass(isActive)}
                  onClick={handleNavLinkClick}
                >
                  Abhishar
                </NavLink>
              </li>
              <li className='nav-item'>
                <NavLink
                  exact='true'
                  to='/events'
                  className={({ isActive }) => isActiveClass(isActive)}
                  onClick={handleNavLinkClick}
                >
                  Events
                </NavLink>
              </li>
              <li className='nav-item'>
                <NavLink
                  exact='true'
                  to='/team'
                  className={({ isActive }) => isActiveClass(isActive)}
                  onClick={handleNavLinkClick}
                >
                  Team
                </NavLink>
              </li>

              <li className='button-contact'>
                <NavLink
                  className='contact-button-mobile'
                  exact='true'
                  to='/contact'
                  onClick={handleNavLinkClick}
                >
                  Contact Us
                </NavLink>
              </li>
              <li className='close-button' onClick={handleOpenNav}>
                {isNavOpen ? <CloseVector /> : ''}
              </li>
            </ul>

            <NavLink
              className='contact-button'
              exact='true'
              to='/contact'
              onClick={handleNavLinkClick}
            >
              Contact Us
            </NavLink>
          </div>
          <div className='nav-icon' onClick={handleOpenNav}>
            <i className={isNavOpen ? '' : 'fa fa-bars'}></i>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
