import React from 'react';

const GetInTouch = () => {
  return (
    <svg
      width='551'
      height='500'
      viewBox='0 0 448 312'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M131.569 128.64C154.049 99.0006 142.589 29.4979 184.657 17.7693C222.961 7.10685 232.915 66.1532 259.706 73.0166C293.037 81.5555 358.633 17.5273 396.148 58.5104C432.499 98.2121 303.86 201.942 255.154 221.735C177.838 253.166 39.2627 241.877 43.8054 184.246C46.8518 145.557 112.197 154.176 131.569 128.64Z'
        fill='#407BFF'
      />
      <path
        opacity='0.9'
        d='M131.569 128.64C154.049 99.0006 142.589 29.4979 184.657 17.7693C222.961 7.10685 232.915 66.1532 259.706 73.0166C293.037 81.5555 358.633 17.5273 396.148 58.5104C432.499 98.2121 303.86 201.942 255.154 221.735C177.838 253.166 39.2627 241.877 43.8054 184.246C46.8518 145.557 112.197 154.176 131.569 128.64Z'
        fill='#DFDFDF'
      />
      <path
        d='M219.681 292.644C315.627 292.644 393.407 288.103 393.407 282.501C393.407 276.9 315.627 272.359 219.681 272.359C123.735 272.359 45.9558 276.9 45.9558 282.501C45.9558 288.103 123.735 292.644 219.681 292.644Z'
        fill='#F5F5F5'
      />
      <path
        d='M288.315 88.2666C285.905 85.7578 284.193 81.0986 283.36 78.097L279.552 87.1824C280.164 87.8893 280.608 88.7259 280.85 89.6292C281.092 90.5324 281.126 91.4788 280.95 92.3972C280.95 92.4778 280.815 94.001 278.262 95.56C278.262 95.56 278.719 104.753 284.193 103.454C289.668 102.155 292.643 91.2413 292.643 91.2413L293.037 88.1501C293.037 88.1501 290.546 90.2468 288.315 88.2666Z'
        fill='#FFC3BD'
      />
      <path
        opacity='0.1'
        d='M280.511 84.9604L279.615 87.1824C280.331 88.0415 280.818 89.068 281.03 90.1661C283.539 87.2093 283.817 82.4247 283.817 82.4247L280.511 84.9604Z'
        fill='black'
      />
      <path
        d='M240.764 129.178C240.764 129.178 255.539 129.778 262.098 127.986C268.657 126.194 274.23 114.717 274.23 114.717L266.748 110.586L259.311 121.652L240.782 124.617L235.46 120.872C235.46 120.872 224.708 118.578 224.708 119.976C224.708 121.374 230.254 122.503 230.254 122.503C230.254 122.503 230.586 129.115 232.861 129.321C235.137 129.527 240.764 129.178 240.764 129.178Z'
        fill='#FFC3BD'
      />
      <path
        d='M276.927 97.576C276.927 97.576 270.529 98.472 266.345 106.222C262.161 113.973 265.736 114.286 269.275 116.473C272.814 118.659 274.588 119.161 276.219 114.376C277.85 109.591 281.066 97.0832 276.927 97.576Z'
        fill='#407BFF'
      />
      <path
        opacity='0.1'
        d='M276.909 112.19L274.534 107.351L270.547 107.047C270.354 110.47 270.423 113.902 270.753 117.315C273.343 118.668 274.839 118.399 276.219 114.358C276.425 113.749 276.667 113.014 276.909 112.19Z'
        fill='black'
      />
      <path
        d='M264.168 66.6999C264.168 66.6999 259.508 68.3485 262.591 73.0884C265.673 77.8282 259.455 81.0538 269.893 82.362C280.331 83.6701 270.26 64.7914 270.26 64.7914L264.168 66.6999Z'
        fill='#263238'
      />
      <path
        opacity='0.3'
        d='M264.168 66.6999C264.168 66.6999 259.508 68.3485 262.591 73.0884C265.673 77.8282 259.455 81.0538 269.893 82.362C280.331 83.6701 270.26 64.7914 270.26 64.7914L264.168 66.6999Z'
        fill='black'
      />
      <path
        d='M304.3 96.4471C302.74 86.3044 296.836 86.4836 291.084 88.7504C291.487 92.5674 291.496 97.4416 285.967 100.479C280.995 103.167 279.059 98.6871 278.952 94.906C273.737 97.8717 269.382 101.671 270.807 109.242C271.622 113.543 275.421 118.265 279.31 124.259C283.96 131.427 288.745 139.563 288.745 139.563L315.32 126.033C315.32 126.033 305.993 107.432 304.3 96.4471Z'
        fill='#407BFF'
      />
      <path
        opacity='0.2'
        d='M304.3 96.4471C304.111 95.1395 303.79 93.8546 303.341 92.6122L299.318 91.9312L297.221 95.2644C297.221 95.2644 301.656 103.077 307.552 108.014C306.112 104.268 305.024 100.395 304.3 96.4471Z'
        fill='black'
      />
      <path
        d='M311.405 93.2215C311.405 93.2215 320.526 99.4935 323.313 101.868C327.461 105.452 338.634 117.521 338.634 117.521L345.489 121.266V125.603L342.245 127.843C342.008 128.007 341.741 128.121 341.459 128.181C341.178 128.24 340.887 128.243 340.604 128.188C340.322 128.134 340.052 128.024 339.813 127.865C339.573 127.706 339.367 127.5 339.208 127.261L335.05 121.051C335.05 121.051 324.8 111.688 321.028 109.018C317.838 106.76 307.498 101.214 307.498 101.214L311.405 93.2215Z'
        fill='#FFC3BD'
      />
      <path
        d='M294.569 87.8096C294.569 87.8096 300.447 85.1664 305.455 87.8096C310.464 90.4528 312.677 91.9044 312.184 95.0941C311.692 98.2839 309.855 104.878 306.557 103.785C303.26 102.692 292.741 92.2538 294.569 87.8096Z'
        fill='#407BFF'
      />
      <path
        d='M284.749 70.5348C285.43 77.4071 285.869 80.2922 283.002 84.3332C278.683 90.417 270.099 89.754 267.384 83.2311C264.929 77.3533 264.23 67.1031 270.449 63.3399C271.813 62.4892 273.374 62.0029 274.98 61.9276C276.586 61.8522 278.185 62.1903 279.624 62.9095C281.062 63.6288 282.292 64.705 283.196 66.0354C284.099 67.3657 284.646 68.9056 284.785 70.5079L284.749 70.5348Z'
        fill='#FFC3BD'
      />
      <path
        d='M270.314 75.7853C270.026 77.1274 269.556 78.4237 268.916 79.6381C269.229 79.7961 269.572 79.8836 269.922 79.8944C270.272 79.9053 270.62 79.8392 270.941 79.7009L270.314 75.7853Z'
        fill='#ED893E'
      />
      <path
        d='M274.902 71.1978C274.943 71.1745 274.977 71.1405 275 71.0992C275.034 71.037 275.042 70.9643 275.024 70.896C275.005 70.8278 274.962 70.7691 274.902 70.7319C274.463 70.4667 273.967 70.3111 273.455 70.2784C272.944 70.2456 272.432 70.3366 271.963 70.5437C271.899 70.5797 271.851 70.6387 271.83 70.7087C271.808 70.7787 271.814 70.8543 271.846 70.92C271.879 70.9826 271.935 71.0298 272.002 71.0516C272.069 71.0734 272.142 71.068 272.205 71.0365C272.596 70.8717 273.021 70.802 273.445 70.8332C273.869 70.8643 274.279 70.9954 274.642 71.2157C274.683 71.2359 274.729 71.2448 274.774 71.2417C274.82 71.2385 274.864 71.2234 274.902 71.1978Z'
        fill='#263238'
      />
      <path
        d='M264.643 72.936L264.759 72.8464C265.025 72.5204 265.364 72.2614 265.748 72.0902C266.133 71.919 266.552 71.8404 266.972 71.8608C267.045 71.8711 267.118 71.8524 267.177 71.8088C267.236 71.7652 267.275 71.7002 267.286 71.6279C267.289 71.5551 267.265 71.4838 267.219 71.4276C267.172 71.3714 267.107 71.3342 267.035 71.3232C266.525 71.295 266.016 71.3875 265.549 71.593C265.082 71.7985 264.67 72.1113 264.347 72.506C264.297 72.5577 264.269 72.6268 264.269 72.6986C264.269 72.7704 264.297 72.8395 264.347 72.8912C264.387 72.9258 264.435 72.949 264.487 72.9585C264.539 72.9679 264.593 72.9633 264.643 72.945V72.936Z'
        fill='#263238'
      />
      <path
        d='M274.077 74.28C274.167 74.8266 273.943 75.3194 273.576 75.3821C273.208 75.4448 272.85 75.0506 272.769 74.4861C272.689 73.9216 272.895 73.4468 273.253 73.384C273.612 73.3213 273.979 73.6887 274.077 74.28Z'
        fill='#263238'
      />
      <path
        d='M267.814 75.2567C267.904 75.8032 267.68 76.2961 267.322 76.3588C266.963 76.4215 266.605 76.0183 266.506 75.4628C266.408 74.9073 266.641 74.4144 266.999 74.3607C267.357 74.3069 267.734 74.6922 267.814 75.2567Z'
        fill='#263238'
      />
      <path
        d='M267.16 74.3607L265.771 74.1994C265.771 74.1994 266.623 75.1223 267.16 74.3607Z'
        fill='#263238'
      />
      <path
        d='M273.423 73.3841L272.035 73.2228C272.035 73.2228 272.886 74.1098 273.423 73.3841Z'
        fill='#263238'
      />
      <path
        d='M275.412 82.2455C275.753 82.0796 276.077 81.8816 276.38 81.6541C276.402 81.639 276.42 81.6197 276.434 81.5975C276.447 81.5753 276.457 81.5505 276.461 81.5247C276.465 81.4988 276.464 81.4724 276.458 81.4469C276.452 81.4214 276.44 81.3975 276.425 81.3764C276.387 81.3417 276.337 81.3225 276.286 81.3225C276.235 81.3225 276.185 81.3417 276.147 81.3764C275.478 81.8888 274.699 82.2368 273.871 82.3925C273.043 82.5481 272.19 82.507 271.38 82.2724C271.356 82.2621 271.331 82.2568 271.304 82.2567C271.278 82.2565 271.252 82.2616 271.228 82.2716C271.204 82.2817 271.182 82.2964 271.163 82.315C271.145 82.3336 271.13 82.3556 271.121 82.3799C271.109 82.4044 271.103 82.431 271.103 82.458C271.103 82.4851 271.108 82.5118 271.119 82.5365C271.13 82.5611 271.147 82.5831 271.167 82.6009C271.187 82.6187 271.211 82.632 271.237 82.6397C272.627 83.056 274.125 82.9146 275.412 82.2455Z'
        fill='#263238'
      />
      <path
        d='M269.929 66.7716C267.241 67.0941 260.073 69.137 261.211 62.4529C262.349 55.7687 275.153 52.4266 281.102 56.7453C287.052 61.064 291.621 60.7415 296.334 57.2381C301.047 53.7348 303.341 55.1146 309.38 58.3761C309.38 58.3761 306.118 64.0029 307.668 67.4257C307.668 67.4257 304.084 63.8416 297.391 67.6676C297.391 67.6676 305.133 65.6157 308.072 70.9649C308.072 70.9649 307.256 76.8695 310.106 80.3818C312.955 83.8941 310.025 88.0516 299.103 83.8135C288.18 79.5754 287.016 83.4641 284.498 81.5914C281.98 79.7188 280.09 74.6474 279.695 70.9649C279.444 68.3127 278.253 65.786 269.929 66.7716Z'
        fill='#263238'
      />
      <path
        d='M285.69 75.2567C285.604 75.913 285.39 76.546 285.059 77.1195C284.729 77.693 284.288 78.1957 283.763 78.5988C282.356 79.6561 281.003 78.5988 280.86 76.8874C280.726 75.3911 281.299 73.0256 282.975 72.6135C283.344 72.5375 283.726 72.5544 284.087 72.6625C284.448 72.7707 284.776 72.9669 285.043 73.2333C285.309 73.4998 285.505 73.8283 285.614 74.1892C285.722 74.5502 285.739 74.9324 285.663 75.3015L285.69 75.2567Z'
        fill='#FFC3BD'
      />
      <path
        d='M321.664 265.128C321.897 270.853 322.067 275.396 322.067 275.396L315.706 276.122C315.706 276.122 315.096 272.251 314.182 266.311L321.664 265.128Z'
        fill='#FFC3BD'
      />
      <path
        d='M312.91 274.742C312.254 274.862 311.577 274.735 311.011 274.384C310.943 274.288 310.906 274.174 310.906 274.057C310.906 273.939 310.943 273.825 311.011 273.73C311.054 273.638 311.117 273.556 311.194 273.49C311.271 273.424 311.361 273.374 311.459 273.344C312.507 272.986 314.801 274.106 314.899 274.16C314.927 274.174 314.951 274.195 314.967 274.223C314.983 274.25 314.99 274.281 314.989 274.312C314.985 274.343 314.972 274.371 314.951 274.394C314.93 274.417 314.903 274.432 314.872 274.437C314.229 274.599 313.572 274.701 312.91 274.742ZM311.826 273.577C311.728 273.577 311.631 273.595 311.539 273.631C311.477 273.643 311.419 273.67 311.369 273.709C311.319 273.748 311.279 273.798 311.252 273.855C311.154 274.052 311.199 274.142 311.252 274.187C311.575 274.581 313.179 274.491 314.353 274.267C313.555 273.88 312.692 273.646 311.808 273.577H311.826Z'
        fill='#407BFF'
      />
      <path
        d='M314.819 274.438H314.756C313.976 274.151 312.364 272.869 312.453 272.144C312.453 271.973 312.57 271.758 312.982 271.687C313.137 271.655 313.298 271.656 313.452 271.692C313.607 271.728 313.752 271.796 313.878 271.893C314.502 272.549 314.901 273.387 315.016 274.285C315.022 274.307 315.022 274.33 315.016 274.352C315.01 274.374 314.998 274.394 314.981 274.41C314.964 274.426 314.944 274.436 314.921 274.441C314.899 274.446 314.876 274.445 314.854 274.438H314.819ZM313.098 271.965H313.018C312.749 271.965 312.731 272.117 312.731 272.153C312.731 272.583 313.788 273.604 314.604 274.016C314.483 273.302 314.145 272.641 313.636 272.126C313.481 272.01 313.291 271.953 313.098 271.965Z'
        fill='#407BFF'
      />
      <path
        d='M315.437 273.506L322.372 273.067C322.499 273.058 322.626 273.093 322.73 273.166C322.835 273.24 322.911 273.347 322.945 273.47L324.567 279.034C324.607 279.151 324.622 279.275 324.611 279.398C324.601 279.521 324.565 279.64 324.506 279.749C324.447 279.857 324.366 279.953 324.269 280.029C324.172 280.104 324.059 280.159 323.94 280.19C323.874 280.198 323.808 280.198 323.743 280.19C321.243 280.306 319.003 280.315 315.894 280.512C312.22 280.754 313.206 281.131 308.86 281.471C306.262 281.668 305.599 278.783 306.692 278.523C311.7 277.565 312.131 276.005 313.958 274.043C314.374 273.697 314.896 273.507 315.437 273.506Z'
        fill='#263238'
      />
      <path
        d='M317.811 129.993L316.602 125.648C316.564 125.525 316.5 125.412 316.415 125.317C316.33 125.221 316.225 125.145 316.108 125.093C315.991 125.041 315.864 125.015 315.736 125.016C315.607 125.018 315.481 125.047 315.365 125.101L288.252 138.792C288.143 138.851 288.047 138.932 287.971 139.03C287.894 139.128 287.839 139.241 287.809 139.362C287.779 139.482 287.774 139.608 287.794 139.73C287.815 139.853 287.861 139.97 287.93 140.073L290.671 144.061C290.671 144.061 297.4 189.864 300.16 205.096C303.009 220.624 313.206 267.09 313.206 267.09C313.238 267.256 313.334 267.402 313.473 267.497C313.612 267.593 313.783 267.63 313.949 267.601L321.843 266.329C321.993 266.304 322.129 266.228 322.229 266.113C322.329 265.999 322.386 265.853 322.39 265.702C322.479 261.741 322.39 236.707 319.899 218.993C319.003 212.667 316.87 207.596 316.145 204.11C315.347 200.267 316.584 197.686 317.041 188.43C318.026 171.048 319.119 143.541 317.811 129.993Z'
        fill='#263238'
      />
      <path
        opacity='0.5'
        d='M317.103 188.466C317.417 182.633 317.74 175.68 317.999 168.494L310.132 155.234L306.629 147.403L303.314 151.883C303.314 151.883 309.174 176.532 316.87 192.014C316.951 190.975 317.041 189.792 317.103 188.466Z'
        fill='black'
      />
      <path
        d='M376.768 243.212C380.065 247.898 382.207 250.98 382.207 250.98L377.216 255.03L370.621 247.611L376.768 243.212Z'
        fill='#FFC3BD'
      />
      <path
        d='M373.775 255.344C373.289 255.8 372.65 256.058 371.983 256.07C371.871 256.032 371.773 255.96 371.704 255.863C371.635 255.767 371.598 255.651 371.598 255.532C371.58 255.329 371.641 255.126 371.768 254.967C372.458 254.071 374.994 253.803 375.11 253.785C375.142 253.78 375.174 253.787 375.202 253.803C375.23 253.819 375.251 253.844 375.263 253.874C375.28 253.899 375.289 253.929 375.289 253.959C375.289 253.99 375.28 254.019 375.263 254.045C374.804 254.518 374.306 254.953 373.775 255.344ZM372.225 254.95C372.145 255.006 372.073 255.072 372.01 255.147C371.969 255.194 371.938 255.25 371.92 255.31C371.901 255.37 371.896 255.434 371.903 255.496C371.903 255.711 372.01 255.765 372.073 255.783C372.557 255.935 373.865 254.994 374.761 254.17C373.874 254.265 373.013 254.53 372.225 254.95Z'
        fill='#407BFF'
      />
      <path
        d='M375.218 254.054H375.164C374.349 254.233 372.297 254.053 371.983 253.373C371.894 253.22 371.867 252.978 372.18 252.701C372.291 252.598 372.421 252.52 372.563 252.469C372.704 252.418 372.855 252.396 373.005 252.405C373.889 252.621 374.682 253.11 375.272 253.803C375.285 253.826 375.292 253.852 375.292 253.879C375.292 253.906 375.285 253.932 375.272 253.955C375.265 253.993 375.246 254.027 375.218 254.054ZM372.431 252.907L372.36 252.951C372.154 253.131 372.198 253.229 372.216 253.265C372.44 253.632 373.892 253.91 374.806 253.812C374.311 253.28 373.668 252.908 372.96 252.745C372.77 252.735 372.583 252.792 372.431 252.907Z'
        fill='#407BFF'
      />
      <path
        d='M375.227 252.942L380.827 248.812C380.926 248.732 381.049 248.688 381.176 248.688C381.304 248.688 381.427 248.732 381.526 248.812L385.889 252.62C385.986 252.696 386.066 252.791 386.124 252.9C386.183 253.008 386.218 253.128 386.228 253.251C386.238 253.373 386.223 253.497 386.183 253.613C386.143 253.73 386.08 253.837 385.997 253.928C385.954 253.978 385.906 254.023 385.853 254.062C383.811 255.514 381.929 256.75 379.42 258.587C376.463 260.773 377.476 260.576 374.044 263.193C371.965 264.77 369.824 262.664 370.621 261.893C374.322 258.372 373.838 256.831 374.331 254.215C374.469 253.698 374.787 253.247 375.227 252.942Z'
        fill='#263238'
      />
      <path
        d='M297.956 141.05C297.956 141.05 318.716 188.905 328.527 203.17C337.245 215.839 370.344 248.256 370.344 248.256C370.588 248.52 370.925 248.679 371.284 248.7C371.642 248.722 371.996 248.605 372.27 248.373L376.867 244.466C377.118 244.25 377.285 243.951 377.338 243.623C377.39 243.295 377.325 242.959 377.153 242.674C374.331 238.078 362.45 218.966 352.02 206.53C347.863 201.575 343.867 197.937 341.896 195.204C339.306 191.62 339.915 188.699 337.864 178.673C334.047 160.027 326.61 132.61 315.32 128.228L297.956 141.05Z'
        fill='#263238'
      />
      <path
        opacity='0.3'
        d='M257.878 276.265H173.304C171.948 276.29 170.602 276.025 169.355 275.489C168.109 274.953 166.991 274.157 166.077 273.155C165.162 272.153 164.471 270.968 164.051 269.678C163.63 268.388 163.489 267.023 163.636 265.675L182.22 53.3226C182.511 50.452 183.844 47.7878 185.968 45.8344C188.091 43.881 190.857 42.774 193.742 42.7229H278.316C279.673 42.6981 281.02 42.963 282.267 43.4998C283.514 44.0366 284.632 44.8331 285.547 45.8362C286.462 46.8392 287.152 48.0257 287.572 49.3166C287.992 50.6076 288.133 51.9732 287.983 53.3226L269.4 265.675C269.107 268.544 267.773 271.206 265.649 273.157C263.526 275.109 260.761 276.214 257.878 276.265Z'
        fill='#407BFF'
      />
      <path
        d='M278.316 42.6871H193.742C190.857 42.7381 188.091 43.8451 185.968 45.7985C183.844 47.7519 182.511 50.4162 182.22 53.2867L163.636 265.639C163.489 266.987 163.63 268.352 164.051 269.642C164.471 270.932 165.162 272.117 166.077 273.119C166.991 274.121 168.109 274.917 169.355 275.453C170.602 275.989 171.948 276.254 173.304 276.229H257.878C260.761 276.179 263.526 275.073 265.649 273.121C267.773 271.17 269.107 268.508 269.4 265.639L287.983 53.2867C288.133 51.9374 287.992 50.5717 287.572 49.2808C287.152 47.9899 286.462 46.8033 285.547 45.8003C284.632 44.7973 283.514 44.0008 282.267 43.464C281.02 42.9271 279.673 42.6623 278.316 42.6871ZM264.696 265.675C264.532 267.269 263.79 268.748 262.61 269.833C261.429 270.918 259.893 271.532 258.29 271.561H173.716C172.962 271.575 172.214 271.427 171.522 271.129C170.829 270.831 170.208 270.389 169.699 269.832C169.191 269.275 168.807 268.616 168.572 267.899C168.338 267.183 168.259 266.424 168.34 265.675L186.924 53.3226C187.085 51.7264 187.827 50.2449 189.007 49.1585C190.188 48.072 191.726 47.456 193.33 47.4269H277.903C278.658 47.4134 279.407 47.561 280.101 47.8597C280.794 48.1584 281.416 48.6015 281.924 49.1593C282.433 49.7172 282.817 50.377 283.051 51.0949C283.284 51.8128 283.362 52.5722 283.279 53.3226L264.696 265.675Z'
        fill='#407BFF'
      />
      <path
        opacity='0.6'
        d='M278.316 42.6871H193.742C190.857 42.7381 188.091 43.8451 185.968 45.7985C183.844 47.7519 182.511 50.4162 182.22 53.2867L163.636 265.639C163.489 266.987 163.63 268.352 164.051 269.642C164.471 270.932 165.162 272.117 166.077 273.119C166.991 274.121 168.109 274.917 169.355 275.453C170.602 275.989 171.948 276.254 173.304 276.229H257.878C260.761 276.179 263.526 275.073 265.649 273.121C267.773 271.17 269.107 268.508 269.4 265.639L287.983 53.2867C288.133 51.9374 287.992 50.5717 287.572 49.2808C287.152 47.9899 286.462 46.8033 285.547 45.8003C284.632 44.7973 283.514 44.0008 282.267 43.464C281.02 42.9271 279.673 42.6623 278.316 42.6871ZM264.696 265.675C264.532 267.269 263.79 268.748 262.61 269.833C261.429 270.918 259.893 271.532 258.29 271.561H173.716C172.962 271.575 172.214 271.427 171.522 271.129C170.829 270.831 170.208 270.389 169.699 269.832C169.191 269.275 168.807 268.616 168.572 267.899C168.338 267.183 168.259 266.424 168.34 265.675L186.924 53.3226C187.085 51.7264 187.827 50.2449 189.007 49.1585C190.188 48.072 191.726 47.456 193.33 47.4269H277.903C278.658 47.4134 279.407 47.561 280.101 47.8597C280.794 48.1584 281.416 48.6015 281.924 49.1593C282.433 49.7172 282.817 50.377 283.051 51.0949C283.284 51.8128 283.362 52.5722 283.279 53.3226L264.696 265.675Z'
        fill='#DFDFDF'
      />
      <path
        d='M228.292 263.148H204.996C204.641 263.155 204.3 263.292 204.039 263.533C203.779 263.774 203.615 264.103 203.58 264.456C203.562 264.622 203.58 264.79 203.632 264.948C203.684 265.107 203.769 265.252 203.882 265.375C203.995 265.498 204.133 265.596 204.286 265.661C204.439 265.727 204.605 265.759 204.772 265.755H228.068C228.423 265.751 228.764 265.616 229.027 265.376C229.29 265.137 229.455 264.809 229.492 264.456C229.51 264.289 229.492 264.12 229.44 263.96C229.387 263.801 229.301 263.654 229.188 263.531C229.074 263.407 228.936 263.309 228.781 263.243C228.627 263.177 228.46 263.144 228.292 263.148Z'
        fill='#DFDFDF'
      />
      <path
        opacity='0.1'
        d='M227.718 141.48H203.06C202.268 141.495 201.482 141.341 200.754 141.028C200.027 140.715 199.374 140.251 198.84 139.666C198.305 139.081 197.902 138.388 197.657 137.635C197.411 136.882 197.329 136.085 197.416 135.298L199.575 110.631C199.746 108.957 200.524 107.403 201.762 106.264C203 105.125 204.613 104.479 206.295 104.448H230.962C231.754 104.435 232.539 104.59 233.266 104.903C233.993 105.217 234.645 105.681 235.179 106.266C235.713 106.851 236.117 107.542 236.363 108.295C236.609 109.047 236.692 109.844 236.607 110.631L234.447 135.298C234.277 136.973 233.497 138.528 232.257 139.667C231.017 140.806 229.402 141.451 227.718 141.48Z'
        fill='black'
      />
      <path
        d='M225.03 138.792H200.372C199.58 138.807 198.794 138.653 198.066 138.34C197.339 138.027 196.686 137.563 196.152 136.978C195.617 136.393 195.214 135.7 194.969 134.947C194.723 134.194 194.641 133.397 194.728 132.61L196.887 107.943C197.058 106.269 197.836 104.715 199.074 103.576C200.312 102.437 201.925 101.791 203.607 101.76H228.274C229.066 101.747 229.851 101.902 230.578 102.215C231.305 102.529 231.957 102.993 232.491 103.578C233.025 104.162 233.429 104.854 233.675 105.607C233.921 106.359 234.004 107.156 233.919 107.943L231.759 132.61C231.589 134.285 230.809 135.84 229.569 136.979C228.329 138.118 226.714 138.763 225.03 138.792Z'
        fill='#407BFF'
      />
      <path
        d='M218.463 118.023C218.884 113.238 214.879 109.367 209.413 109.367C203.948 109.367 199.27 113.238 198.858 118.023C198.527 121.795 200.991 125.003 204.709 126.176C204.193 127.18 203.371 127.993 202.362 128.497C202.54 128.607 202.729 128.7 202.926 128.775C203.929 129.1 205.014 129.066 205.995 128.678C206.976 128.291 207.792 127.574 208.302 126.651C213.535 126.472 218.06 122.664 218.463 118.023Z'
        fill='#DFDFDF'
      />
      <path
        d='M229.815 122.162C230.164 118.22 226.822 114.994 222.351 114.994C221.566 114.996 220.783 115.095 220.022 115.29C220.208 116.173 220.265 117.078 220.192 117.978C219.998 119.686 219.412 121.326 218.48 122.77C217.547 124.214 216.293 125.423 214.816 126.302C215.496 127.179 216.361 127.896 217.349 128.402C218.336 128.908 219.423 129.192 220.532 129.232C220.669 129.592 220.875 129.922 221.139 130.202C221.403 130.482 221.719 130.707 222.071 130.865C222.422 131.022 222.801 131.109 223.186 131.119C223.571 131.129 223.954 131.063 224.314 130.925C224.473 130.866 224.628 130.797 224.779 130.719C224.104 130.334 223.591 129.716 223.337 128.981C226.804 128.174 229.528 125.433 229.815 122.162Z'
        fill='#DFDFDF'
      />
      <path
        opacity='0.1'
        d='M216.411 217.819H198.07C197.481 217.83 196.896 217.716 196.355 217.483C195.813 217.251 195.327 216.906 194.93 216.471C194.532 216.036 194.232 215.521 194.049 214.961C193.866 214.401 193.804 213.809 193.868 213.223L195.471 194.882C195.598 193.634 196.178 192.477 197.101 191.628C198.024 190.779 199.226 190.298 200.48 190.276H218.821C219.411 190.265 219.997 190.38 220.539 190.613C221.081 190.846 221.567 191.192 221.965 191.628C222.362 192.064 222.663 192.579 222.845 193.14C223.028 193.702 223.088 194.295 223.023 194.882L221.42 213.223C221.292 214.469 220.712 215.626 219.789 216.473C218.866 217.32 217.664 217.799 216.411 217.819Z'
        fill='black'
      />
      <path
        opacity='0.1'
        d='M243.327 182.123H220.416C219.68 182.136 218.949 181.992 218.272 181.701C217.596 181.409 216.989 180.977 216.493 180.433C215.997 179.888 215.623 179.244 215.396 178.544C215.168 177.843 215.093 177.102 215.174 176.37L217.172 153.442C217.332 151.881 218.059 150.434 219.215 149.374C220.371 148.313 221.876 147.714 223.444 147.689H246.382C247.118 147.677 247.848 147.822 248.524 148.114C249.2 148.406 249.806 148.838 250.301 149.382C250.797 149.926 251.171 150.57 251.399 151.27C251.627 151.97 251.704 152.71 251.624 153.442L249.626 176.37C249.465 177.935 248.734 179.386 247.573 180.447C246.411 181.508 244.9 182.104 243.327 182.123Z'
        fill='black'
      />
      <path
        d='M240.639 179.435H217.728C216.992 179.448 216.261 179.304 215.584 179.013C214.908 178.721 214.301 178.289 213.805 177.745C213.309 177.2 212.935 176.556 212.708 175.856C212.48 175.155 212.405 174.414 212.486 173.682L214.484 150.754C214.644 149.193 215.371 147.746 216.527 146.686C217.683 145.625 219.188 145.026 220.756 145.001H243.694C244.43 144.989 245.16 145.134 245.836 145.426C246.512 145.718 247.118 146.15 247.613 146.694C248.109 147.238 248.483 147.882 248.711 148.582C248.939 149.282 249.016 150.022 248.936 150.754L246.938 173.682C246.777 175.247 246.046 176.698 244.885 177.759C243.723 178.82 242.212 179.416 240.639 179.435Z'
        fill='#407BFF'
      />
      <path
        d='M213.723 215.131H195.382C194.793 215.142 194.208 215.028 193.667 214.795C193.125 214.563 192.639 214.217 192.242 213.783C191.844 213.348 191.544 212.833 191.361 212.273C191.178 211.713 191.116 211.12 191.18 210.535L192.783 192.194C192.91 190.946 193.49 189.789 194.413 188.94C195.336 188.091 196.538 187.61 197.792 187.588H216.133C216.723 187.577 217.309 187.692 217.851 187.925C218.393 188.158 218.879 188.504 219.277 188.94C219.674 189.376 219.975 189.891 220.157 190.452C220.34 191.014 220.4 191.607 220.335 192.194L218.732 210.535C218.604 211.781 218.024 212.938 217.101 213.785C216.178 214.632 214.976 215.111 213.723 215.131Z'
        fill='#407BFF'
      />
      <path
        d='M242.144 152.761H220.864C220.191 152.758 219.542 153.008 219.044 153.461C218.546 153.913 218.237 154.537 218.176 155.207L216.984 169.256C216.951 169.567 216.985 169.881 217.082 170.178C217.18 170.474 217.339 170.747 217.55 170.978C217.761 171.208 218.019 171.391 218.306 171.514C218.593 171.638 218.903 171.699 219.215 171.693H240.486C241.158 171.696 241.806 171.448 242.304 170.997C242.801 170.545 243.112 169.925 243.174 169.256L244.402 155.225C244.439 154.91 244.409 154.59 244.311 154.288C244.214 153.986 244.053 153.709 243.838 153.475C243.624 153.241 243.362 153.056 243.069 152.933C242.777 152.809 242.461 152.751 242.144 152.761ZM225.846 162.204L218.723 168.754L219.869 155.655L225.846 162.204ZM221.169 154.454H241.535L231.24 163.925C231.146 164.019 231.035 164.093 230.913 164.144C230.791 164.195 230.66 164.221 230.527 164.221C230.395 164.221 230.264 164.195 230.142 164.144C230.019 164.093 229.908 164.019 229.815 163.925L221.169 154.454ZM226.939 163.414L228.507 165.134C228.742 165.388 229.028 165.589 229.346 165.724C229.664 165.86 230.007 165.928 230.353 165.923C231.088 165.918 231.795 165.636 232.333 165.134L234.205 163.414L240.209 169.991H219.789L226.939 163.414ZM235.505 162.213L242.673 155.664L241.526 168.763L235.505 162.213Z'
        fill='#DFDFDF'
      />
      <path
        d='M197.344 196.423L202.72 198.609L208.634 194.559L204.189 199.819L207.451 204.424L212.621 191.593L197.344 196.423Z'
        fill='#DFDFDF'
      />
      <path
        d='M202.353 199.828L204.772 202.784H202.191L202.353 199.828Z'
        fill='#DFDFDF'
      />
      <path
        d='M200.211 203.689L199.647 203.331C199.811 203.065 199.994 202.81 200.193 202.569L200.704 203.008C200.524 203.224 200.36 203.451 200.211 203.689Z'
        fill='#DFDFDF'
      />
      <path
        d='M199.942 208.107C199.384 207.798 198.97 207.284 198.787 206.673L199.432 206.494C199.583 206.942 199.905 207.312 200.328 207.524L199.942 208.107ZM199.378 205.795L198.706 205.741C198.772 205.156 198.945 204.588 199.217 204.066L199.826 204.37C199.59 204.813 199.438 205.296 199.378 205.795Z'
        fill='#DFDFDF'
      />
      <path
        d='M213.49 210.472L212.827 210.356C212.858 210.193 212.873 210.028 212.872 209.863C212.892 209.576 212.806 209.292 212.63 209.065L213.149 208.635C213.42 208.986 213.559 209.42 213.544 209.863C213.542 210.067 213.524 210.271 213.49 210.472ZM203.965 208.922H203.267V208.25C203.801 208.277 204.336 208.277 204.87 208.25V208.922H203.965ZM205.757 208.85V208.178C206.304 208.178 206.851 208.089 207.379 208.035L207.442 208.707L205.757 208.85ZM202.433 208.85H202.156C201.684 208.788 201.219 208.683 200.767 208.537L200.982 207.9C201.392 208.037 201.816 208.133 202.245 208.187H202.505L202.433 208.85ZM212.11 208.716C211.63 208.561 211.127 208.488 210.623 208.501V207.784C211.21 207.769 211.795 207.857 212.352 208.044L212.11 208.716ZM208.212 208.635L208.15 207.963C208.723 207.909 209.279 207.865 209.789 207.838V208.51C209.315 208.51 208.768 208.555 208.204 208.608L208.212 208.635Z'
        fill='#DFDFDF'
      />
      <path
        d='M212.934 212.174L212.316 211.906C212.442 211.619 212.549 211.341 212.63 211.099L213.266 211.305C213.176 211.574 213.069 211.861 212.934 212.174Z'
        fill='#DFDFDF'
      />
      <path
        opacity='0.1'
        d='M237.798 99.4307H217.19C216.527 99.4424 215.87 99.3126 215.261 99.0501C214.652 98.7877 214.106 98.3985 213.659 97.9085C213.212 97.4185 212.875 96.8389 212.67 96.2084C212.464 95.5779 212.396 94.9109 212.468 94.2518L214.26 73.6438C214.403 72.2418 215.054 70.9405 216.091 69.9862C217.128 69.0319 218.478 68.4906 219.887 68.465H240.495C241.158 68.4533 241.816 68.5831 242.425 68.8456C243.034 69.108 243.58 69.4972 244.027 69.9872C244.474 70.4772 244.811 71.0567 245.016 71.6872C245.221 72.3178 245.29 72.9848 245.217 73.6438L243.425 94.2518C243.283 95.6539 242.632 96.9552 241.595 97.9095C240.558 98.8638 239.207 99.4051 237.798 99.4307Z'
        fill='black'
      />
      <path
        d='M235.11 96.7427H214.502C213.839 96.7544 213.182 96.6246 212.573 96.3621C211.964 96.0997 211.418 95.7105 210.971 95.2205C210.524 94.7305 210.187 94.1509 209.982 93.5204C209.776 92.8899 209.708 92.2229 209.78 91.5638L211.572 70.9558C211.715 69.5538 212.366 68.2525 213.403 67.2982C214.44 66.3439 215.79 65.8026 217.199 65.777H237.807C238.47 65.7653 239.128 65.8951 239.737 66.1576C240.346 66.42 240.892 66.8092 241.339 67.2992C241.786 67.7892 242.123 68.3687 242.328 68.9992C242.533 69.6298 242.602 70.2968 242.529 70.9558L240.737 91.5638C240.595 92.9659 239.944 94.2672 238.907 95.2215C237.87 96.1758 236.519 96.7171 235.11 96.7427Z'
        fill='#407BFF'
      />
      <path
        d='M236.212 85.4531C236.077 81.8269 234.661 78.3661 232.215 75.6855C229.769 73.005 226.452 71.2784 222.853 70.8125C221.801 70.2945 220.601 70.156 219.458 70.4205C218.316 70.685 217.299 71.3363 216.581 72.264C214.789 74.4413 216.384 75.848 218.373 77.4071C220.362 78.9661 222.145 80.1578 223.91 77.9805C224.572 77.1836 224.981 76.2069 225.084 75.176C226.739 75.8516 228.222 76.8873 229.426 78.2078C230.63 79.5282 231.525 81.1004 232.046 82.8099C231.017 82.8147 230.012 83.1267 229.161 83.7059C226.84 85.2829 227.862 87.1197 229.251 89.297C230.639 91.4743 231.876 93.1587 234.187 91.5728C235.174 90.9386 235.914 89.985 236.283 88.8712C236.651 87.7574 236.626 86.5509 236.212 85.4531Z'
        fill='#DFDFDF'
      />
      <path
        d='M227.584 39.121H241.651C242.35 39.121 242.44 38.0458 241.75 38.0458H227.7C227.011 38.0458 226.912 39.121 227.602 39.121H227.584Z'
        fill='#407BFF'
      />
      <path
        d='M246.803 39.121H250.88C251.579 39.121 251.668 38.0458 250.979 38.0458H246.938C246.248 38.0458 246.149 39.121 246.848 39.121H246.803Z'
        fill='#407BFF'
      />
      <g opacity='0.3'>
        <path
          d='M227.584 39.121H241.651C242.35 39.121 242.44 38.0458 241.75 38.0458H227.7C227.011 38.0458 226.912 39.121 227.602 39.121H227.584Z'
          fill='#DFDFDF'
        />
      </g>
      <g opacity='0.3'>
        <path
          d='M246.803 39.121H250.88C251.579 39.121 251.668 38.0458 250.979 38.0458H246.938C246.248 38.0458 246.149 39.121 246.848 39.121H246.803Z'
          fill='#DFDFDF'
        />
      </g>
      <path
        d='M273.907 147.815L272.671 161.864C272.659 162.007 272.704 162.148 272.796 162.257C272.889 162.367 273.021 162.435 273.164 162.446C273.306 162.458 273.448 162.413 273.557 162.321C273.666 162.228 273.734 162.096 273.746 161.954C274.152 157.277 274.561 152.593 274.973 147.904C274.972 147.771 274.92 147.643 274.829 147.545C274.738 147.448 274.614 147.388 274.481 147.376C274.348 147.365 274.216 147.404 274.11 147.485C274.003 147.566 273.931 147.684 273.907 147.815Z'
        fill='#407BFF'
      />
      <path
        d='M272.223 167.007L271.873 171.048C271.875 171.181 271.927 171.309 272.018 171.407C272.109 171.505 272.233 171.565 272.366 171.576C272.499 171.587 272.631 171.548 272.737 171.467C272.843 171.386 272.915 171.269 272.94 171.138L273.289 167.106C273.289 166.971 273.239 166.842 273.149 166.743C273.059 166.644 272.934 166.582 272.801 166.569C272.667 166.557 272.534 166.595 272.427 166.676C272.32 166.757 272.247 166.875 272.223 167.007Z'
        fill='#407BFF'
      />
      <g opacity='0.3'>
        <path
          d='M273.907 147.815L272.671 161.864C272.659 162.007 272.704 162.148 272.796 162.257C272.889 162.367 273.021 162.435 273.164 162.446C273.306 162.458 273.448 162.413 273.557 162.321C273.666 162.228 273.734 162.096 273.746 161.954C274.152 157.277 274.561 152.593 274.973 147.904C274.972 147.771 274.92 147.643 274.829 147.545C274.738 147.448 274.614 147.388 274.481 147.376C274.348 147.365 274.216 147.404 274.11 147.485C274.003 147.566 273.931 147.684 273.907 147.815Z'
          fill='#DFDFDF'
        />
      </g>
      <g opacity='0.3'>
        <path
          d='M272.223 167.007L271.873 171.048C271.875 171.181 271.927 171.309 272.018 171.407C272.109 171.505 272.233 171.565 272.366 171.576C272.499 171.587 272.631 171.548 272.737 171.467C272.843 171.386 272.915 171.269 272.94 171.138L273.289 167.106C273.289 166.971 273.239 166.842 273.149 166.743C273.059 166.644 272.934 166.582 272.801 166.569C272.667 166.557 272.534 166.595 272.427 166.676C272.32 166.757 272.247 166.875 272.223 167.007Z'
          fill='#DFDFDF'
        />
      </g>
      <path
        d='M149.498 85.2918C149.498 85.2918 144.301 84.584 144.928 87.2899C145.385 89.2611 147.132 85.3814 149.498 85.2918Z'
        fill='#263238'
      />
      <path
        d='M144.982 91.4384C144.982 91.4384 149.265 91.3936 149.928 83.5895C149.928 83.5895 154.013 82.1648 156.334 84.7095C158.655 87.2541 153.324 92.1821 156.262 94.7446C156.262 94.7446 153.324 96.3485 144.982 91.4384Z'
        fill='#FF8B7B'
      />
      <path
        d='M160.832 63.8685C160.832 63.8685 166.351 62.6858 167.785 66.7447C169.219 70.8035 159.613 69.0205 159.613 69.0205L160.832 63.8685Z'
        fill='#263238'
      />
      <path
        opacity='0.3'
        d='M160.832 63.8685C160.832 63.8685 166.351 62.6858 167.785 66.7447C169.219 70.8035 159.613 69.0205 159.613 69.0205L160.832 63.8685Z'
        fill='black'
      />
      <path
        d='M165.348 73.9843C165.437 73.4647 165.778 73.0883 166.118 73.1511C166.459 73.2138 166.665 73.6707 166.575 74.1904C166.486 74.7101 166.136 75.0864 165.805 75.0326C165.473 74.9789 165.258 74.504 165.348 73.9843Z'
        fill='#263238'
      />
      <path
        d='M165.617 74.961L168.027 78.8675C167.57 79.1498 167.052 79.3204 166.517 79.3655C165.981 79.4106 165.443 79.3291 164.945 79.1274L165.617 74.961Z'
        fill='#FF5652'
      />
      <path
        d='M147.365 71.4755C147.06 78.1149 146.792 80.9283 149.757 84.6647C154.479 88.6608 162.454 89.2163 164.73 82.792C166.781 77.0218 166.934 67.1568 160.751 63.8058C159.393 63.0687 157.868 62.6912 156.322 62.7092C154.777 62.7271 153.261 63.14 151.92 63.9085C150.579 64.6769 149.456 65.7756 148.659 67.1C147.862 68.4244 147.416 69.9306 147.365 71.4755Z'
        fill='#FF8B7B'
      />
      <path
        d='M164.515 72.3446C164.558 72.369 164.608 72.3817 164.658 72.3817C164.708 72.3817 164.757 72.369 164.801 72.3446C165.109 72.1117 165.467 71.9546 165.847 71.8861C166.227 71.8176 166.618 71.8396 166.988 71.9504C167.062 71.9798 167.146 71.9785 167.22 71.9466C167.294 71.9147 167.352 71.8549 167.382 71.7802C167.412 71.704 167.41 71.6193 167.379 71.544C167.347 71.4687 167.287 71.4087 167.212 71.377C166.747 71.2261 166.252 71.1897 165.77 71.2708C165.288 71.352 164.833 71.5482 164.443 71.8429C164.41 71.8638 164.381 71.8912 164.358 71.9233C164.336 71.9555 164.32 71.9918 164.312 72.0302C164.303 72.0686 164.303 72.1082 164.31 72.1468C164.317 72.1854 164.332 72.2222 164.353 72.2551C164.395 72.303 164.452 72.3347 164.515 72.3446Z'
        fill='#263238'
      />
      <path
        d='M141.971 92.7824C141.971 92.7824 143.817 87.6842 151.46 90.9367C159.103 94.1891 159.82 98.6691 159.82 98.6691L141.971 92.7824Z'
        fill='#263238'
      />
      <path
        opacity='0.4'
        d='M141.971 92.7824C141.971 92.7824 143.817 87.6842 151.46 90.9367C159.103 94.1891 159.82 98.6691 159.82 98.6691L141.971 92.7824Z'
        fill='black'
      />
      <path
        d='M145.403 189.407C145.403 189.407 144.319 198.699 143.799 201.772C143.333 204.46 128.567 274.975 128.567 274.975L121.856 273.864C121.856 273.864 121.112 238.14 126.282 214.495C127.348 209.657 129.49 205.607 130.269 201.906C130.897 198.94 129.947 189.765 129.947 189.765L145.403 189.407Z'
        fill='#FF8B7B'
      />
      <path
        d='M117.376 187.893C117.376 187.893 114.249 196.405 112.788 199.039C111.203 201.906 105.181 209.71 105.181 209.71L73.1046 254.717L66.967 250.837C66.967 250.837 76.2227 226.053 90.1645 205.876C93.4886 201.064 98.2733 197.399 100.916 192.624C102.413 189.882 103.685 180.42 103.685 180.42L117.376 187.893Z'
        fill='#FF8B7B'
      />
      <path
        d='M125.978 101.733C125.978 101.733 118.299 111.527 118.953 115.639C119.607 119.752 131.64 124.761 131.64 124.761L134.696 120.415L126.793 114.645L134.319 106.016L125.978 101.733Z'
        fill='#FF8B7B'
      />
      <path
        d='M138.674 91.9312C137.715 91.4743 133.459 89.7719 124.275 101.707C124.275 101.707 127.617 105.873 134.776 108.355C134.776 108.328 142.84 93.8755 138.674 91.9312Z'
        fill='#263238'
      />
      <path
        opacity='0.4'
        d='M134.776 108.328C134.776 108.328 136.12 105.927 137.464 102.952C137.249 97.8359 136.622 91.4832 134.83 93.768C132.447 96.7965 129.149 102.898 128.065 104.941C130.139 106.367 132.397 107.507 134.776 108.328Z'
        fill='black'
      />
      <path
        d='M127.546 131.624C136.371 136.388 146.12 139.193 156.128 139.849L145.77 197.561C145.77 197.561 115.898 197.462 100.28 182.624C100.28 182.589 106.508 151.139 127.546 131.624Z'
        fill='#407BFF'
      />
      <path
        d='M126.282 131.749C126.282 131.749 138.244 140.566 157.293 139.966C157.293 139.966 168.233 109.699 161.379 99.4576C157.14 93.1319 140.869 90.2736 136.488 92.0746C131.21 94.2429 134.266 113.22 126.282 131.749Z'
        fill='#263238'
      />
      <path
        opacity='0.1'
        d='M129.149 162.841L115.844 191.934C120.509 193.628 125.303 194.943 130.18 195.867C130.18 195.867 130.628 168.692 129.149 162.841Z'
        fill='black'
      />
      <path
        opacity='0.4'
        d='M156.361 104.413L154.246 105.972C155.94 111.864 158.605 117.433 162.131 122.449C163.036 118.226 163.537 113.927 163.628 109.609L158.915 105.64L156.361 104.413Z'
        fill='black'
      />
      <path
        d='M168.349 108.785L175.517 118.775L193.536 119.976L196.842 117.225C197.062 117.039 197.335 116.926 197.622 116.903L207.245 116.141C207.314 116.137 207.383 116.148 207.448 116.173C207.513 116.198 207.572 116.236 207.621 116.285C207.67 116.334 207.708 116.393 207.733 116.457C207.758 116.522 207.768 116.592 207.764 116.661L207.711 117.476C207.701 117.593 207.651 117.702 207.569 117.785C207.487 117.869 207.379 117.921 207.263 117.933L202.702 118.292L201.806 122.96C201.754 123.223 201.629 123.465 201.445 123.66C201.261 123.855 201.026 123.994 200.767 124.062L197.183 124.958C196.954 125.021 196.713 125.021 196.484 124.958L193.957 124.322C193.957 124.322 179.917 127.153 173.475 126.015C167.032 124.877 160.68 114.367 160.68 114.367L168.349 108.785Z'
        fill='#FF8B7B'
      />
      <path
        d='M160.052 116.392C160.052 116.392 148.512 100.954 155.635 97.3699C162.758 93.7859 170.24 109.645 170.24 109.645C170.24 109.645 167.319 114.107 160.052 116.392Z'
        fill='#263238'
      />
      <path
        d='M130.036 274.572C130.002 274.565 129.971 274.547 129.947 274.522C129.922 274.496 129.907 274.463 129.902 274.429C129.897 274.392 129.903 274.354 129.918 274.321C129.934 274.287 129.96 274.259 129.992 274.24C130.108 274.187 132.751 272.798 133.979 273.192C134.092 273.225 134.198 273.28 134.291 273.353C134.383 273.427 134.46 273.519 134.516 273.622C134.597 273.733 134.641 273.866 134.641 274.003C134.641 274.14 134.597 274.273 134.516 274.384C133.87 274.811 133.088 274.984 132.321 274.868C131.552 274.842 130.787 274.743 130.036 274.572ZM130.619 274.357C131.99 274.581 133.862 274.644 134.203 274.169C134.257 274.115 134.31 274.007 134.203 273.783C134.17 273.718 134.124 273.661 134.066 273.616C134.009 273.571 133.942 273.54 133.871 273.524C133.761 273.488 133.647 273.469 133.531 273.47C132.513 273.578 131.524 273.879 130.619 274.357Z'
        fill='#407BFF'
      />
      <path
        d='M129.92 274.563C129.894 274.546 129.873 274.522 129.86 274.493C129.847 274.464 129.843 274.433 129.848 274.402C129.848 274.303 130.135 272.314 131.103 271.57C131.243 271.462 131.403 271.384 131.574 271.339C131.744 271.295 131.923 271.285 132.097 271.31C132.581 271.373 132.716 271.624 132.733 271.83C132.859 272.663 131.013 274.204 130.108 274.572H130.036C129.998 274.582 129.957 274.579 129.92 274.563ZM131.963 271.633C131.742 271.621 131.525 271.692 131.354 271.83C130.772 272.448 130.397 273.23 130.278 274.07C131.174 273.559 132.438 272.35 132.411 271.839C132.411 271.794 132.411 271.669 132.07 271.633H131.963Z'
        fill='#407BFF'
      />
      <path
        d='M129.32 273.506L121.256 273.201C121.107 273.196 120.961 273.243 120.842 273.332C120.723 273.422 120.638 273.549 120.602 273.694L118.89 280.226C118.816 280.502 118.855 280.797 118.997 281.046C119.14 281.294 119.375 281.476 119.652 281.552C119.729 281.565 119.808 281.565 119.885 281.552C122.806 281.614 125.404 281.552 129.042 281.695C133.334 281.856 132.223 282.322 137.267 282.591C140.305 282.743 140.986 279.312 139.704 279.097C133.836 278.138 133.289 276.328 131.094 274.133C130.597 273.717 129.967 273.494 129.32 273.506Z'
        fill='#263238'
      />
      <path
        d='M74.1082 255.156C74.082 255.131 74.0632 255.099 74.0537 255.064C74.0443 255.03 74.0445 254.993 74.0544 254.958C74.0657 254.925 74.0873 254.895 74.1163 254.874C74.1452 254.853 74.18 254.842 74.2157 254.842C74.3501 254.842 77.3338 254.842 78.2566 255.738C78.4295 255.906 78.532 256.133 78.5434 256.374C78.5613 256.51 78.5363 256.647 78.472 256.768C78.4078 256.888 78.3074 256.986 78.185 257.046C77.4116 257.132 76.6336 256.932 75.9987 256.482C75.3297 256.098 74.6969 255.654 74.1082 255.156ZM74.7264 255.227C75.8464 256.043 77.495 256.939 78.0326 256.679C78.1043 256.679 78.1939 256.589 78.185 256.329C78.1857 256.256 78.17 256.183 78.139 256.116C78.108 256.05 78.0625 255.991 78.0058 255.944C77.923 255.865 77.8295 255.799 77.728 255.747C76.7659 255.403 75.7478 255.241 74.7264 255.272V255.227Z'
        fill='#407BFF'
      />
      <path
        d='M74.0365 255.102C74.0188 255.075 74.0093 255.044 74.0093 255.012C74.0093 254.98 74.0188 254.949 74.0365 254.923C74.0902 254.851 75.2282 253.193 76.4378 252.969C76.6089 252.936 76.7851 252.936 76.9559 252.972C77.1267 253.007 77.2886 253.076 77.4323 253.175C77.8355 253.453 77.8534 253.74 77.7728 253.928C77.504 254.734 75.1654 255.281 74.1888 255.2C74.1656 255.209 74.1403 255.209 74.1171 255.2C74.0785 255.179 74.0496 255.144 74.0365 255.102ZM77.1814 253.417C76.9901 253.304 76.7633 253.265 76.5453 253.31C75.7477 253.604 75.0596 254.135 74.5741 254.833C75.6493 254.833 77.2621 254.26 77.4861 253.803C77.4861 253.758 77.5398 253.632 77.271 253.453H77.1814V253.417Z'
        fill='#407BFF'
      />
      <path
        d='M73.9469 253.883L66.8595 249.959C66.7298 249.886 66.5793 249.86 66.4327 249.884C66.2862 249.909 66.1523 249.982 66.0531 250.093L61.5731 255.156C61.4777 255.261 61.4041 255.385 61.3565 255.519C61.309 255.654 61.2883 255.796 61.2958 255.938C61.3033 256.081 61.3388 256.22 61.4002 256.349C61.4616 256.477 61.5478 256.592 61.6538 256.688L61.8419 256.822C64.4224 258.193 66.7789 259.313 69.9597 261.078C73.7139 263.157 72.5133 263.076 76.8947 265.558C79.5827 267.072 81.6973 264.313 80.649 263.542C75.8464 260.039 76.169 258.166 75.2013 255.227C74.9541 254.646 74.51 254.17 73.9469 253.883Z'
        fill='#263238'
      />
      <path
        d='M158.592 77.1831C158.592 77.1831 161.28 76.2871 160.294 72.3895C160.294 72.3895 161.88 69.7015 161.629 68.2768C161.629 68.2768 169.693 63.6266 165.088 58.5283C160.483 53.4301 152.123 56.8349 148.422 63.3488C148.422 63.3488 128.755 69.7463 142.446 81.7706C155.77 93.5171 158.592 77.1831 158.592 77.1831Z'
        fill='#263238'
      />
      <path
        d='M150.474 66.0816C150.474 66.0816 147.401 59.3347 152.015 54.2365C156.63 49.1383 148.951 61.4224 150.474 66.0816Z'
        fill='#263238'
      />
      <path
        d='M158.592 77.1831C158.669 77.1581 158.743 77.1282 158.816 77.0934C158.816 72.04 156.872 65.983 149.417 61.8615C149.059 62.345 148.727 62.8475 148.422 63.3667C148.422 63.3667 147.858 63.5549 146.989 63.9133C149.569 66.655 156.657 75.7315 148.691 85.6503C156.675 88.1411 158.592 77.1831 158.592 77.1831Z'
        fill='#407BFF'
      />
      <path
        d='M158.906 74.8176C159.821 75.82 160.301 77.1443 160.241 78.5002C160.142 80.2922 158.386 80.6416 156.988 79.6739C155.725 78.7779 154.255 76.8067 155.044 75.1939C155.221 74.8515 155.481 74.5588 155.801 74.3426C156.12 74.1264 156.489 73.9935 156.872 73.9561C157.256 73.9187 157.643 73.9779 157.999 74.1284C158.354 74.2789 158.665 74.5158 158.906 74.8176Z'
        fill='#FF8B7B'
      />
      <path
        d='M165.993 73.16L167.444 73.0883C167.444 73.0883 166.495 73.9933 165.993 73.16Z'
        fill='#263238'
      />
      <path
        opacity='0.2'
        d='M152.705 86.5283C153.433 87.9983 154.552 89.2386 155.94 90.1123C156.253 89.2993 156.525 88.471 156.755 87.6304C155.352 87.4988 153.982 87.126 152.705 86.5283Z'
        fill='black'
      />
    </svg>
  );
};

export default GetInTouch;
