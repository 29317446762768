import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './AbhisharSectionHome.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import AbhisharCard2 from '../abhisharCardComponent/AbhisharCard2.jsx';
import AbhisharList from '../../screens/Abhishar/abhisharData.json';
import CustomButton from '../custom-button/CutsomButton.jsx';

const AbhisharSection = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1120,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 798,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const cardsContainerRef = useRef(null);
  const [showHorizontalSlider, setShowHorizontalSlider] = useState(false);

  useEffect(() => {
    function handleResize() {
      const shouldShowSlider = window.innerWidth < 998;
      setShowHorizontalSlider(shouldShowSlider);
    }
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className='abhisharSectionHome'>
      <h1 className='homepageHeadings'>Abhishar</h1>

      {showHorizontalSlider ? (
        <Slider {...settings} className='abhisharCardsContainerHome'>
          {AbhisharList.slice(0, 4).map((card) => (
            <AbhisharCard2
              key={card.version}
              image={card.image}
              heading={card.heading}
              version={card.version}
              link={card.link}
            />
          ))}
        </Slider>
      ) : (
        <div ref={cardsContainerRef} className='abhisharCardsContainerHome'>
          {AbhisharList.slice(0, 4).map((card) => (
            <AbhisharCard2
              key={card.version}
              image={card.image}
              heading={card.heading}
              version={card.version}
              link={card.link}
            />
          ))}
        </div>
      )}

      <Link className='customButton' exact to='/abhishar'>
        <CustomButton customButton__text='Explore More Versions' />
      </Link>
    </div>
  );
};

export default AbhisharSection;
