import React from 'react';
import './AASFSectionHome.css';

const AASFSection = () => {
  return (
    <div className='introSectionHome flex-center'>
      <div className='introSectionItems'>
        <div className='introSectionContent'>
          <h1 className='introSectionHeading'>What is AASF?</h1>

          <h3 className='introSectionText'>
            Abhigyan Abhikaushalam Students’ Forum aims to construct this
            shoreline and helps in constructing the further path. It works to
            conjugate the knowledge and skills into a single bunch. Knowledge is
            incomplete in itself, unless it is complemented by skills. This
            forum aims in providing the right kind of knowledge to the students
            and development of skills.
          </h3>
        </div>

        <div className='introSectionCardsContainer'>
          {/* <div>
            <img
              className='introSectionCards'
              src='https://img.freepik.com/free-vector/qualification-increase-course-skills-improvement-coaching_335657-3310.jpg?w=2000'
              alt=''
            />
          </div> */}
          <div className='introSectionCards'>
            <img
              className='AASFSectionIcon'
              src='https://res.cloudinary.com/dlafd0adt/image/upload/v1694202485/AASF%20Website%20Assests/knowledge_skills_puxipm.webp'
            />
            <div className='introSectionCardText'>Knowledge and Skills</div>
          </div>
          <div className='introSectionCards'>
            <img
              className='AASFSectionIcon'
              src='https://res.cloudinary.com/dlafd0adt/image/upload/v1694201903/AASF%20Website%20Assests/peer_to_peer_dacktq.webp'
            />
            <div className='introSectionCardText'>Peer to Peer Learning</div>
          </div>
          <div className='introSectionCards'>
            <img
              className='AASFSectionIcon'
              src='https://res.cloudinary.com/dlafd0adt/image/upload/v1694201903/AASF%20Website%20Assests/3899021-200_cyhd0q.webp'
            />
            <div className='introSectionCardText'>Clarify Your Doubts</div>
          </div>
          <div className='introSectionCards'>
            <img
              className='AASFSectionIcon'
              src='https://res.cloudinary.com/dlafd0adt/image/upload/v1694201903/AASF%20Website%20Assests/domains_h0biab.webp'
            />
            <div className='introSectionCardText'>Explore all domains</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AASFSection;
