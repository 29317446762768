import React from 'react';
import './teammembers.css';
import TeamMemberCard from '../teamMemberCard/TeamMemberCard';
import TeamMemberList from './TeamMemberData.json';

function TeamMembers() {
  return (
    <>
      <div className='batchRepresentativeSection'>
        <h2 className='teamMembersHeading'>Batch Representatives</h2>

        <div
          className='teamMemberCards'
          style={{ display: 'flex', flexWrap: 'wrap' }}
        >
          {TeamMemberList.filter((member) => member.Role === 'batchrep').map(
            (member) => (
              <TeamMemberCard
                key={member.Name}
                name={member.Name}
                batch={member.Batch}
                designation={member.Designation}
                image={member.image}
                instagram={member.Instagram}
                facebook={member.Facebook}
                twitter={member.Twitter}
                linkedIn={member.LinkedIn}
                medium={member.Medium}
              />
            )
          )}
        </div>
      </div>

      <div className='advisoryCommitteeSection'>
        <h2 className='teamMembersHeading'>Advisory Committee</h2>
        {/* <div
          className='teamMemberCards alumniSection'
          style={{ display: 'flex', flexWrap: 'wrap' }}
        >
          {TeamMemberList.filter((member) => member.Role === 'alumni').map(
            (member) => (
              <TeamMemberCard
                key={member.Name}
                name={member.Name}
                batch={member.Batch}
                designation={member.Designation}
                image={member.image}
                instagram={member.Instagram}
                facebook={member.Facebook}
                twitter={member.Twitter}
                linkedIn={member.LinkedIn}
                medium={member.Medium}
                youtube={member.Youtube}
              />
            )
          )}
        </div> */}
        <div
          className='teamMemberCards finalYearSection'
          style={{ display: 'flex', flexWrap: 'wrap' }}
        >
          {TeamMemberList.filter((member) => member.Role === '5thyear').map(
            (member) => (
              <TeamMemberCard
                key={member.Name}
                name={member.Name}
                batch={member.Batch}
                designation={member.Designation}
                image={member.image}
                instagram={member.Instagram}
                facebook={member.Facebook}
                twitter={member.Twitter}
                linkedIn={member.LinkedIn}
                medium={member.Medium}
              />
            )
          )}
        </div>

        <div
          className='teamMemberCards preFinalYearSection'
          style={{ display: 'flex', flexWrap: 'wrap' }}
        >
          {TeamMemberList.filter((member) => member.Role === '4thYear').map(
            (member) => (
              <TeamMemberCard
                key={member.Name}
                name={member.Name}
                batch={member.Batch}
                designation={member.Designation}
                image={member.image}
                instagram={member.Instagram}
                facebook={member.Facebook}
                twitter={member.Twitter}
                linkedIn={member.LinkedIn}
                medium={member.Medium}
              />
            )
          )}
        </div>
      </div>
    </>
  );
}

export default TeamMembers;
