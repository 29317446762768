import React from 'react';
import Slider from 'react-slick';
import './EventsSectionHome.css';
import FlagshipEventCard from '../FlagshipEventCard/FlagshipEventCard';
import EventList from '../../screens/Events/flagshipeventData.json';
import { Link } from 'react-router-dom';
import CustomButton from '../custom-button/CutsomButton.jsx';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const EventsSection = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Number of cards visible at once
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200, // Large desktop screens
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 992, // Small desktop/laptop screens
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576, // Mobile devices
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className='eventsSectionHome'>
      <div className='eventsContainerHome'>
        <h1 className='homepageHeadings'>Flagship Events</h1>

        <Slider {...settings} className='eventCardContainerHome'>
          {EventList.slice(0, 7).map((card) => (
            <FlagshipEventCard
              key={card.key}
              thumbnail={card.image}
              name={card.heading}
              tags={card.tag}
              eventDetails={card.content}
            />
          ))}
        </Slider>

        <Link className='customButton' exact to='/events'>
          <CustomButton customButton__text='Explore More Events' />
        </Link>
      </div>
    </div>
  );
};

export default EventsSection;
