import React from 'react';

const TeamVector = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 448 358'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_1281_5470)'>
        <path
          d='M44.4033 88.4618C52.8944 63.4734 76.932 44.4421 103.365 41.7786C129.797 39.1151 157.197 52.984 170.579 75.7242C178.226 88.7339 181.494 104.042 190.057 116.486C202.545 134.694 226.366 144.496 248.168 140.4C266.924 136.878 282.601 124.455 300.29 117.345C318.448 110.049 339.311 108.574 357.7 115.269C376.089 121.963 391.549 137.307 396.339 156.16C401.195 175.27 394.897 195.998 383.044 211.821C371.191 227.645 354.382 239.13 336.822 248.337C299.668 267.741 258.379 278.051 216.396 278.409C166.662 278.782 115.268 263.796 79.5724 229.421C43.8766 195.046 25.2497 134.021 44.4033 88.4618Z'
          fill='#F5F5F5'
        />
        <path
          d='M346.886 94.7268C346.351 93.282 345.452 91.9972 344.275 90.9946C343.098 89.992 341.682 89.3049 340.162 88.9988C337.132 88.34 333.374 87.4236 330.632 88.8699C328.923 89.7792 327.292 91.0751 326.643 92.8866C325.878 95.0346 327.191 99.0013 327.848 101.185C328.504 103.369 329.009 105.868 327.848 107.822C326.686 109.777 324.291 110.493 322.798 112.169C321.825 113.369 321.361 114.899 321.505 116.434C321.65 117.968 322.39 119.386 323.57 120.388C320.901 120.281 318.693 123.016 318.837 125.665C318.982 128.314 321.001 130.606 323.375 131.765C325.748 132.925 328.533 133.126 331.202 133.083C334.515 133.028 337.813 132.63 341.042 131.894C343.704 131.286 346.475 130.341 348.177 128.221C349.743 126.274 350.132 123.646 350.096 121.154C349.952 112.34 345.14 103.691 347.001 95.0776'
          fill='#263238'
        />
        <path
          d='M270.12 112.913C270.12 112.913 264.839 109.39 263.548 108.259C261.138 106.111 261.319 105.918 261.607 105.674C262.386 105.016 265.986 109.254 265.734 107.865C265.481 106.476 260.734 103.24 261.578 102.588C262.675 101.736 266.369 106.219 266.708 106.577C267.047 106.935 267.566 106.441 267.242 106.133C266.773 105.696 261.997 100.977 263.065 100.133C263.663 99.6672 264.385 100.849 264.385 100.849C264.385 100.849 268.533 105.603 269.031 105.188C269.529 104.772 267.184 101.887 266.325 100.977C265.467 100.068 265.553 99.302 266.26 99.2519C266.737 99.2161 269.427 101.944 270.257 102.832C271.087 103.72 273.309 106.326 273.222 104.149C273.222 104.114 273.222 104.085 273.222 104.049C273.078 101.901 273.684 100.211 274.196 99.839C274.313 99.7543 274.453 99.7067 274.598 99.7022C274.743 99.6978 274.886 99.7367 275.008 99.814C275.13 99.8912 275.226 100.003 275.284 100.135C275.341 100.268 275.356 100.414 275.329 100.555C275.333 100.66 275.316 100.765 275.278 100.863C275.278 100.906 275.278 100.97 275.228 101.02C275.119 101.748 275.087 102.485 275.134 103.219C275.153 103.876 275.287 104.525 275.531 105.137C275.812 105.853 276.086 106.448 276.324 106.942L277.81 110.293L273.648 114.188L270.12 112.913Z'
          fill='#B78876'
        />
        <path
          d='M365.159 225.239L369.387 321.183L381.406 321.527C381.406 321.527 385.013 268.643 384.248 263.338C383.483 258.032 381.586 229.442 381.586 229.442L365.159 225.239Z'
          fill='#B78876'
        />
        <path
          d='M346.828 225.239L351.063 321.183L363.075 321.527C363.075 321.527 366.682 268.643 365.96 263.338C365.239 258.032 363.291 229.442 363.291 229.442L346.828 225.239Z'
          fill='#B78876'
        />
        <path
          d='M348.61 265.564L348.754 268.822L365.967 269.882V265.106L348.61 265.564Z'
          fill='#AA6550'
        />
        <path
          d='M366.92 265.085L367.129 269.882L384.263 270.984L384.356 264.626L366.92 265.085Z'
          fill='#AA6550'
        />
        <path
          d='M390.561 264.462C391.155 264.449 391.74 264.314 392.28 264.067C392.819 263.819 393.302 263.464 393.697 263.023C394.092 262.583 394.392 262.066 394.577 261.505C394.762 260.945 394.829 260.352 394.774 259.765C393.67 247.55 390.589 214.356 388.649 203.05C387.153 194.787 383.677 187.003 378.513 180.353L366.71 162.868L366.516 149.1C366.516 149.1 368.269 139.291 368.262 139.14C367.951 127.749 351.799 125.973 343.733 127.355L338.965 127.484H337.955L299.309 126.553L276.62 105.188L269.629 115.276L294.756 140.68L328.245 144.84C328.281 145.369 328.18 146.673 328.064 148.212C327.689 152.873 327.509 158.451 333.612 163.413C338.453 174.038 341.887 182.931 340.826 194.967H340.891L341.533 265.765L390.561 264.462Z'
          fill='#455A64'
        />
        <path
          d='M366.35 162.711C366.089 163.021 365.799 163.306 365.484 163.563C365.203 163.835 364.842 164.15 364.417 164.501C363.991 164.852 363.508 165.274 362.974 165.689C361.672 166.69 360.319 167.622 358.919 168.482C357.197 169.523 355.414 170.462 353.581 171.296C351.738 172.118 349.85 172.835 347.925 173.444C346.35 173.922 344.751 174.319 343.135 174.632C342.413 174.783 341.822 174.861 341.266 174.955C340.711 175.048 340.242 175.098 339.823 175.134C339.42 175.196 339.012 175.225 338.604 175.219C338.997 175.114 339.397 175.037 339.802 174.99C340.184 174.933 340.66 174.854 341.201 174.747C341.742 174.639 342.377 174.539 343.055 174.375C344.419 174.088 346.028 173.659 347.795 173.114C351.612 171.901 355.275 170.253 358.71 168.203C360.283 167.243 361.668 166.327 362.786 165.496C363.363 165.095 363.847 164.694 364.287 164.358C364.727 164.021 365.102 163.72 365.398 163.47C365.699 163.197 366.017 162.944 366.35 162.711Z'
          fill='#263238'
        />
        <path
          d='M366.638 162.976C366.827 162.82 367.035 162.69 367.259 162.589C367.842 162.272 368.445 161.99 369.062 161.744C369.978 161.378 370.918 161.074 371.876 160.835C372.417 160.699 372.994 160.577 373.593 160.484C373.906 160.42 374.225 160.385 374.545 160.377C374.745 160.374 374.94 160.443 375.093 160.57C375.174 160.64 375.237 160.727 375.277 160.825C375.317 160.924 375.333 161.03 375.324 161.136C375.289 161.494 375.131 161.83 374.877 162.088C374.651 162.328 374.382 162.525 374.083 162.668C373.537 162.911 372.955 163.065 372.359 163.126C371.366 163.249 370.365 163.289 369.365 163.248C368.7 163.226 368.036 163.154 367.382 163.033C367.133 163.005 366.89 162.94 366.66 162.84C366.66 162.783 367.692 162.983 369.358 162.99C370.324 162.986 371.288 162.917 372.244 162.783C372.801 162.717 373.344 162.565 373.853 162.331C374.107 162.207 374.337 162.037 374.531 161.83C374.727 161.638 374.851 161.386 374.884 161.114C374.884 160.871 374.733 160.742 374.459 160.728C374.166 160.741 373.874 160.774 373.586 160.828C372.994 160.921 372.431 161.036 371.89 161.157C370.942 161.375 370.007 161.647 369.091 161.973C367.591 162.532 366.667 163.033 366.638 162.976Z'
          fill='#263238'
        />
        <path
          d='M366.4 162.532C366.422 162.305 366.481 162.082 366.574 161.873C366.782 161.288 367.038 160.721 367.338 160.176C367.783 159.364 368.31 158.599 368.911 157.892C369.264 157.473 369.642 157.076 370.044 156.704C370.26 156.51 370.476 156.317 370.707 156.131C370.83 156.003 370.984 155.91 371.155 155.859C371.267 155.84 371.383 155.856 371.485 155.907C371.588 155.957 371.671 156.038 371.724 156.138C371.787 156.3 371.814 156.474 371.801 156.647C371.789 156.82 371.738 156.989 371.652 157.14C371.552 157.417 371.434 157.688 371.299 157.95C371.05 158.454 370.753 158.934 370.411 159.382C369.835 160.107 369.19 160.776 368.485 161.379C367.887 161.895 367.382 162.274 367.042 162.539C366.869 162.688 366.675 162.811 366.465 162.904C366.422 162.861 367.187 162.246 368.319 161.186C368.986 160.567 369.599 159.894 370.152 159.174C370.47 158.748 370.75 158.295 370.989 157.821C371.11 157.571 371.218 157.315 371.313 157.055C371.421 156.79 371.508 156.51 371.414 156.339C371.32 156.167 371.299 156.224 371.241 156.238C371.133 156.284 371.035 156.349 370.953 156.432C370.729 156.611 370.52 156.797 370.31 156.983C369.914 157.343 369.538 157.726 369.185 158.129C368.59 158.813 368.054 159.546 367.584 160.319C366.768 161.637 366.48 162.532 366.4 162.532Z'
          fill='#263238'
        />
        <path
          d='M351.056 139.198L340.877 204.683H353.249L366.711 146.279C368.752 135.768 363.695 132.267 357.758 132.811C357.549 132.811 357.347 132.854 357.145 132.882C355.591 133.169 354.167 133.933 353.075 135.066C351.982 136.199 351.276 137.644 351.056 139.198Z'
          fill='#455A64'
        />
        <path
          d='M360.434 132.983H360.066C359.814 132.983 359.453 132.925 358.97 132.904C357.571 132.853 356.178 133.122 354.901 133.692C354.004 134.148 353.223 134.799 352.614 135.596C351.887 136.544 351.442 137.675 351.33 138.861C350.912 141.41 350.407 144.303 349.887 147.51C347.903 160.398 345.162 178.112 342.139 197.702C341.771 200.072 341.418 202.42 341.043 204.726L340.862 204.511H353.235L353.061 204.647C356.625 189.919 359.706 177.181 361.899 168.131C363.003 163.62 363.883 160.012 364.496 157.527C364.806 156.303 365.044 155.379 365.217 154.663C365.297 154.348 365.362 154.112 365.405 153.947C365.448 153.782 365.477 153.704 365.477 153.704C365.472 153.789 365.458 153.873 365.434 153.954C365.434 154.133 365.347 154.377 365.275 154.67L364.626 157.534C364.034 160.026 363.183 163.635 362.13 168.167C359.965 177.246 356.928 189.998 353.422 204.733V204.876H340.653V204.661C341.014 202.356 341.375 200.007 341.742 197.637C344.816 178.048 347.593 160.32 349.606 147.46C350.125 144.253 350.652 141.36 351.085 138.818C351.142 138.496 351.2 138.188 351.251 137.88C351.322 137.575 351.424 137.278 351.554 136.992C351.786 136.456 352.081 135.949 352.434 135.482C353.066 134.67 353.875 134.012 354.8 133.555C356.105 132.999 357.523 132.754 358.941 132.839C359.417 132.839 359.785 132.933 360.03 132.954L360.434 132.983Z'
          fill='#263238'
        />
        <path
          d='M336.094 163.541C336.094 163.541 336.007 163.541 335.834 163.541C335.591 163.51 335.35 163.465 335.112 163.405C334.222 163.167 333.378 162.782 332.616 162.267C331.479 161.501 330.511 160.512 329.774 159.36C328.887 157.943 328.292 156.366 328.021 154.72C327.608 151.737 327.782 148.702 328.533 145.785C328.786 144.668 329.031 143.773 329.204 143.157C329.272 142.831 329.376 142.515 329.514 142.212C329.487 142.543 329.43 142.872 329.341 143.193C329.197 143.909 329.016 144.775 328.793 145.842C328.126 148.732 327.99 151.718 328.389 154.656C328.653 156.255 329.224 157.788 330.07 159.174C330.731 160.267 331.606 161.217 332.643 161.968C333.68 162.719 334.858 163.257 336.108 163.549L336.094 163.541Z'
          fill='#263238'
        />
        <path
          d='M347.427 159.911C346.307 159.819 345.234 159.421 344.328 158.761C343.421 158.102 342.716 157.206 342.291 156.174C342.96 157.012 343.717 157.778 344.549 158.458C345.456 159.039 346.42 159.526 347.427 159.911Z'
          fill='#263238'
        />
        <path
          d='M341.908 144.138C341.923 144.106 341.939 144.074 341.959 144.045C342.01 143.958 342.071 143.876 342.139 143.801C342.39 143.498 342.705 143.253 343.063 143.085C343.397 142.921 343.762 142.824 344.134 142.8C344.507 142.777 344.881 142.828 345.234 142.95C345.587 143.072 345.911 143.263 346.189 143.511C346.466 143.759 346.691 144.06 346.85 144.396C347.077 144.863 347.178 145.381 347.145 145.899C347.113 146.416 346.946 146.917 346.662 147.353L344.751 150.174L340.343 156.618V156.668H340.285L339.78 156.854L335.603 158.415L335.351 158.508V158.236C335.351 156.546 335.351 154.935 335.351 153.446V153.396V153.353L340.033 146.637L341.382 144.761C341.533 144.531 341.702 144.313 341.887 144.109C341.77 144.36 341.63 144.6 341.468 144.825L340.22 146.78L335.646 153.568V153.475C335.646 154.964 335.646 156.575 335.646 158.265L335.401 158.1L339.578 156.525L340.083 156.339L339.989 156.41L344.426 149.966L346.359 147.174C346.623 146.783 346.781 146.331 346.819 145.863C346.856 145.394 346.773 144.924 346.576 144.496C346.407 144.146 346.164 143.836 345.862 143.589C345.561 143.341 345.208 143.162 344.83 143.064C344.247 142.919 343.631 142.974 343.084 143.221C342.636 143.448 342.236 143.759 341.908 144.138Z'
          fill='#E0E0E0'
        />
        <path
          d='M340.234 156.453C339.388 156.096 338.594 155.627 337.875 155.057C337.079 154.587 336.351 154.011 335.711 153.346C336.557 153.719 337.35 154.2 338.07 154.778C338.864 155.238 339.592 155.801 340.234 156.453Z'
          fill='#E0E0E0'
        />
        <path
          d='M337.154 157.62C337.053 157.663 336.808 157.284 336.389 156.94C335.971 156.596 335.574 156.41 335.596 156.31C335.617 156.21 336.151 156.245 336.635 156.661C337.118 157.076 337.248 157.584 337.154 157.62Z'
          fill='#E0E0E0'
        />
        <path
          d='M346.612 147.081C345.799 146.705 345.039 146.223 344.354 145.649C343.599 145.172 342.915 144.593 342.32 143.93C343.136 144.301 343.896 144.783 344.578 145.362C345.335 145.836 346.02 146.414 346.612 147.081Z'
          fill='#E0E0E0'
        />
        <path
          d='M337.984 154.713C338.201 154.206 338.477 153.725 338.806 153.281C339.355 152.429 340.134 151.276 341.007 150.009C341.88 148.742 342.688 147.603 343.279 146.787C343.581 146.331 343.916 145.898 344.282 145.491C344.404 145.57 343.07 147.689 341.317 150.217C340.444 151.484 339.629 152.623 339.023 153.432C338.73 153.899 338.382 154.33 337.984 154.713Z'
          fill='#E0E0E0'
        />
        <path
          d='M341.454 204.891L340.408 208.334L330.495 210.132H349.519L351.178 204.905L341.454 204.891Z'
          fill='#B78876'
        />
        <path
          d='M350.609 321.541L350.681 318.749L363.969 319.093L363.875 325.057L363.053 325.093C359.395 325.221 344.44 325.494 342.009 324.713C339.29 323.832 350.609 321.541 350.609 321.541Z'
          fill='#407BFF'
        />
        <g opacity='0.4'>
          <path
            d='M345.068 322.859C345.068 322.859 341.1 323.911 341.584 324.563C342.067 325.214 357.188 325.508 363.868 325.057V324.763L346.223 324.57C346.223 324.57 345.84 322.916 345.068 322.859Z'
            fill='white'
          />
        </g>
        <path
          d='M364.063 324.756H363.839H363.183L360.773 324.813C358.739 324.813 355.925 324.856 352.838 324.813C349.75 324.77 346.922 324.641 344.902 324.534L342.5 324.391L341.843 324.334C341.769 324.339 341.694 324.339 341.62 324.334H341.843H342.5L344.909 324.427C346.944 324.505 349.75 324.599 352.845 324.656C355.94 324.713 358.753 324.72 360.78 324.72H363.839L364.063 324.756Z'
          fill='#263238'
        />
        <path
          d='M346.208 324.778C346.107 324.38 345.964 323.993 345.782 323.625C345.547 323.287 345.276 322.975 344.974 322.694C345.367 322.878 345.694 323.176 345.914 323.547C346.134 323.919 346.237 324.348 346.208 324.778Z'
          fill='#263238'
        />
        <path
          d='M350.154 322.916C350.154 322.916 349.938 322.716 349.75 322.408C349.563 322.1 349.44 321.835 349.476 321.813C349.512 321.792 349.7 322.021 349.887 322.322C350.075 322.622 350.19 322.859 350.154 322.916Z'
          fill='#263238'
        />
        <path
          d='M351.236 322.343C351.236 322.343 351.006 322.229 350.775 322.021C350.544 321.813 350.4 321.62 350.428 321.591C350.457 321.563 350.667 321.699 350.89 321.906C351.114 322.114 351.273 322.307 351.236 322.343Z'
          fill='#263238'
        />
        <path
          d='M351.943 321.112C351.712 321.193 351.465 321.22 351.222 321.19C350.978 321.215 350.731 321.186 350.5 321.104C350.731 321.023 350.978 320.996 351.222 321.026C351.466 321.001 351.712 321.03 351.943 321.112Z'
          fill='#263238'
        />
        <path
          d='M352.189 320.245C351.948 320.367 351.685 320.438 351.415 320.453C351.145 320.468 350.876 320.426 350.623 320.331C350.883 320.29 351.146 320.276 351.409 320.288C351.667 320.245 351.928 320.231 352.189 320.245Z'
          fill='#263238'
        />
        <path
          d='M349.728 321.727C349.447 321.778 349.159 321.778 348.877 321.727C348.57 321.685 348.268 321.613 347.975 321.513C347.795 321.457 347.621 321.383 347.456 321.291C347.358 321.231 347.286 321.136 347.254 321.026C347.24 320.962 347.246 320.896 347.27 320.836C347.295 320.776 347.337 320.725 347.391 320.689C347.718 320.537 348.088 320.502 348.439 320.591C348.789 320.68 349.097 320.887 349.31 321.176C349.441 321.345 349.532 321.541 349.577 321.749C349.591 321.825 349.591 321.902 349.577 321.978C349.492 321.721 349.365 321.479 349.202 321.262C349.013 321.037 348.762 320.87 348.48 320.782C348.317 320.722 348.142 320.695 347.967 320.702C347.793 320.709 347.621 320.751 347.463 320.825C347.326 320.925 347.377 321.083 347.528 321.176C347.679 321.263 347.839 321.335 348.004 321.391C348.288 321.494 348.58 321.576 348.877 321.634C349.404 321.692 349.728 321.699 349.728 321.727Z'
          fill='#263238'
        />
        <path
          d='M349.505 321.763C349.408 321.584 349.377 321.376 349.418 321.176C349.451 320.957 349.535 320.749 349.663 320.567C349.737 320.441 349.842 320.336 349.968 320.262C350.095 320.188 350.238 320.148 350.385 320.145C350.471 320.164 350.547 320.216 350.596 320.289C350.644 320.363 350.662 320.453 350.645 320.539C350.622 320.675 350.57 320.804 350.493 320.918C350.376 321.101 350.238 321.269 350.082 321.419C349.946 321.57 349.775 321.685 349.584 321.756C349.584 321.756 349.757 321.606 349.995 321.341C350.137 321.188 350.261 321.02 350.363 320.84C350.486 320.668 350.572 320.346 350.363 320.303C350.154 320.26 349.93 320.489 349.801 320.653C349.677 320.814 349.591 321 349.548 321.198C349.517 321.385 349.503 321.574 349.505 321.763Z'
          fill='#263238'
        />
        <path
          d='M363.861 321.155C363.861 321.155 363.486 321.112 362.887 321.155C362.104 321.222 361.356 321.51 360.731 321.984C360.106 322.459 359.631 323.1 359.359 323.832C359.15 324.391 359.121 324.77 359.093 324.77C359.075 324.683 359.075 324.593 359.093 324.505C359.123 324.262 359.176 324.023 359.251 323.789C359.505 323.024 359.985 322.353 360.628 321.861C361.271 321.37 362.048 321.081 362.858 321.033C363.099 321.02 363.341 321.032 363.58 321.069C363.774 321.119 363.868 321.14 363.861 321.155Z'
          fill='#263238'
        />
        <path
          d='M357.426 323.546C356.79 323.677 356.141 323.727 355.493 323.696C354.84 323.718 354.187 323.653 353.552 323.503C353.552 323.46 354.425 323.503 355.493 323.539C356.56 323.575 357.426 323.496 357.426 323.546Z'
          fill='#263238'
        />
        <path
          d='M360.022 323.589C360.058 323.625 359.95 323.768 359.857 323.961C359.763 324.155 359.713 324.334 359.669 324.326C359.626 324.319 359.605 324.119 359.713 323.897C359.821 323.675 359.986 323.553 360.022 323.589Z'
          fill='#263238'
        />
        <path
          d='M361.026 322.4C361.026 322.4 360.968 322.565 360.831 322.701C360.694 322.837 360.55 322.923 360.521 322.887C360.492 322.851 360.579 322.723 360.716 322.587C360.853 322.451 360.997 322.365 361.026 322.4Z'
          fill='#263238'
        />
        <path
          d='M362.44 321.871C362.44 321.921 362.252 321.914 362.057 321.978C361.863 322.042 361.718 322.143 361.682 322.114C361.646 322.085 361.762 321.899 362.007 321.828C362.252 321.756 362.454 321.835 362.44 321.871Z'
          fill='#263238'
        />
        <path
          d='M363.435 321.634C363.435 321.67 363.377 321.742 363.269 321.778C363.161 321.813 363.053 321.82 363.039 321.778C363.024 321.735 363.103 321.67 363.211 321.627C363.319 321.584 363.42 321.591 363.435 321.634Z'
          fill='#263238'
        />
        <path
          d='M368.485 321.541L368.557 318.749L381.853 319.093L381.759 325.057L380.908 325.064C377.243 325.193 362.288 325.465 359.857 324.684C357.173 323.832 368.485 321.541 368.485 321.541Z'
          fill='#407BFF'
        />
        <g opacity='0.4'>
          <path
            d='M362.952 322.859C362.952 322.859 358.984 323.911 359.468 324.563C359.951 325.214 375.072 325.508 381.76 325.057V324.763L364.114 324.57C364.114 324.57 363.724 322.916 362.952 322.859Z'
            fill='white'
          />
        </g>
        <path
          d='M381.947 324.756H381.723H381.067L378.657 324.813C376.623 324.813 373.809 324.856 370.722 324.813C367.634 324.77 364.806 324.641 362.786 324.534L360.384 324.391L359.727 324.334H360.384L362.793 324.427C364.828 324.505 367.634 324.599 370.729 324.656C373.824 324.713 376.645 324.72 378.664 324.72H381.731L381.947 324.756Z'
          fill='#263238'
        />
        <path
          d='M364.092 324.778C363.949 323.957 363.51 323.217 362.858 322.694C363.268 322.854 363.604 323.157 363.803 323.546C364.051 323.906 364.154 324.346 364.092 324.778Z'
          fill='#263238'
        />
        <path
          d='M368.038 322.916C368.038 322.916 367.821 322.716 367.634 322.408C367.446 322.1 367.323 321.835 367.36 321.813C367.396 321.792 367.583 322.021 367.771 322.322C367.958 322.622 368.081 322.859 368.038 322.916Z'
          fill='#263238'
        />
        <path
          d='M369.12 322.343C369.12 322.343 368.889 322.229 368.666 322.021C368.442 321.813 368.283 321.62 368.312 321.591C368.341 321.563 368.55 321.699 368.774 321.906C368.998 322.114 369.156 322.307 369.12 322.343Z'
          fill='#263238'
        />
        <path
          d='M369.827 321.112C369.596 321.193 369.349 321.22 369.106 321.19C368.862 321.215 368.615 321.186 368.384 321.104C368.615 321.023 368.862 320.996 369.106 321.026C369.35 321.001 369.596 321.03 369.827 321.112Z'
          fill='#263238'
        />
        <path
          d='M370.087 320.245C369.846 320.367 369.583 320.438 369.313 320.453C369.044 320.468 368.774 320.426 368.521 320.331C368.781 320.29 369.045 320.276 369.308 320.288C369.565 320.245 369.826 320.231 370.087 320.245Z'
          fill='#263238'
        />
        <path
          d='M367.612 321.727C367.331 321.778 367.043 321.778 366.761 321.727C366.454 321.685 366.152 321.613 365.859 321.513C365.68 321.459 365.509 321.384 365.347 321.291C365.296 321.262 365.252 321.224 365.216 321.179C365.18 321.133 365.153 321.081 365.138 321.026C365.125 320.962 365.131 320.897 365.156 320.837C365.18 320.777 365.221 320.726 365.275 320.689C365.602 320.537 365.972 320.502 366.322 320.591C366.672 320.68 366.981 320.887 367.194 321.176C367.325 321.345 367.416 321.541 367.461 321.749C367.48 321.824 367.48 321.903 367.461 321.978C367.38 321.719 367.253 321.477 367.086 321.262C366.897 321.037 366.646 320.87 366.364 320.782C366.202 320.722 366.028 320.694 365.855 320.702C365.681 320.709 365.511 320.751 365.354 320.825C365.217 320.925 365.261 321.083 365.412 321.176C365.563 321.263 365.723 321.335 365.888 321.391C366.173 321.493 366.464 321.575 366.761 321.634C367.288 321.692 367.612 321.699 367.612 321.727Z'
          fill='#263238'
        />
        <path
          d='M367.389 321.763C367.292 321.584 367.261 321.376 367.302 321.176C367.335 320.957 367.419 320.749 367.547 320.567C367.622 320.442 367.727 320.338 367.853 320.264C367.979 320.19 368.122 320.149 368.269 320.145C368.354 320.166 368.428 320.218 368.475 320.291C368.522 320.365 368.539 320.454 368.521 320.539C368.499 320.675 368.447 320.804 368.37 320.918C368.253 321.101 368.115 321.269 367.959 321.419C367.824 321.571 367.653 321.687 367.461 321.756C367.461 321.756 367.634 321.606 367.872 321.341C368.014 321.188 368.137 321.02 368.24 320.84C368.363 320.668 368.449 320.346 368.24 320.303C368.031 320.26 367.807 320.489 367.677 320.653C367.554 320.814 367.467 321 367.425 321.198C367.397 321.385 367.385 321.574 367.389 321.763Z'
          fill='#263238'
        />
        <path
          d='M381.745 321.155C381.745 321.155 381.37 321.112 380.771 321.155C379.988 321.222 379.24 321.51 378.615 321.984C377.99 322.459 377.515 323.1 377.244 323.832C377.034 324.391 377.005 324.77 376.977 324.77C376.961 324.683 376.961 324.593 376.977 324.506C377 324.261 377.053 324.021 377.135 323.79C377.388 323.022 377.868 322.348 378.513 321.856C379.162 321.366 379.943 321.08 380.757 321.033C380.998 321.019 381.24 321.031 381.478 321.069C381.63 321.119 381.752 321.14 381.745 321.155Z'
          fill='#263238'
        />
        <path
          d='M375.31 323.546C374.674 323.677 374.025 323.727 373.376 323.696C372.724 323.718 372.071 323.653 371.436 323.503C371.436 323.46 372.309 323.503 373.376 323.539C374.444 323.575 375.31 323.496 375.31 323.546Z'
          fill='#263238'
        />
        <path
          d='M377.906 323.589C377.906 323.625 377.834 323.768 377.741 323.961C377.647 324.155 377.597 324.334 377.553 324.326C377.51 324.319 377.489 324.119 377.597 323.897C377.705 323.675 377.878 323.553 377.906 323.589Z'
          fill='#263238'
        />
        <path
          d='M378.91 322.4C378.91 322.4 378.852 322.565 378.715 322.701C378.578 322.837 378.434 322.923 378.405 322.887C378.376 322.851 378.462 322.723 378.599 322.587C378.737 322.451 378.881 322.365 378.91 322.4Z'
          fill='#263238'
        />
        <path
          d='M380.324 321.871C380.324 321.921 380.136 321.914 379.941 321.978C379.747 322.042 379.602 322.143 379.566 322.114C379.53 322.085 379.646 321.899 379.891 321.828C380.136 321.756 380.338 321.835 380.324 321.871Z'
          fill='#263238'
        />
        <path
          d='M381.326 321.634C381.326 321.67 381.261 321.742 381.153 321.778C381.045 321.813 380.937 321.82 380.923 321.778C380.908 321.735 380.987 321.67 381.095 321.627C381.203 321.584 381.311 321.591 381.326 321.634Z'
          fill='#263238'
        />
        <path
          d='M351.236 96.1659V98.2638L351.38 129.639C351.38 133.276 348.25 136.19 344.318 136.183C340.35 136.183 337.104 133.176 336.995 129.496C336.916 125.636 336.757 121.57 336.757 121.57C336.757 121.57 331.707 120.854 330.582 114.359C330.005 111.145 330.07 105.846 330.272 101.514C330.313 100.59 330.542 99.6841 330.946 98.8504C331.349 98.0166 331.919 97.2726 332.62 96.6631C333.321 96.0536 334.139 95.5913 335.025 95.3041C335.911 95.0168 336.846 94.9106 337.775 94.9917L351.236 96.1659Z'
          fill='#D5A390'
        />
        <path
          d='M331.801 105.467C331.803 105.683 331.89 105.891 332.044 106.044C332.197 106.198 332.405 106.286 332.623 106.29C332.73 106.297 332.838 106.282 332.939 106.247C333.04 106.212 333.133 106.157 333.212 106.086C333.292 106.014 333.356 105.928 333.401 105.831C333.446 105.735 333.471 105.63 333.475 105.524C333.473 105.307 333.386 105.1 333.232 104.946C333.079 104.793 332.87 104.704 332.652 104.701C332.545 104.693 332.438 104.707 332.336 104.741C332.234 104.776 332.141 104.831 332.061 104.903C331.982 104.974 331.918 105.061 331.873 105.158C331.828 105.255 331.804 105.36 331.801 105.467Z'
          fill='#263238'
        />
        <path
          d='M331.469 104.25C331.577 104.357 332.191 103.877 333.107 103.863C334.023 103.849 334.68 104.285 334.773 104.178C334.867 104.071 334.708 103.935 334.42 103.727C334.022 103.466 333.554 103.334 333.078 103.347C332.61 103.353 332.155 103.506 331.779 103.784C331.505 104.006 331.419 104.199 331.469 104.25Z'
          fill='#263238'
        />
        <path
          d='M340.35 105.316C340.352 105.533 340.439 105.74 340.593 105.894C340.746 106.048 340.954 106.136 341.172 106.14C341.279 106.147 341.387 106.132 341.488 106.097C341.589 106.062 341.682 106.007 341.761 105.935C341.841 105.864 341.905 105.777 341.95 105.681C341.995 105.584 342.02 105.48 342.024 105.374C342.026 105.159 341.945 104.953 341.797 104.796C341.649 104.64 341.446 104.547 341.23 104.536C341.12 104.524 341.008 104.535 340.902 104.569C340.796 104.602 340.698 104.658 340.615 104.732C340.532 104.805 340.465 104.895 340.42 104.996C340.374 105.097 340.35 105.206 340.35 105.316Z'
          fill='#263238'
        />
        <path
          d='M340.343 104.264C340.451 104.364 341.064 103.892 341.98 103.877C342.896 103.863 343.553 104.3 343.647 104.185C343.741 104.071 343.589 103.942 343.293 103.741C342.897 103.483 342.433 103.348 341.959 103.355C341.487 103.364 341.031 103.519 340.653 103.798C340.386 104.013 340.292 104.214 340.343 104.264Z'
          fill='#263238'
        />
        <path
          d='M337.19 110.98C336.72 110.845 336.236 110.766 335.747 110.744C335.517 110.744 335.293 110.686 335.257 110.529C335.222 110.282 335.27 110.03 335.394 109.813C335.603 109.254 335.82 108.674 336.043 108.059C336.646 106.572 337.128 105.039 337.486 103.476C336.762 104.91 336.154 106.4 335.668 107.93C335.452 108.546 335.25 109.133 335.048 109.698C334.911 109.976 334.875 110.292 334.947 110.593C334.981 110.673 335.033 110.745 335.099 110.802C335.166 110.859 335.244 110.9 335.329 110.923C335.459 110.955 335.592 110.972 335.726 110.973C336.212 111.034 336.704 111.037 337.19 110.98Z'
          fill='#263238'
        />
        <path
          d='M336.75 121.57C339.832 121.566 342.852 120.711 345.472 119.099C345.472 119.099 343.394 123.596 336.902 123.102L336.75 121.57Z'
          fill='#AA6550'
        />
        <path
          d='M337.623 113.128C337.791 112.911 338.011 112.74 338.263 112.631C338.516 112.522 338.792 112.479 339.066 112.505C339.266 112.516 339.462 112.568 339.641 112.658C339.82 112.748 339.978 112.874 340.105 113.028C340.229 113.175 340.304 113.356 340.32 113.547C340.335 113.739 340.29 113.93 340.191 114.095C340.05 114.259 339.863 114.377 339.653 114.434C339.444 114.492 339.221 114.486 339.015 114.417C338.588 114.273 338.193 114.047 337.854 113.751C337.751 113.68 337.661 113.593 337.587 113.493C337.553 113.443 337.535 113.385 337.535 113.325C337.535 113.265 337.553 113.206 337.587 113.157'
          fill='#AA6550'
        />
        <path
          d='M339.852 111.531C339.708 111.531 339.722 112.498 338.9 113.207C338.078 113.916 337.024 113.822 337.017 113.923C337.01 114.023 337.255 114.109 337.695 114.109C338.271 114.105 338.827 113.903 339.268 113.536C339.689 113.172 339.948 112.657 339.989 112.104C340.033 111.768 339.924 111.524 339.852 111.531Z'
          fill='#263238'
        />
        <path
          d='M352.874 125.901C350.933 125.271 348.639 124.634 347.874 122.751C347.152 120.835 347.066 118.74 347.629 116.772C347.874 115.863 348.221 114.868 347.802 114.023C347.424 113.454 346.933 112.967 346.359 112.591C345.216 111.533 344.527 110.08 344.433 108.531C344.34 106.996 344.587 105.459 345.155 104.028C345.458 103.233 345.811 102.366 345.515 101.579C345.241 101.045 344.843 100.584 344.354 100.233C343.407 99.3855 342.62 98.3767 342.031 97.2542C341.782 96.8795 341.62 96.4542 341.558 96.0095C341.495 95.5648 341.534 95.1119 341.67 94.6838C342.125 93.7029 343.358 93.3735 344.44 93.2518C347.023 93.0084 349.902 93.531 351.582 95.4929C353.09 97.2471 353.307 99.7316 353.451 102.037C353.913 109.305 354.367 116.636 353.227 123.832'
          fill='#263238'
        />
        <path
          d='M327.682 95.858C328.045 96.0748 328.421 96.2709 328.807 96.4452C329.151 96.6803 329.472 96.9463 329.767 97.2399C329.94 97.3974 330.127 97.5764 330.293 97.7554C330.337 97.8056 330.366 97.8485 330.416 97.8986L330.604 98.0776L331.065 98.5001C331.515 98.8973 331.998 99.2566 332.508 99.5741C333.601 100.278 334.863 100.68 336.165 100.736C337.466 100.793 338.759 100.503 339.91 99.8963C340.962 99.3676 341.88 98.6086 342.594 97.6767C343.676 96.1874 343.827 94.9917 343.315 94.3544C342.803 93.7172 341.497 93.8246 340.061 94.2041C339.349 94.3965 338.627 94.5495 337.897 94.6623C337.334 94.758 336.758 94.758 336.195 94.6623C335.918 94.6054 335.649 94.5165 335.394 94.3974C335.026 94.2399 334.564 94.0036 334.03 93.7673C333.763 93.6528 333.482 93.531 333.186 93.4165L332.725 93.2661L332.205 93.1158C331.563 92.9641 330.899 92.9229 330.243 92.994C329.721 93.0237 329.213 93.1674 328.754 93.415C328.295 93.6626 327.897 94.0078 327.588 94.426C327.42 94.6329 327.336 94.8949 327.354 95.1602C327.371 95.4254 327.488 95.6745 327.682 95.858Z'
          fill='#263238'
        />
        <path
          d='M340.047 100.755C340.141 100.999 341.043 100.863 342.103 100.97C343.164 101.078 344.022 101.35 344.159 101.128C344.224 101.028 344.065 100.798 343.719 100.569C343.253 100.29 342.729 100.119 342.187 100.07C341.644 100.02 341.098 100.093 340.588 100.283C340.199 100.448 340.011 100.641 340.047 100.755Z'
          fill='#263238'
        />
        <path
          d='M331.758 101.672C331.917 101.872 332.537 101.672 333.28 101.629C334.023 101.586 334.665 101.743 334.81 101.529C334.954 101.314 334.766 101.221 334.485 101.027C334.109 100.805 333.674 100.697 333.236 100.719C332.799 100.741 332.377 100.89 332.025 101.149C331.765 101.343 331.686 101.55 331.758 101.672Z'
          fill='#263238'
        />
        <path
          d='M352.535 94.8341C354.436 95.1831 356.4 94.8127 358.039 93.7959C358.841 93.2745 359.466 92.5261 359.834 91.6478C360.202 90.7694 360.295 89.8016 360.102 88.8699C359.67 87.5957 358.802 86.5117 357.65 85.8054C356.689 85.0649 355.504 84.6687 354.288 84.6813C353.503 84.7824 352.755 85.072 352.109 85.525C351.463 85.9779 350.938 86.5804 350.58 87.2803C350.101 88.0788 349.814 88.9757 349.74 89.9018C349.666 90.8279 349.808 91.7585 350.154 92.6217C350.525 93.4761 351.148 94.1986 351.942 94.6935C352.735 95.1883 353.662 95.4321 354.598 95.3926'
          fill='#263238'
        />
        <path
          d='M354.872 95.0919C354.872 95.1348 354.562 95.2208 354.035 95.2064C353.347 95.1719 352.675 94.9887 352.066 94.6697C351.456 94.3506 350.924 93.9034 350.507 93.3592C350.197 92.9367 350.096 92.6432 350.132 92.6432C350.746 93.3186 351.456 93.9023 352.239 94.3759C353.078 94.7367 353.965 94.9778 354.872 95.0919Z'
          fill='#407BFF'
        />
        <path
          d='M345.97 92.9797C347.35 92.6285 348.792 92.5835 350.192 92.8478C351.592 93.1122 352.917 93.6794 354.071 94.5092C355.225 95.339 356.181 96.411 356.871 97.649C357.56 98.8869 357.966 100.26 358.061 101.672C358.284 105.037 356.755 108.567 357.996 111.696C359.128 114.56 362.274 116.286 363.132 119.221C363.991 122.157 362.259 125.307 362.851 128.3C363.334 130.792 365.354 132.868 365.448 135.396C365.441 136.338 365.211 137.266 364.774 138.103C364.338 138.941 363.709 139.664 362.937 140.214C361.384 141.303 359.561 141.951 357.664 142.09C355.709 142.298 353.595 142.09 352.022 140.945C349.916 139.384 349.339 136.541 349.029 133.949C348.112 126.266 348.228 118.496 349.375 110.844'
          fill='#263238'
        />
        <path
          d='M187.569 245.43C193.174 245.259 190.534 237.096 190.397 228.246C190.31 222.683 186.587 217.22 182.519 213.382C180.453 211.573 178.02 210.225 175.384 209.43C168.395 207.203 160.96 206.728 153.741 208.048C153.741 208.048 152.075 246.547 187.569 245.43Z'
          fill='#455A64'
        />
        <path
          d='M203.44 149.68C203.281 149.938 201.521 153.26 201.521 153.26L202.964 147.661C203.418 145.892 202.314 146.078 201.997 146.708C201.679 147.338 199.667 152.68 199.667 152.68C201.535 145.727 200.951 145.119 200.388 145.004C199.825 144.89 198.224 151.255 198.224 151.255C197.798 150.53 197.223 149.903 196.536 149.415C194.84 148.262 192.02 149.415 191.883 150.21C191.746 151.004 192.756 151.219 192.756 151.219C193.152 150.921 193.628 150.745 194.125 150.712C194.621 150.679 195.117 150.792 195.55 151.036C195.983 151.28 196.334 151.644 196.56 152.084C196.787 152.523 196.879 153.019 196.824 153.51C196.79 153.875 196.629 154.216 196.369 154.475C196.108 154.735 195.765 154.896 195.398 154.932C195.031 154.967 194.663 154.875 194.357 154.67C194.051 154.466 193.827 154.162 193.722 153.811C193.001 150.675 191.94 151.699 191.94 151.699L193.03 158.608L191.875 162.088L185.621 179L173.573 166.549C173.573 166.549 167.131 180.675 166.979 181.155C166.878 181.47 173.018 187.814 177.079 191.931C178.505 193.386 180.298 194.433 182.272 194.964C184.246 195.494 186.327 195.487 188.298 194.945C190.268 194.402 192.055 193.344 193.472 191.88C194.888 190.416 195.882 188.601 196.348 186.625L201.11 162.281L203.339 154.298L204.334 150.303C204.565 149.315 203.858 149 203.44 149.68Z'
          fill='#FFBE9D'
        />
        <path
          d='M153.099 325.408C153.099 325.408 148.554 321.377 140.323 321.047V249.111H137.271V321.09C129.393 321.656 124.726 325.386 124.726 325.386H137.271V325.436H140.323V325.386L153.099 325.408Z'
          fill='#263238'
        />
        <path
          d='M133.801 174.024C130.454 173.723 127.049 173.458 123.745 174.117C120.441 174.776 117.216 176.48 115.463 179.329C113.71 182.179 113.638 185.773 113.638 189.103C113.638 203.27 113.638 217.435 113.638 231.597H151.873L133.801 174.024Z'
          fill='#455A64'
        />
        <path
          d='M113.638 229.635C113.465 238.013 114.251 244.822 120.484 248.108C125.144 250.578 130.786 250.636 136.146 250.629L159.376 250.593C161.727 250.593 164.252 250.543 166.171 249.275C167.179 248.558 167.962 247.572 168.428 246.432C168.894 245.291 169.024 244.043 168.804 242.831C168.444 240.447 167.008 238.299 165.27 236.509C158.56 229.578 147.956 227.523 138.094 225.841'
          fill='#455A64'
        />
        <path
          d='M142.668 135.03C142.752 135.296 142.756 135.58 142.68 135.848C142.604 136.116 142.452 136.356 142.24 136.539C142.029 136.722 141.769 136.84 141.491 136.879C141.213 136.917 140.93 136.875 140.676 136.756C139.731 136.298 139.602 135.016 139.775 133.985C139.948 132.954 140.28 131.837 139.832 130.906C139.394 130.237 138.843 129.648 138.202 129.166C137.702 128.675 137.324 128.075 137.096 127.414C136.868 126.754 136.797 126.05 136.889 125.357C137.001 124.667 137.301 124.021 137.758 123.49C138.215 122.958 138.81 122.561 139.479 122.343C140.857 121.942 142.559 122.343 143.577 121.34C144.298 120.624 144.341 119.436 144.904 118.577C145.784 117.231 147.667 117.002 149.268 117.195C150.87 117.388 152.5 117.911 154.059 117.481C155.357 117.138 156.418 116.221 157.666 115.648C158.996 115.027 160.488 114.833 161.934 115.094C163.38 115.355 164.708 116.058 165.733 117.104C166.757 118.151 167.426 119.489 167.647 120.931C167.868 122.373 167.63 123.848 166.965 125.15C166.489 126.073 165.522 128.73 162.55 128.73'
          fill='#263238'
        />
        <path
          d='M142.047 123.918L142.091 126.066L142.523 157.964C142.574 161.666 145.82 164.573 149.817 164.494C153.85 164.408 157.125 161.308 157.139 157.563C157.139 153.639 157.233 149.508 157.233 149.508C157.233 149.508 162.333 148.663 163.387 142.062C163.913 138.782 163.747 133.398 163.466 129.002C163.408 128.063 163.159 127.146 162.734 126.305C162.309 125.465 161.718 124.718 160.994 124.111C160.271 123.503 159.431 123.048 158.526 122.771C157.62 122.494 156.668 122.402 155.725 122.5L142.047 123.918Z'
          fill='#FFBE9D'
        />
        <path
          d='M162.11 132.725C162.112 132.945 162.027 133.158 161.873 133.317C161.719 133.476 161.509 133.569 161.287 133.577C161.179 133.587 161.069 133.575 160.966 133.541C160.862 133.508 160.766 133.455 160.684 133.384C160.602 133.313 160.535 133.226 160.487 133.129C160.44 133.032 160.413 132.926 160.407 132.818C160.403 132.596 160.487 132.382 160.641 132.221C160.795 132.061 161.006 131.966 161.23 131.959C161.448 131.945 161.664 132.018 161.829 132.162C161.994 132.305 162.095 132.508 162.11 132.725Z'
          fill='#263238'
        />
        <path
          d='M162.319 131.78C162.218 131.887 161.597 131.415 160.652 131.415C159.707 131.415 159.058 131.873 158.964 131.766C158.87 131.658 159.022 131.515 159.31 131.3C159.71 131.029 160.183 130.885 160.667 130.885C161.144 130.886 161.608 131.036 161.994 131.314C162.247 131.529 162.319 131.744 162.319 131.78Z'
          fill='#263238'
        />
        <path
          d='M152.94 132.725C152.942 132.945 152.858 133.158 152.704 133.317C152.55 133.476 152.34 133.569 152.118 133.577C152.01 133.586 151.901 133.573 151.798 133.539C151.694 133.506 151.599 133.452 151.517 133.381C151.435 133.311 151.368 133.224 151.32 133.128C151.272 133.031 151.244 132.926 151.238 132.818C151.236 132.597 151.321 132.384 151.474 132.223C151.627 132.063 151.838 131.968 152.06 131.959C152.169 131.951 152.278 131.965 152.381 131.999C152.485 132.034 152.58 132.088 152.662 132.159C152.744 132.231 152.81 132.317 152.858 132.414C152.906 132.511 152.934 132.617 152.94 132.725Z'
          fill='#263238'
        />
        <path
          d='M153.272 131.944C153.164 132.059 152.551 131.586 151.599 131.586C150.646 131.586 150.012 132.045 149.911 131.93C149.81 131.816 149.968 131.687 150.264 131.472C150.659 131.198 151.131 131.053 151.613 131.057C152.092 131.057 152.559 131.208 152.948 131.486C153.229 131.701 153.323 131.902 153.272 131.944Z'
          fill='#263238'
        />
        <path
          d='M156.598 138.703C157.083 138.555 157.585 138.466 158.091 138.439C158.329 138.439 158.546 138.367 158.589 138.209C158.611 137.96 158.556 137.71 158.43 137.493C158.214 136.935 157.983 136.341 157.709 135.725C157.068 134.224 156.547 132.675 156.151 131.092C156.917 132.537 157.563 134.041 158.084 135.589C158.315 136.212 158.531 136.806 158.748 137.372C158.892 137.652 158.935 137.973 158.87 138.281C158.836 138.364 158.783 138.438 158.715 138.497C158.648 138.557 158.568 138.6 158.481 138.625C158.351 138.659 158.218 138.678 158.084 138.682C157.592 138.75 157.092 138.757 156.598 138.703Z'
          fill='#263238'
        />
        <path
          d='M157.269 149.486C154.139 149.529 151.058 148.712 148.367 147.124C148.367 147.124 150.531 151.656 157.182 151.04L157.269 149.486Z'
          fill='#EB996E'
        />
        <path
          d='M153.9 139.334C154.052 139.334 154.052 140.315 154.903 141.016C155.754 141.718 156.815 141.61 156.822 141.732C156.829 141.854 156.591 141.926 156.144 141.94C155.558 141.946 154.989 141.748 154.535 141.381C154.096 141.024 153.816 140.51 153.756 139.949C153.72 139.57 153.835 139.326 153.9 139.334Z'
          fill='#263238'
        />
        <path
          d='M153.51 128.379C153.417 128.622 152.5 128.508 151.425 128.637C150.351 128.765 149.478 129.059 149.333 128.837C149.268 128.73 149.42 128.493 149.773 128.257C150.239 127.956 150.771 127.772 151.325 127.72C151.875 127.653 152.434 127.717 152.955 127.906C153.344 128.064 153.546 128.257 153.51 128.379Z'
          fill='#263238'
        />
        <path
          d='M161.958 129.138C161.792 129.345 161.165 129.138 160.4 129.138C159.635 129.138 159 129.274 158.849 129.059C158.784 128.952 158.885 128.751 159.166 128.551C159.542 128.312 159.983 128.194 160.429 128.214C160.876 128.219 161.311 128.364 161.67 128.629C161.937 128.83 162.023 129.038 161.958 129.138Z'
          fill='#263238'
        />
        <path
          d='M163.69 126.052C163.69 126.052 160.292 127.813 155.754 125.73C152.76 124.362 149.305 123.761 148.54 124.369C147.775 124.978 147.739 127.999 144.846 128.973C144.846 128.973 145.229 134.701 142.913 134.579C140.597 134.458 140.951 122.923 140.951 122.923L147.321 122.586L153.446 121.233L159.282 121.863L163.812 122.944L163.69 126.052Z'
          fill='#263238'
        />
        <path
          d='M140.799 121.283C140.799 121.197 141.311 120.997 141.953 121.226C142.595 121.455 142.884 121.942 142.804 121.999C142.725 122.057 142.371 121.734 141.838 121.548C141.304 121.362 140.82 121.384 140.799 121.283Z'
          fill='#263238'
        />
        <path
          d='M142.718 119.343C142.826 119.343 142.79 120.023 142.913 120.775C143.035 121.527 143.367 122.121 143.288 122.207C143.209 122.293 142.696 121.734 142.567 120.861C142.438 120.353 142.492 119.816 142.718 119.343Z'
          fill='#263238'
        />
        <path
          d='M143.057 132.209C142.963 132.166 139.212 130.906 139.176 134.758C139.14 138.61 143.035 137.851 143.043 137.744C143.05 137.637 143.057 132.209 143.057 132.209Z'
          fill='#FFBE9D'
        />
        <path
          d='M141.874 136.269C141.874 136.269 141.809 136.319 141.694 136.369C141.531 136.43 141.351 136.43 141.189 136.369C140.946 136.204 140.751 135.978 140.624 135.714C140.498 135.45 140.443 135.157 140.467 134.866C140.458 134.538 140.525 134.212 140.662 133.913C140.7 133.793 140.766 133.684 140.855 133.594C140.944 133.504 141.053 133.437 141.174 133.398C141.253 133.379 141.335 133.388 141.408 133.424C141.48 133.459 141.538 133.518 141.571 133.591C141.621 133.699 141.571 133.777 141.621 133.777C141.672 133.777 141.701 133.72 141.672 133.555C141.65 133.46 141.597 133.374 141.52 133.312C141.416 133.232 141.284 133.196 141.153 133.212C140.991 133.241 140.839 133.311 140.713 133.415C140.587 133.519 140.49 133.654 140.431 133.806C140.262 134.136 140.177 134.503 140.186 134.873C140.164 135.215 140.244 135.557 140.416 135.855C140.588 136.152 140.844 136.394 141.153 136.548C141.257 136.579 141.367 136.586 141.474 136.569C141.582 136.551 141.684 136.51 141.773 136.448C141.867 136.362 141.888 136.276 141.874 136.269Z'
          fill='#EB996E'
        />
        <path
          d='M125.462 173.709C125.462 173.709 123.925 219.74 124.019 224.151L172.426 223.592L168.444 199.17V183.267L173.876 190.105L185.419 176.773L171.863 162.532C169.334 159.912 166.224 157.914 162.781 156.697C160.953 156.049 159.035 155.685 157.096 155.615C152.622 157.022 147.819 157.022 143.346 155.615C142.79 155.687 142.018 155.759 141.037 155.873H140.864C136.776 156.384 132.999 158.306 130.195 161.302C127.391 164.298 125.738 168.178 125.527 172.262L125.462 173.709Z'
          fill='#407BFF'
        />
        <path
          d='M152.623 181.592C151.837 181.071 151.092 180.492 150.394 179.859L150.307 179.788L150.387 179.702L150.43 179.652C151.056 178.922 151.742 178.244 152.479 177.625C151.904 178.387 151.272 179.105 150.589 179.773L150.553 179.816V179.652C151.296 180.24 151.988 180.889 152.623 181.592Z'
          fill='white'
        />
        <path
          d='M156.548 177.568C157.316 178.108 158.04 178.709 158.712 179.365L158.798 179.437L158.719 179.523L158.676 179.566C158.052 180.297 157.366 180.975 156.627 181.592C157.2 180.83 157.829 180.112 158.51 179.444L158.553 179.401V179.558C157.836 178.945 157.165 178.28 156.548 177.568Z'
          fill='white'
        />
        <path
          d='M153.2 182.408C153.534 181.426 153.939 180.469 154.412 179.544C154.829 178.591 155.311 177.668 155.855 176.78C155.524 177.763 155.121 178.72 154.65 179.644C154.234 180.599 153.75 181.523 153.2 182.408Z'
          fill='white'
        />
        <path
          d='M161.987 184.17C162.03 184.16 162.069 184.14 162.102 184.112C162.182 184.062 162.153 183.933 162.153 183.761V182.272C162.153 180.94 162.153 179.079 162.102 176.802C162.102 176.222 162.102 175.62 162.102 174.99C162.102 174.675 162.102 174.353 162.102 174.024V173.78C162.107 173.764 162.105 173.747 162.097 173.732C162.089 173.718 162.075 173.707 162.059 173.702H157.226H147.212C147.212 173.702 147.169 173.702 147.169 173.773V178.585C147.169 180.246 147.169 181.85 147.169 183.375V183.948C147.167 183.955 147.166 183.962 147.167 183.969C147.169 183.977 147.171 183.984 147.175 183.99C147.18 183.996 147.185 184.001 147.191 184.005C147.198 184.009 147.205 184.011 147.212 184.012H148.583H150.704H154.513L160.018 184.062H161.518H161.908C161.994 184.062 162.038 184.062 162.038 184.062H161.908H161.518H160.018L154.513 184.112H150.704H147.176C147.097 184.102 147.024 184.062 146.972 184.001C146.92 183.94 146.892 183.863 146.895 183.783V183.21C146.895 181.685 146.895 180.081 146.895 178.42V173.609C146.892 173.562 146.899 173.515 146.914 173.471C146.93 173.427 146.954 173.386 146.986 173.352C147.017 173.317 147.055 173.289 147.098 173.269C147.141 173.249 147.187 173.238 147.234 173.236H157.247H161.612C161.794 173.223 161.978 173.223 162.16 173.236C162.223 173.259 162.278 173.301 162.315 173.356C162.353 173.412 162.372 173.477 162.369 173.544V173.788C162.369 174.117 162.369 174.439 162.369 174.754C162.369 175.384 162.369 175.986 162.369 176.566C162.369 178.842 162.369 180.704 162.319 182.036V183.525C162.319 183.683 162.319 183.84 162.232 183.897C162.269 183.93 162.291 183.975 162.293 184.023C162.296 184.072 162.279 184.119 162.247 184.155C162.214 184.191 162.169 184.213 162.12 184.216C162.071 184.218 162.023 184.202 161.987 184.17Z'
          fill='white'
        />
        <path
          d='M162.182 175.205C162.182 175.262 158.777 175.313 154.578 175.313C150.379 175.313 146.982 175.262 146.982 175.205C146.982 175.148 150.379 175.091 154.578 175.091C158.777 175.091 162.182 175.141 162.182 175.205Z'
          fill='white'
        />
        <path
          d='M148.02 174.647C148.112 174.647 148.186 174.573 148.186 174.482C148.186 174.391 148.112 174.317 148.02 174.317C147.929 174.317 147.854 174.391 147.854 174.482C147.854 174.573 147.929 174.647 148.02 174.647Z'
          fill='white'
        />
        <path
          d='M148.835 174.482C148.837 174.515 148.828 174.548 148.81 174.576C148.793 174.604 148.767 174.626 148.736 174.64C148.706 174.653 148.672 174.657 148.639 174.651C148.606 174.645 148.576 174.629 148.553 174.605C148.529 174.582 148.513 174.552 148.507 174.519C148.501 174.487 148.505 174.453 148.518 174.423C148.532 174.392 148.554 174.367 148.582 174.349C148.611 174.332 148.643 174.323 148.677 174.324C148.698 174.324 148.718 174.329 148.737 174.336C148.757 174.344 148.774 174.356 148.789 174.371C148.804 174.385 148.815 174.403 148.823 174.422C148.831 174.441 148.835 174.461 148.835 174.482Z'
          fill='white'
        />
        <path
          d='M149.491 174.482C149.497 174.506 149.496 174.531 149.49 174.555C149.485 174.579 149.474 174.601 149.458 174.62C149.443 174.639 149.423 174.654 149.401 174.665C149.378 174.675 149.354 174.681 149.329 174.681C149.305 174.681 149.28 174.675 149.258 174.665C149.236 174.654 149.216 174.639 149.201 174.62C149.185 174.601 149.174 174.579 149.168 174.555C149.163 174.531 149.162 174.506 149.167 174.482C149.167 174.44 149.184 174.4 149.214 174.371C149.244 174.341 149.284 174.325 149.326 174.325C149.347 174.324 149.369 174.327 149.389 174.334C149.409 174.342 149.427 174.353 149.443 174.368C149.458 174.383 149.47 174.4 149.479 174.42C149.487 174.44 149.491 174.461 149.491 174.482Z'
          fill='white'
        />
        <path
          d='M168.335 183.468C168.145 181.361 168.083 179.245 168.148 177.131C168.083 175.017 168.146 172.901 168.335 170.795C168.52 172.901 168.583 175.017 168.522 177.131C168.583 179.245 168.521 181.361 168.335 183.468Z'
          fill='#263238'
        />
        <g opacity='0.2'>
          <path
            d='M168.415 180.611C170.62 183.261 173.032 185.734 175.629 188.007L173.862 190.105L168.429 183.296L168.415 180.611Z'
            fill='black'
          />
        </g>
        <path
          d='M141.037 155.859C141.037 155.859 124.726 155.981 122.706 173.086L121.689 185.788L136.478 185.501L141.037 155.859Z'
          fill='#407BFF'
        />
        <path
          opacity='0.2'
          d='M137.646 170.63L139.991 191.036L134.703 194.208L135.223 185.53L136.658 185.501L137.646 170.63Z'
          fill='black'
        />
        <path
          d='M121.689 185.788C121.783 185.765 121.88 185.753 121.977 185.752L122.8 185.709L125.887 185.601L136.651 185.322L136.478 185.487C136.636 183.045 136.81 180.375 136.99 177.611C137.192 174.475 137.423 171.482 137.574 168.775C137.65 167.5 137.561 166.219 137.307 164.966C137.078 163.883 136.616 162.862 135.951 161.974C135.406 161.24 134.671 160.669 133.823 160.32C133.183 160.051 132.49 159.931 131.796 159.969C131.202 160.019 130.623 160.177 130.086 160.434L130.18 160.363C130.276 160.297 130.377 160.239 130.483 160.191C130.888 159.988 131.329 159.864 131.781 159.826C132.502 159.762 133.228 159.865 133.902 160.126C134.797 160.469 135.579 161.052 136.16 161.809C136.864 162.719 137.357 163.773 137.603 164.895C137.878 166.172 137.982 167.479 137.913 168.783C137.784 171.518 137.56 174.511 137.358 177.64C137.17 180.425 136.99 183.096 136.824 185.516V185.68H136.658L125.895 185.823H121.977C121.88 185.828 121.782 185.816 121.689 185.788Z'
          fill='#263238'
        />
        <path
          d='M217.147 279.032L214.117 272.796L202.271 278.388L206.636 287.589L207.357 287.288C210.712 285.927 224.325 280.128 226.193 278.431C228.307 276.526 217.147 279.032 217.147 279.032Z'
          fill='#455A64'
        />
        <path
          d='M206.325 287.381C206.4 287.36 206.472 287.334 206.542 287.302L207.148 287.066C207.739 286.808 208.475 286.5 209.312 286.142C211.166 285.347 213.706 284.216 216.483 282.913C219.26 281.61 221.749 280.364 223.538 279.44L225.652 278.338L226.222 278.03C226.352 277.958 226.417 277.915 226.41 277.908L226.208 278.001L225.623 278.288L223.459 279.347C221.656 280.235 219.131 281.495 216.389 282.77C213.648 284.044 211.08 285.219 209.24 286.035L207.076 287.009L206.484 287.274L206.325 287.381Z'
          fill='#263238'
        />
        <path
          d='M222.449 280.171C222.379 279.771 222.353 279.366 222.37 278.961C222.438 278.563 222.552 278.174 222.709 277.801C222.403 278.107 222.226 278.516 222.211 278.946C222.135 279.37 222.22 279.806 222.449 280.171Z'
          fill='#263238'
        />
        <path
          d='M218.113 280.063C218.27 279.658 218.325 279.22 218.272 278.789C218.228 278.789 218.156 279.061 218.113 279.419C218.062 279.631 218.062 279.852 218.113 280.063Z'
          fill='#263238'
        />
        <path
          d='M216.916 280.013C216.952 280.013 217.081 279.813 217.197 279.541C217.312 279.269 217.377 279.018 217.333 279.004C217.29 278.989 217.168 279.197 217.053 279.476C216.937 279.755 216.873 279.992 216.916 280.013Z'
          fill='#263238'
        />
        <path
          d='M215.776 279.19C216.008 279.166 216.232 279.093 216.432 278.975C216.771 278.818 217.038 278.66 217.017 278.617C216.787 278.642 216.566 278.715 216.367 278.832C216.021 278.989 215.754 279.147 215.776 279.19Z'
          fill='#263238'
        />
        <path
          d='M215.192 278.524C215.461 278.564 215.736 278.519 215.978 278.395C216.396 278.238 216.699 278.001 216.642 277.966C216.394 278.042 216.153 278.137 215.92 278.252C215.669 278.32 215.425 278.411 215.192 278.524Z'
          fill='#263238'
        />
        <path
          d='M218.027 278.846C218.286 278.765 218.53 278.642 218.748 278.481C219.006 278.318 219.247 278.131 219.469 277.923C219.608 277.802 219.732 277.665 219.837 277.514C219.902 277.42 219.93 277.306 219.917 277.192C219.903 277.13 219.871 277.073 219.824 277.029C219.778 276.984 219.72 276.954 219.657 276.942C219.298 276.939 218.949 277.059 218.669 277.282C218.389 277.505 218.195 277.817 218.12 278.166C218.077 278.369 218.077 278.579 218.12 278.782C218.156 278.925 218.193 279.004 218.2 278.997C218.171 278.727 218.183 278.454 218.236 278.187C218.316 277.911 218.473 277.662 218.69 277.471C218.814 277.351 218.961 277.257 219.122 277.194C219.283 277.131 219.455 277.101 219.628 277.106C219.787 277.106 219.809 277.3 219.715 277.443C219.613 277.581 219.497 277.708 219.368 277.822C219.158 278.033 218.928 278.225 218.683 278.395C218.301 278.689 218.012 278.825 218.027 278.846Z'
          fill='#263238'
        />
        <path
          d='M218.243 278.789C218.256 278.587 218.197 278.387 218.077 278.223C217.958 278.037 217.794 277.882 217.601 277.772C217.485 277.688 217.348 277.636 217.205 277.621C217.062 277.606 216.918 277.629 216.786 277.686C216.717 277.739 216.671 277.816 216.659 277.902C216.647 277.988 216.669 278.075 216.721 278.144C216.795 278.258 216.893 278.354 217.01 278.424C217.19 278.542 217.383 278.638 217.587 278.71C217.772 278.785 217.971 278.82 218.171 278.81C218.171 278.81 217.955 278.746 217.63 278.603C217.441 278.523 217.262 278.422 217.096 278.302C216.916 278.195 216.707 277.937 216.88 277.815C216.988 277.776 217.104 277.764 217.219 277.779C217.333 277.794 217.442 277.836 217.536 277.901C217.711 277.998 217.863 278.129 217.984 278.288C218.082 278.448 218.169 278.616 218.243 278.789Z'
          fill='#263238'
        />
        <path
          d='M205.034 284.044C205.034 284.044 205.359 283.851 205.929 283.665C206.663 283.418 207.451 283.376 208.208 283.543C208.963 283.726 209.654 284.11 210.207 284.653C210.625 285.075 210.805 285.405 210.834 285.369C210.811 285.284 210.772 285.204 210.719 285.133C210.597 284.926 210.452 284.734 210.286 284.56C209.742 283.968 209.031 283.554 208.245 283.371C207.46 283.188 206.637 283.246 205.885 283.536C205.662 283.624 205.447 283.732 205.243 283.858C205.168 283.914 205.098 283.976 205.034 284.044Z'
          fill='#263238'
        />
        <path
          d='M203.923 278.574C204.224 279.415 204.576 280.238 204.976 281.037C205.329 281.859 205.729 282.659 206.174 283.436C205.874 282.594 205.522 281.771 205.12 280.973C204.769 280.15 204.369 279.35 203.923 278.574Z'
          fill='#263238'
        />
        <path
          d='M211.823 283.6C212.451 283.462 213.059 283.245 213.633 282.956C214.23 282.712 214.791 282.39 215.3 281.997C215.3 281.961 214.528 282.369 213.569 282.813C212.609 283.257 211.808 283.536 211.823 283.6Z'
          fill='#263238'
        />
        <path
          d='M209.5 284.689C209.5 284.732 209.644 284.825 209.803 284.961C209.961 285.097 210.084 285.24 210.127 285.211C210.171 285.183 210.127 284.997 209.911 284.839C209.695 284.682 209.514 284.653 209.5 284.689Z'
          fill='#263238'
        />
        <path
          d='M208.107 284.023C208.107 284.066 208.23 284.152 208.41 284.216C208.591 284.281 208.749 284.302 208.764 284.259C208.778 284.216 208.648 284.13 208.461 284.066C208.273 284.001 208.122 283.98 208.107 284.023Z'
          fill='#263238'
        />
        <path
          d='M206.621 284.116C206.621 284.152 206.801 284.08 207.011 284.059C207.22 284.037 207.379 284.059 207.4 284.059C207.422 284.059 207.242 283.901 206.989 283.93C206.737 283.958 206.592 284.087 206.621 284.116Z'
          fill='#263238'
        />
        <path
          d='M205.604 284.309C205.604 284.352 205.705 284.381 205.82 284.367C205.935 284.352 206.029 284.316 206.022 284.273C206.014 284.231 205.921 284.202 205.806 284.209C205.69 284.216 205.604 284.252 205.604 284.309Z'
          fill='#263238'
        />
        <path
          d='M179.337 321.849L179.243 314.904L166.128 315.004V325.171L166.943 325.214C170.55 325.386 185.368 325.837 187.785 325.093C190.505 324.262 179.337 321.849 179.337 321.849Z'
          fill='#455A64'
        />
        <path
          d='M165.977 324.871C166.051 324.881 166.126 324.881 166.2 324.871L166.849 324.906L169.23 324.992C171.243 325.05 174.028 325.093 177.101 325.078C180.174 325.064 182.966 324.978 184.979 324.899L187.359 324.785L188.009 324.742H188.232C188.158 324.734 188.083 324.734 188.009 324.742H187.359L184.971 324.806C182.959 324.864 180.174 324.921 177.101 324.942C174.028 324.964 171.25 324.942 169.237 324.906H166.849H165.977V324.871Z'
          fill='#263238'
        />
        <path
          d='M183.644 325.107C183.75 324.715 183.897 324.336 184.084 323.976C184.316 323.642 184.587 323.336 184.892 323.066C184.483 323.215 184.145 323.511 183.947 323.897C183.704 324.252 183.597 324.681 183.644 325.107Z'
          fill='#263238'
        />
        <path
          d='M179.763 323.188C179.799 323.188 179.986 322.988 180.174 322.687C180.362 322.386 180.484 322.121 180.448 322.1C180.412 322.078 180.224 322.3 180.037 322.601C179.849 322.902 179.727 323.167 179.763 323.188Z'
          fill='#263238'
        />
        <path
          d='M178.695 322.637C178.695 322.637 178.933 322.529 179.157 322.329C179.38 322.128 179.539 321.935 179.51 321.899C179.481 321.863 179.272 322.007 179.049 322.207C178.825 322.408 178.666 322.601 178.695 322.637Z'
          fill='#263238'
        />
        <path
          d='M178.017 321.412C178.237 321.49 178.471 321.517 178.702 321.491C178.945 321.526 179.193 321.501 179.424 321.42C179.193 321.338 178.946 321.308 178.702 321.334C178.471 321.309 178.237 321.336 178.017 321.412Z'
          fill='#263238'
        />
        <path
          d='M177.779 320.546C178.005 320.694 178.272 320.769 178.544 320.761C178.811 320.81 179.086 320.773 179.33 320.653C179.075 320.613 178.816 320.596 178.558 320.603C178.301 320.56 178.04 320.541 177.779 320.546Z'
          fill='#263238'
        />
        <path
          d='M180.203 322.05C180.48 322.095 180.763 322.095 181.04 322.05C181.346 322.013 181.648 321.946 181.941 321.849C182.119 321.795 182.291 321.723 182.454 321.634C182.55 321.575 182.622 321.484 182.656 321.377C182.669 321.314 182.664 321.249 182.641 321.189C182.618 321.129 182.578 321.078 182.526 321.04C182.318 320.945 182.092 320.896 181.863 320.897C181.634 320.899 181.408 320.95 181.202 321.048C180.995 321.146 180.813 321.287 180.667 321.463C180.522 321.639 180.418 321.844 180.362 322.064C180.347 322.137 180.347 322.213 180.362 322.286C180.448 322.028 180.577 321.786 180.744 321.57C180.935 321.349 181.185 321.188 181.465 321.105C181.629 321.047 181.803 321.022 181.976 321.032C182.15 321.041 182.319 321.086 182.475 321.162C182.605 321.262 182.555 321.42 182.41 321.505C182.259 321.589 182.099 321.659 181.934 321.713C181.651 321.808 181.362 321.885 181.069 321.942C180.52 322.021 180.203 322.021 180.203 322.05Z'
          fill='#263238'
        />
        <path
          d='M180.419 322.085C180.526 321.912 180.56 321.704 180.513 321.505C180.485 321.286 180.403 321.078 180.275 320.897C180.202 320.77 180.097 320.665 179.971 320.591C179.844 320.516 179.7 320.476 179.554 320.474C179.468 320.493 179.394 320.544 179.346 320.616C179.297 320.688 179.279 320.776 179.294 320.861C179.314 320.994 179.363 321.121 179.438 321.233C179.553 321.417 179.689 321.588 179.842 321.742C180.109 321.992 180.318 322.093 180.325 322.071C180.333 322.05 180.16 321.928 179.921 321.663C179.785 321.51 179.666 321.342 179.568 321.162C179.453 320.99 179.366 320.668 179.568 320.632C179.77 320.596 179.994 320.818 180.123 320.983C180.243 321.144 180.324 321.33 180.362 321.527C180.4 321.711 180.419 321.898 180.419 322.085Z'
          fill='#263238'
        />
        <path
          d='M166.215 321.305C166.215 321.341 166.59 321.269 167.181 321.305C167.954 321.391 168.687 321.686 169.302 322.157C169.912 322.638 170.374 323.277 170.637 324.004C170.846 324.556 170.868 324.935 170.897 324.928C170.911 324.84 170.911 324.751 170.897 324.663C170.874 324.419 170.823 324.179 170.745 323.947C170.506 323.183 170.038 322.509 169.405 322.014C168.771 321.519 168.001 321.227 167.196 321.176C166.956 321.148 166.714 321.148 166.474 321.176C166.383 321.209 166.296 321.252 166.215 321.305Z'
          fill='#263238'
        />
        <path
          d='M167.535 315.885C167.45 316.772 167.419 317.664 167.441 318.556C167.411 319.449 167.432 320.343 167.506 321.233C167.59 320.343 167.621 319.449 167.6 318.556C167.63 317.665 167.608 316.773 167.535 315.885Z'
          fill='#263238'
        />
        <path
          d='M172.556 323.754C173.186 323.89 173.83 323.948 174.475 323.926C175.119 323.956 175.764 323.901 176.394 323.761C176.394 323.718 175.528 323.761 174.475 323.761C173.421 323.761 172.563 323.704 172.556 323.754Z'
          fill='#263238'
        />
        <path
          d='M169.988 323.768C169.952 323.768 170.06 323.947 170.146 324.14C170.232 324.334 170.283 324.505 170.326 324.505C170.369 324.505 170.398 324.298 170.29 324.076C170.182 323.854 170.016 323.732 169.988 323.768Z'
          fill='#263238'
        />
        <path
          d='M169.006 322.572C169.006 322.608 169.064 322.744 169.201 322.88C169.338 323.016 169.475 323.095 169.504 323.066C169.533 323.038 169.447 322.902 169.309 322.766C169.172 322.63 169.043 322.544 169.006 322.572Z'
          fill='#263238'
        />
        <path
          d='M167.621 322.035C167.621 322.085 167.802 322.078 167.996 322.143C168.191 322.207 168.328 322.315 168.364 322.286C168.4 322.257 168.285 322.071 168.047 321.992C167.809 321.914 167.607 321.999 167.621 322.035Z'
          fill='#263238'
        />
        <path
          d='M166.647 321.785C166.647 321.828 166.705 321.892 166.813 321.935C166.921 321.978 167.022 321.978 167.036 321.935C167.051 321.892 166.979 321.828 166.871 321.785C166.763 321.742 166.647 321.749 166.647 321.785Z'
          fill='#263238'
        />
        <path
          d='M125.873 224.624C125.873 224.624 185.981 207.232 191.406 209.788C196.831 212.344 219.311 274.729 219.311 274.729L202.062 283.049L180.138 229.836C180.138 229.836 137.437 246.304 130.577 244.922C123.716 243.54 125.873 224.624 125.873 224.624Z'
          fill='#263238'
        />
        <path
          d='M151.245 222.862C152.104 223.141 185.051 233.853 187.316 238.084C189.581 242.316 186.241 318.498 186.241 318.498L164.844 318.462L166.287 245.896L130.216 244.994L151.245 222.862Z'
          fill='#263238'
        />
        <path
          d='M130.057 244.987C130.237 244.985 130.416 244.963 130.591 244.922L132.099 244.657C133.404 244.414 135.287 244.049 137.61 243.555C142.256 242.574 148.655 241.092 155.646 239.101C159.13 238.091 162.42 237.046 165.392 236.029C168.364 235.013 171.005 233.996 173.191 233.065C174.294 232.621 175.276 232.184 176.134 231.798C176.993 231.411 177.736 231.082 178.298 230.774L179.662 230.058C179.826 229.989 179.981 229.9 180.123 229.793C179.951 229.84 179.785 229.905 179.626 229.986L178.226 230.595C177.627 230.874 176.877 231.196 176.011 231.554C175.146 231.912 174.157 232.342 173.054 232.764C170.889 233.659 168.198 234.612 165.233 235.628C162.268 236.645 158.979 237.662 155.494 238.664C148.54 240.648 142.163 242.173 137.531 243.225L132.048 244.464L130.577 244.872C130.4 244.891 130.226 244.93 130.057 244.987Z'
          fill='#455A64'
        />
        <path
          d='M187.843 254.896C187.934 254.552 187.989 254.199 188.009 253.843C188.088 253.077 188.189 252.111 188.312 250.979C188.434 249.848 188.557 248.287 188.708 246.683C188.95 244.923 188.95 243.138 188.708 241.378C188.341 239.596 187.393 237.983 186.01 236.788C184.911 235.823 183.668 235.033 182.324 234.447C181.191 233.939 180.239 233.609 179.582 233.387C179.25 233.251 178.905 233.15 178.551 233.087C178.551 233.165 179.994 233.659 182.194 234.719C183.49 235.323 184.686 236.117 185.743 237.075C187.049 238.222 187.943 239.76 188.29 241.457C188.517 243.182 188.517 244.929 188.29 246.655C188.174 248.252 188.073 249.698 187.987 250.951C187.9 252.204 187.857 253.099 187.814 253.851C187.783 254.199 187.792 254.55 187.843 254.896Z'
          fill='#455A64'
        />
        <path
          d='M162.168 190.7L143.721 207.339C139.291 211.327 131.269 212.351 126.299 209.036C123.312 207.067 122.692 203.451 122.115 199.943C121.004 193.499 122.439 179.294 122.396 179.673L135.468 179.981L134.855 193.922L157.074 181.449L157.471 180.969C157.716 180.353 157.991 179.616 158.265 178.749C158.509 177.989 158.619 177.192 158.589 176.394C158.579 175.498 158.475 174.605 158.279 173.73C158.279 173.673 158.236 173.594 158.207 173.544C158.154 173.429 158.125 173.305 158.12 173.179C158.089 173.014 158.105 172.843 158.166 172.686C158.228 172.529 158.333 172.392 158.47 172.292C158.606 172.192 158.768 172.132 158.937 172.119C159.107 172.106 159.276 172.141 159.426 172.22C160.075 172.621 160.948 174.618 160.97 177.232C160.97 177.275 160.97 177.31 160.97 177.353C161.057 179.988 163.517 176.695 164.433 175.513C165.349 174.332 168.357 170.809 168.942 170.809C169.793 170.809 169.959 171.74 169.014 172.9C168.069 174.06 165.479 177.74 166.128 178.205C166.777 178.671 171.344 172.578 171.344 172.578C171.344 172.578 172.123 171.146 172.873 171.625C174.244 172.556 168.891 178.656 168.372 179.222C168.004 179.616 168.682 180.153 169.05 179.709C169.418 179.265 173.487 173.523 174.886 174.453C175.961 175.169 170.558 179.516 170.341 181.184C170.125 182.852 174.121 177.461 175.124 178.184C175.492 178.449 175.73 178.707 173.011 181.463C170.779 183.526 168.436 185.467 165.991 187.277L162.168 190.7Z'
          fill='#FFBE9D'
        />
        <path
          d='M149.059 247.808C154.657 247.442 152.024 239.466 151.887 230.588C151.801 225.024 148.078 219.561 144.009 215.731C141.946 213.916 139.512 212.566 136.875 211.771C129.886 209.543 122.45 209.07 115.232 210.397C115.232 210.397 104.642 250.715 149.059 247.808Z'
          fill='#455A64'
        />
        <path
          d='M359.937 210.125H84.1172C83.8056 210.125 83.4969 210.185 83.209 210.304C82.921 210.422 82.6593 210.596 82.4389 210.814C82.2186 211.033 82.0437 211.293 81.9244 211.579C81.8052 211.864 81.7438 212.171 81.7438 212.48C81.7438 212.79 81.8052 213.096 81.9244 213.382C82.0437 213.667 82.2186 213.927 82.4389 214.146C82.6593 214.365 82.921 214.538 83.209 214.656C83.4969 214.775 83.8056 214.836 84.1172 214.836H97.2398V325.157H102.91V214.836H340.422V325.157H346.093V214.836H359.937C360.248 214.836 360.557 214.775 360.845 214.656C361.133 214.538 361.395 214.365 361.615 214.146C361.835 213.927 362.01 213.667 362.129 213.382C362.249 213.096 362.31 212.79 362.31 212.48C362.31 212.171 362.249 211.864 362.129 211.579C362.01 211.293 361.835 211.033 361.615 210.814C361.395 210.596 361.133 210.422 360.845 210.304C360.557 210.185 360.248 210.125 359.937 210.125Z'
          fill='#E0E0E0'
        />
        <path
          d='M261.29 253.307C255.685 253.135 258.325 244.972 258.462 236.123C258.549 230.552 262.278 225.096 266.34 221.258C268.407 219.447 270.843 218.099 273.482 217.306C280.393 214.965 287.91 214.714 295.124 215.924C295.117 215.896 296.798 254.423 261.29 253.307Z'
          fill='#407BFF'
        />
        <path
          opacity='0.3'
          d='M261.29 253.307C255.685 253.135 258.325 244.972 258.462 236.123C258.549 230.552 262.278 225.096 266.34 221.258C268.407 219.447 270.843 218.099 273.482 217.306C280.393 214.965 287.91 214.714 295.124 215.924C295.117 215.896 296.798 254.423 261.29 253.307Z'
          fill='black'
        />
        <path
          d='M305.787 325.551H308.839V252.34H305.787V325.551Z'
          fill='#263238'
        />
        <path
          d='M321.384 325.501H293.011C293.011 325.501 298.118 321.062 307.316 321.205C316.118 321.348 321.384 325.501 321.384 325.501Z'
          fill='#263238'
        />
        <path
          d='M312.309 177.983C315.656 177.683 319.068 177.418 322.365 178.076C325.662 178.735 328.858 180.439 330.654 183.289C332.451 186.139 332.472 189.733 332.472 193.062C332.472 207.234 332.472 221.399 332.472 235.557H294.237L312.309 177.983Z'
          fill='#407BFF'
        />
        <path
          d='M332.48 233.595C332.638 241.972 331.902 248.774 326.059 252.068C321.687 254.531 316.399 254.588 311.371 254.581H289.591C287.384 254.581 285.017 254.531 283.214 253.263C281.23 251.874 280.4 249.232 280.747 246.819C281.198 244.434 282.35 242.235 284.058 240.497C290.349 233.566 300.304 231.511 309.546 229.829'
          fill='#407BFF'
        />
        <path
          d='M257.964 321.362L258.159 313.787L272.458 314.066L272.285 325.15L271.397 325.193C267.451 325.329 251.306 325.622 248.687 324.778C245.758 323.84 257.964 321.362 257.964 321.362Z'
          fill='#407BFF'
        />
        <g opacity='0.4'>
          <path
            d='M251.998 322.78C251.998 322.78 247.713 323.918 248.232 324.62C248.752 325.322 265.07 325.637 272.284 325.15V324.835L253.217 324.634C253.217 324.634 252.828 322.83 251.998 322.78Z'
            fill='white'
          />
        </g>
        <path
          d='M272.494 324.828H272.248H271.527L268.923 324.892C266.758 324.928 263.692 324.942 260.345 324.892C256.998 324.842 253.961 324.706 251.767 324.591L249.17 324.434L248.449 324.384C248.366 324.382 248.284 324.37 248.204 324.348H248.449H249.17L251.767 324.448C253.932 324.534 256.998 324.634 260.345 324.699C263.692 324.763 266.73 324.77 268.923 324.763H272.479L272.494 324.828Z'
          fill='#263238'
        />
        <path
          d='M253.218 324.856C253.116 324.424 252.961 324.005 252.756 323.61C252.506 323.245 252.216 322.909 251.89 322.608C252.316 322.804 252.671 323.124 252.909 323.526C253.146 323.928 253.254 324.392 253.218 324.856Z'
          fill='#263238'
        />
        <path
          d='M257.481 322.809C257.445 322.809 257.243 322.587 257.041 322.257C256.839 321.928 256.709 321.642 256.752 321.613C256.796 321.584 256.991 321.835 257.193 322.164C257.395 322.494 257.546 322.787 257.481 322.809Z'
          fill='#263238'
        />
        <path
          d='M258.657 322.2C258.621 322.236 258.397 322.085 258.159 321.856C257.921 321.627 257.748 321.427 257.777 321.391C257.806 321.355 258.029 321.505 258.275 321.735C258.52 321.964 258.686 322.2 258.657 322.2Z'
          fill='#263238'
        />
        <path
          d='M259.415 320.897C259.183 320.977 258.936 321.004 258.693 320.976C258.449 320.996 258.204 320.967 257.972 320.89C258.203 320.809 258.449 320.78 258.693 320.804C258.937 320.788 259.183 320.819 259.415 320.897Z'
          fill='#263238'
        />
        <path
          d='M259.71 319.959C259.461 320.116 259.169 320.194 258.873 320.181C258.581 320.224 258.282 320.179 258.015 320.052C258.297 320.011 258.582 319.997 258.866 320.009C259.145 319.966 259.428 319.949 259.71 319.959Z'
          fill='#263238'
        />
        <path
          d='M257.026 321.563C256.721 321.618 256.408 321.618 256.103 321.563C255.772 321.512 255.446 321.433 255.129 321.326C254.936 321.268 254.749 321.189 254.574 321.09C254.468 321.023 254.389 320.922 254.35 320.804C254.337 320.735 254.345 320.664 254.372 320.599C254.399 320.534 254.444 320.479 254.501 320.439C254.855 320.273 255.255 320.236 255.633 320.333C256.011 320.429 256.343 320.654 256.572 320.968C256.71 321.151 256.809 321.361 256.861 321.584C256.88 321.664 256.88 321.748 256.861 321.828C256.768 321.546 256.631 321.28 256.457 321.04C256.252 320.797 255.982 320.618 255.677 320.525C255.319 320.397 254.925 320.415 254.581 320.575C254.437 320.682 254.487 320.854 254.653 320.947C254.814 321.043 254.986 321.12 255.165 321.176C255.47 321.289 255.784 321.378 256.103 321.441C256.673 321.527 257.026 321.534 257.026 321.563Z'
          fill='#263238'
        />
        <path
          d='M256.781 321.606C256.677 321.41 256.644 321.185 256.687 320.968C256.723 320.731 256.814 320.505 256.954 320.31C257.033 320.175 257.145 320.062 257.28 319.983C257.416 319.904 257.569 319.861 257.726 319.859C257.819 319.879 257.899 319.935 257.951 320.014C258.002 320.093 258.02 320.189 258 320.281C257.977 320.427 257.923 320.566 257.842 320.689C257.713 320.886 257.563 321.068 257.394 321.233C257.248 321.393 257.065 321.516 256.861 321.591C256.861 321.591 257.048 321.427 257.308 321.147C257.459 320.98 257.592 320.798 257.705 320.603C257.834 320.417 257.928 320.073 257.705 320.023C257.481 319.973 257.236 320.224 257.091 320.403C256.958 320.576 256.864 320.777 256.817 320.99C256.789 321.194 256.777 321.4 256.781 321.606Z'
          fill='#263238'
        />
        <path
          d='M272.277 320.94C272.277 320.976 271.866 320.897 271.224 320.94C270.382 321.011 269.578 321.318 268.905 321.825C268.232 322.332 267.718 323.018 267.422 323.804C267.191 324.412 267.162 324.821 267.126 324.813C267.111 324.719 267.111 324.622 267.126 324.527C267.153 324.268 267.212 324.013 267.3 323.768C267.569 322.937 268.088 322.208 268.786 321.677C269.485 321.152 270.327 320.846 271.202 320.797C271.463 320.764 271.728 320.764 271.989 320.797C272.092 320.829 272.19 320.877 272.277 320.94Z'
          fill='#263238'
        />
        <path
          d='M270.906 315.04C270.989 316.009 271.013 316.982 270.978 317.954C271 318.927 270.964 319.9 270.87 320.868C270.788 319.899 270.766 318.926 270.806 317.954C270.784 316.982 270.817 316.009 270.906 315.04Z'
          fill='#263238'
        />
        <path
          d='M265.33 323.525C264.642 323.664 263.94 323.72 263.238 323.689C262.537 323.707 261.837 323.637 261.153 323.482C261.153 323.432 262.091 323.482 263.245 323.482C264.399 323.482 265.33 323.474 265.33 323.525Z'
          fill='#263238'
        />
        <path
          d='M268.135 323.568C268.135 323.568 268.056 323.768 267.955 323.976C267.855 324.183 267.797 324.377 267.747 324.369C267.696 324.362 267.682 324.148 267.797 323.904C267.912 323.661 268.107 323.539 268.135 323.568Z'
          fill='#263238'
        />
        <path
          d='M269.218 322.286C269.218 322.322 269.153 322.465 269.002 322.615C268.85 322.766 268.706 322.852 268.67 322.816C268.634 322.78 268.728 322.637 268.879 322.486C269.031 322.336 269.182 322.25 269.218 322.286Z'
          fill='#263238'
        />
        <path
          d='M270.741 321.72C270.741 321.77 270.539 321.763 270.322 321.828C270.106 321.892 269.961 322.007 269.925 321.978C269.889 321.949 270.012 321.749 270.272 321.67C270.531 321.591 270.755 321.677 270.741 321.72Z'
          fill='#263238'
        />
        <path
          d='M271.822 321.484C271.822 321.534 271.75 321.606 271.635 321.649C271.519 321.692 271.404 321.692 271.39 321.649C271.375 321.606 271.455 321.534 271.57 321.491C271.685 321.448 271.8 321.412 271.822 321.484Z'
          fill='#263238'
        />
        <g opacity='0.3'>
          <path
            d='M272.263 320.56L257.986 320.052L258.044 318.348L272.386 318.72L272.263 320.56Z'
            fill='black'
          />
        </g>
        <path
          d='M223.639 287.932L226.525 280.908L239.813 286.149L235.73 296.474L234.886 296.195C231.135 294.949 215.92 289.601 213.763 287.896C211.325 285.992 223.639 287.932 223.639 287.932Z'
          fill='#407BFF'
        />
        <g opacity='0.4'>
          <path
            d='M217.536 287.18C217.536 287.18 213.121 286.751 213.359 287.589C213.597 288.426 228.769 294.412 235.723 296.474L235.817 296.166L218.056 289.343C218.056 289.343 218.294 287.517 217.536 287.18Z'
            fill='white'
          />
        </g>
        <path
          d='M236.004 296.245L235.766 296.173L235.095 295.959L232.635 295.107C230.565 294.391 227.722 293.331 224.606 292.106C221.489 290.882 218.69 289.708 216.67 288.834L214.297 287.782L213.655 287.481L213.431 287.374C213.513 287.39 213.593 287.417 213.669 287.452L214.318 287.717L216.721 288.72C218.741 289.565 221.547 290.717 224.656 291.942C227.766 293.166 230.608 294.233 232.664 294.992L235.103 295.894L235.759 296.145C235.844 296.169 235.927 296.202 236.004 296.245Z'
          fill='#263238'
        />
        <path
          d='M217.955 289.55C218 289.11 218 288.666 217.955 288.226C217.848 287.795 217.693 287.377 217.493 286.98C217.821 287.313 218.039 287.737 218.12 288.195C218.201 288.654 218.141 289.126 217.947 289.55H217.955Z'
          fill='#263238'
        />
        <path
          d='M222.665 289.121C222.614 289.121 222.521 288.827 222.449 288.448C222.378 288.216 222.36 287.971 222.398 287.732C222.449 287.732 222.542 288.018 222.614 288.398C222.685 288.632 222.703 288.879 222.665 289.121Z'
          fill='#263238'
        />
        <path
          d='M223.964 288.978C223.921 288.978 223.769 288.777 223.625 288.484C223.481 288.19 223.387 287.932 223.43 287.911C223.473 287.889 223.632 288.111 223.776 288.405C223.921 288.698 224.007 288.956 223.964 288.978Z'
          fill='#263238'
        />
        <path
          d='M225.147 288.004C224.896 287.994 224.65 287.93 224.426 287.818C224.036 287.667 223.74 287.517 223.755 287.467C224.006 287.477 224.252 287.541 224.476 287.653C224.866 287.832 225.161 287.954 225.147 288.004Z'
          fill='#263238'
        />
        <path
          d='M225.731 287.216C225.441 287.246 225.147 287.217 224.868 287.13C224.589 287.044 224.331 286.903 224.108 286.715C224.385 286.775 224.656 286.861 224.916 286.973C225.195 287.029 225.468 287.111 225.731 287.216Z'
          fill='#263238'
        />
        <path
          d='M222.673 287.789C222.37 287.726 222.082 287.61 221.821 287.445C221.527 287.289 221.249 287.105 220.992 286.894C220.832 286.77 220.686 286.628 220.559 286.472C220.518 286.425 220.487 286.37 220.469 286.311C220.45 286.252 220.444 286.189 220.451 286.128C220.461 286.059 220.492 285.995 220.539 285.943C220.587 285.892 220.649 285.857 220.718 285.841C221.106 285.812 221.494 285.917 221.813 286.139C222.133 286.361 222.365 286.686 222.471 287.059C222.534 287.277 222.551 287.506 222.521 287.732C222.519 287.814 222.497 287.896 222.456 287.968C222.471 287.673 222.436 287.378 222.355 287.094C222.252 286.795 222.064 286.531 221.814 286.336C221.523 286.093 221.147 285.975 220.768 286.006C220.588 286.049 220.581 286.228 220.696 286.378C220.815 286.522 220.948 286.654 221.093 286.772C221.344 286.981 221.609 287.172 221.886 287.345C222.355 287.631 222.687 287.768 222.673 287.789Z'
          fill='#263238'
        />
        <path
          d='M222.435 287.746C222.4 287.526 222.449 287.302 222.572 287.116C222.686 286.904 222.852 286.725 223.055 286.593C223.176 286.495 223.32 286.428 223.474 286.401C223.628 286.373 223.787 286.385 223.935 286.436C224.014 286.49 224.07 286.572 224.09 286.665C224.11 286.758 224.093 286.856 224.043 286.937C223.97 287.063 223.869 287.171 223.747 287.252C223.562 287.396 223.358 287.517 223.141 287.61C222.947 287.709 222.732 287.761 222.514 287.76C222.514 287.76 222.745 287.674 223.084 287.495C223.282 287.389 223.469 287.262 223.639 287.116C223.827 286.987 224.036 286.694 223.841 286.572C223.719 286.537 223.59 286.531 223.464 286.555C223.339 286.578 223.221 286.631 223.12 286.708C222.933 286.824 222.775 286.98 222.658 287.166C222.566 287.352 222.491 287.546 222.435 287.746Z'
          fill='#263238'
        />
        <path
          d='M237.173 292.529C236.848 292.393 236.515 292.273 236.178 292.171C235.36 291.957 234.499 291.97 233.689 292.207C232.873 292.458 232.142 292.926 231.575 293.56C231.149 294.047 230.976 294.419 230.947 294.405C230.967 294.311 231.003 294.221 231.055 294.14C231.173 293.906 231.316 293.685 231.481 293.481C232.041 292.799 232.795 292.3 233.645 292.049C234.484 291.79 235.382 291.79 236.221 292.049C236.472 292.125 236.714 292.229 236.942 292.357C237.101 292.457 237.18 292.515 237.173 292.529Z'
          fill='#263238'
        />
        <path
          d='M237.996 286.493C237.726 287.429 237.404 288.35 237.029 289.25C236.702 290.167 236.324 291.066 235.896 291.942C236.165 291.005 236.488 290.085 236.863 289.185C237.186 288.266 237.565 287.367 237.996 286.493Z'
          fill='#263238'
        />
        <path
          d='M229.757 292.522C229.064 292.417 228.388 292.224 227.744 291.949C227.078 291.726 226.444 291.416 225.861 291.025C225.861 290.982 226.734 291.376 227.802 291.784C228.87 292.192 229.771 292.472 229.757 292.522Z'
          fill='#263238'
        />
        <path
          d='M232.368 293.56C232.368 293.603 232.224 293.71 232.051 293.875C231.878 294.04 231.77 294.19 231.726 294.169C231.683 294.147 231.726 293.939 231.936 293.753C232.145 293.567 232.347 293.503 232.368 293.56Z'
          fill='#263238'
        />
        <path
          d='M233.833 292.715C233.833 292.758 233.71 292.865 233.516 292.951C233.321 293.037 233.155 293.066 233.133 293.023C233.112 292.98 233.256 292.873 233.451 292.794C233.645 292.715 233.811 292.672 233.833 292.715Z'
          fill='#263238'
        />
        <path
          d='M235.456 292.715C235.456 292.758 235.254 292.715 235.031 292.679C234.807 292.643 234.627 292.715 234.605 292.679C234.583 292.643 234.771 292.5 235.045 292.515C235.319 292.529 235.492 292.679 235.456 292.715Z'
          fill='#263238'
        />
        <path
          d='M236.567 292.844C236.567 292.887 236.459 292.93 236.336 292.923C236.214 292.916 236.105 292.887 236.105 292.837C236.105 292.787 236.206 292.751 236.336 292.751C236.466 292.751 236.567 292.801 236.567 292.844Z'
          fill='#263238'
        />
        <g opacity='0.3'>
          <path
            d='M237.296 292.164L224.108 286.715L224.765 285.133L238.068 290.481L237.296 292.164Z'
            fill='black'
          />
        </g>
        <path
          d='M315.981 221.86C315.981 221.86 319.884 246.648 309.661 249.569C298.789 252.684 275.495 258.512 275.495 258.512L273.742 319.236L256.68 318.62L255.05 250.22C255.106 249.035 255.445 247.88 256.04 246.85C256.634 245.821 257.467 244.946 258.469 244.299L283.113 221.845L315.981 221.86Z'
          fill='#263238'
        />
        <path
          d='M223.17 285.125L239.135 291.226L260.778 246.633C260.778 246.633 297.426 250.929 307.959 249.497C311.717 248.989 316.161 247.041 318.397 244.213C322.481 239.044 322.661 231.977 321.896 225.454L321.593 224.022L294.583 222.59L253.217 228.748C251.403 229.061 249.695 229.813 248.243 230.937C246.791 232.061 245.641 233.522 244.892 235.192L223.17 285.125Z'
          fill='#263238'
        />
        <path
          d='M258.664 245.065C257.837 245.492 257.127 246.111 256.594 246.87C256.037 247.701 255.666 248.641 255.504 249.626C255.318 250.846 255.255 252.081 255.317 253.314C255.382 255.963 255.418 258.369 255.425 260.108C255.425 260.975 255.425 261.676 255.425 262.163C255.434 262.418 255.415 262.672 255.367 262.922C255.306 262.689 255.274 262.448 255.273 262.206C255.273 261.719 255.201 261.018 255.165 260.144C255.093 258.412 255.014 256.013 254.949 253.357C254.885 252.099 254.96 250.839 255.172 249.598C255.355 248.565 255.769 247.586 256.384 246.734C256.797 246.163 257.331 245.691 257.95 245.352C258.163 245.204 258.407 245.106 258.664 245.065Z'
          fill='#455A64'
        />
        <path
          d='M309.632 249.569C309.46 249.591 309.285 249.591 309.113 249.569L307.619 249.519C306.328 249.469 304.452 249.376 302.144 249.247C297.52 248.989 291.135 248.567 284.108 247.965C277.082 247.364 270.697 246.669 266.073 246.104C263.779 245.817 261.918 245.574 260.634 245.388L259.191 245.166C259.018 245.142 258.847 245.109 258.678 245.065C258.851 245.045 259.025 245.045 259.198 245.065L260.684 245.216L266.131 245.817C270.733 246.325 277.096 246.97 284.166 247.571C291.236 248.173 297.584 248.638 302.202 248.96L307.67 249.354L309.163 249.476C309.322 249.495 309.478 249.526 309.632 249.569Z'
          fill='#455A64'
        />
        <path
          d='M309.091 152.959C309.769 154.126 311.458 154.319 312.698 153.79C313.888 153.149 314.949 152.297 315.829 151.276C316.796 150.339 317.994 149.451 319.307 149.379C320.324 149.329 321.42 149.751 322.337 149.3C323.253 148.849 323.577 147.639 324.032 146.673C324.525 145.682 325.22 144.804 326.073 144.095C326.942 143.363 327.536 142.362 327.758 141.253C327.981 140.144 327.819 138.994 327.3 137.988C327.257 137.923 327.221 137.851 327.177 137.78C326.444 136.398 325.22 135.334 323.743 134.794C322.517 134.408 321.067 134.479 320.136 133.62C319.04 132.625 319.133 130.849 318.246 129.668C317.483 128.798 316.421 128.241 315.267 128.107C314.122 127.971 312.991 127.732 311.89 127.391C311.343 127.217 310.853 126.899 310.472 126.472C310.091 126.045 309.834 125.524 309.726 124.963'
          fill='#263238'
        />
        <path
          d='M302.923 128.558C302.501 127.224 301.711 126.034 300.643 125.122C299.575 124.21 298.272 123.612 296.88 123.397C295.487 123.182 294.062 123.357 292.766 123.903C291.469 124.45 290.352 125.345 289.541 126.489C288.819 127.462 288.358 128.637 287.376 129.295C286.511 129.854 285.429 129.925 284.491 130.291C283.468 130.716 282.619 131.471 282.08 132.433C281.541 133.395 281.343 134.509 281.519 135.596C281.764 136.871 282.529 138.109 282.24 139.377C281.85 141.109 279.65 142.126 279.585 143.902C279.542 145.097 280.53 146.05 281.482 146.816C282.435 147.582 283.502 148.348 283.762 149.522C283.816 150.311 283.816 151.103 283.762 151.892C283.86 152.736 284.27 153.514 284.912 154.076C285.554 154.638 286.383 154.944 287.239 154.935C288.148 154.892 289.166 154.506 289.894 155.05C290.161 155.321 290.402 155.616 290.616 155.93C291.46 156.854 292.996 156.825 294.114 156.246C295.181 155.576 296.131 154.739 296.928 153.768C297.735 152.791 298.851 152.112 300.095 151.842'
          fill='#263238'
        />
        <path
          d='M310.166 132.052L310.123 134.15L309.697 165.582C309.647 169.234 306.451 172.098 302.483 172.026C298.508 171.947 295.269 168.89 295.269 165.203C295.269 161.336 295.175 157.262 295.175 157.262C295.175 157.262 290.125 156.432 289.108 149.93C288.588 146.694 288.754 141.389 289.029 137.042C289.087 136.118 289.333 135.214 289.752 134.386C290.171 133.558 290.755 132.823 291.468 132.225C292.181 131.627 293.009 131.178 293.902 130.906C294.794 130.634 295.733 130.544 296.661 130.641L310.166 132.052Z'
          fill='#FFB29D'
        />
        <path
          d='M290.399 140.737C290.397 140.955 290.482 141.165 290.634 141.321C290.787 141.478 290.995 141.569 291.214 141.575C291.425 141.593 291.634 141.527 291.796 141.393C291.958 141.259 292.06 141.067 292.08 140.859C292.082 140.642 291.999 140.433 291.848 140.277C291.697 140.12 291.49 140.028 291.272 140.021C291.061 140.001 290.85 140.065 290.686 140.2C290.523 140.334 290.419 140.527 290.399 140.737Z'
          fill='#263238'
        />
        <path
          d='M290.219 139.799C290.32 139.906 290.94 139.441 291.864 139.441C292.787 139.441 293.429 139.899 293.53 139.785C293.631 139.67 293.472 139.541 293.184 139.334C292.793 139.063 292.326 138.92 291.849 138.925C291.378 138.923 290.919 139.068 290.536 139.341C290.262 139.556 290.168 139.749 290.219 139.799Z'
          fill='#263238'
        />
        <path
          d='M299.453 140.737C299.451 140.954 299.535 141.162 299.685 141.319C299.836 141.475 300.043 141.567 300.261 141.575C300.365 141.584 300.471 141.574 300.571 141.543C300.671 141.512 300.764 141.462 300.844 141.396C300.925 141.329 300.991 141.247 301.04 141.155C301.088 141.063 301.118 140.962 301.127 140.859C301.131 140.641 301.048 140.432 300.897 140.275C300.745 140.118 300.538 140.027 300.319 140.021C300.109 140.003 299.9 140.068 299.738 140.202C299.576 140.336 299.473 140.528 299.453 140.737Z'
          fill='#263238'
        />
        <path
          d='M299.107 139.971C299.215 140.078 299.828 139.613 300.752 139.613C301.675 139.613 302.324 140.064 302.418 139.956C302.512 139.849 302.36 139.706 302.072 139.498C301.68 139.23 301.214 139.087 300.737 139.09C300.267 139.087 299.809 139.235 299.431 139.513C299.15 139.72 299.056 139.921 299.107 139.971Z'
          fill='#263238'
        />
        <path
          d='M295.824 146.622C295.356 146.479 294.871 146.392 294.381 146.365C294.151 146.365 293.927 146.293 293.891 146.136C293.87 145.899 293.923 145.661 294.042 145.455C294.259 144.904 294.49 144.324 294.728 143.708C295.359 142.23 295.873 140.705 296.264 139.147C295.512 140.572 294.876 142.054 294.36 143.579C294.129 144.188 293.913 144.775 293.703 145.334C293.558 145.611 293.517 145.931 293.588 146.236C293.621 146.316 293.671 146.389 293.736 146.447C293.801 146.505 293.879 146.548 293.963 146.572C294.093 146.604 294.226 146.621 294.36 146.622C294.846 146.684 295.338 146.684 295.824 146.622Z'
          fill='#263238'
        />
        <path
          d='M295.204 157.248C298.289 157.293 301.325 156.487 303.976 154.921C303.976 154.921 301.848 159.389 295.319 158.78L295.204 157.248Z'
          fill='#E8947E'
        />
        <path
          d='M296.207 148.785C296.378 148.574 296.6 148.409 296.852 148.305C297.104 148.201 297.378 148.162 297.649 148.19C297.849 148.206 298.043 148.262 298.219 148.356C298.396 148.449 298.55 148.578 298.674 148.735C298.795 148.884 298.867 149.066 298.88 149.257C298.893 149.448 298.846 149.638 298.746 149.801C298.602 149.964 298.412 150.08 298.201 150.133C297.99 150.187 297.768 150.176 297.563 150.102C297.132 149.947 296.738 149.703 296.409 149.386C296.305 149.316 296.216 149.226 296.149 149.121C296.114 149.073 296.095 149.016 296.095 148.957C296.095 148.897 296.114 148.84 296.149 148.792'
          fill='#E8947E'
        />
        <path
          d='M298.486 147.245C298.342 147.245 298.342 148.212 297.498 148.907C296.654 149.601 295.615 149.486 295.608 149.623C295.601 149.759 295.839 149.816 296.279 149.823C296.853 149.831 297.413 149.639 297.859 149.279C298.288 148.934 298.562 148.435 298.623 147.89C298.667 147.496 298.551 147.238 298.486 147.245Z'
          fill='#263238'
        />
        <path
          d='M298.876 136.448C298.962 136.692 299.864 136.577 300.925 136.706C301.985 136.835 302.844 137.121 302.988 136.899C303.053 136.799 302.901 136.57 302.555 136.334C302.095 136.04 301.573 135.857 301.03 135.797C300.487 135.738 299.937 135.804 299.424 135.99C299.035 136.14 298.833 136.334 298.876 136.448Z'
          fill='#263238'
        />
        <path
          d='M290.551 137.2C290.709 137.408 291.33 137.2 292.08 137.2C292.83 137.2 293.465 137.336 293.61 137.121C293.674 137.021 293.573 136.813 293.299 136.62C292.927 136.387 292.491 136.272 292.051 136.291C291.612 136.297 291.186 136.44 290.832 136.699C290.565 136.892 290.479 137.107 290.551 137.2Z'
          fill='#263238'
        />
        <path
          d='M302.714 126.961C302.663 126.961 302.591 126.961 302.512 126.868L302.115 126.481L302.721 126.711C302.826 126.755 302.935 126.791 303.046 126.818C303.298 126.89 303.536 126.818 303.587 126.947C303.637 127.076 303.37 127.283 302.959 127.183C302.79 127.143 302.642 127.044 302.541 126.904L302.75 126.746C302.771 126.854 302.757 126.94 302.714 126.961Z'
          fill='#263238'
        />
        <path
          d='M302.634 127.706C302.045 128.376 301.633 129.181 301.436 130.048C301.238 130.916 301.261 131.818 301.502 132.675C301.781 133.544 302.263 134.336 302.908 134.985C303.554 135.635 304.345 136.124 305.217 136.412C305.65 136.556 306.162 136.706 306.328 137.128C306.411 137.494 306.346 137.877 306.148 138.195C305.74 139.288 305.709 140.485 306.059 141.597C306.409 142.71 307.12 143.676 308.081 144.346C307.853 145.543 307.931 146.779 308.309 147.939C308.688 149.098 309.353 150.145 310.245 150.983C310.245 149.859 311.089 148.899 312.02 148.198C313.015 147.59 313.947 146.887 314.805 146.1C315.411 145.419 315.794 144.571 315.901 143.669C316.008 142.767 315.834 141.854 315.404 141.052C315.069 140.614 314.857 140.095 314.79 139.548C314.79 138.904 315.382 138.417 315.829 137.93C316.798 136.842 317.315 135.43 317.275 133.978C317.235 132.527 316.641 131.145 315.613 130.112C315.115 129.632 314.531 129.238 314.069 128.722C313.545 128.021 313.063 127.289 312.626 126.532C311.799 125.435 310.678 124.591 309.391 124.098C308.103 123.604 306.702 123.479 305.347 123.739C302.627 124.29 300.946 126.918 301.523 129.882'
          fill='#263238'
        />
        <path
          d='M301.906 128.565C301.894 129.785 301.634 130.991 301.141 132.109C300.89 132.665 300.505 133.151 300.021 133.525C299.537 133.9 298.968 134.151 298.364 134.257C297.173 134.393 296.034 133.785 294.879 133.462C293.364 133.04 291.474 133.24 290.601 134.536C289.988 135.439 289.88 137.529 288.437 138.116C287.413 138.532 286.778 137.357 285.775 137.816C284.101 138.575 284.383 141.446 282.695 142.176C283.236 140.787 282.976 139.24 282.962 137.758C282.947 136.276 283.358 134.579 284.678 133.87C285.453 133.59 286.246 133.363 287.052 133.19C288.264 132.746 289.108 131.665 289.988 130.713C290.868 129.761 291.972 128.837 293.271 128.873C294.569 128.909 295.622 129.804 296.878 129.746C297.88 129.696 298.732 129.03 299.633 128.594C300.535 128.157 301.798 128.021 302.411 128.808'
          fill='#263238'
        />
        <path
          d='M315.187 130.255C315.187 130.255 315.007 130.119 314.66 129.968C314.167 129.769 313.628 129.71 313.102 129.796C312.316 129.962 311.553 130.221 310.83 130.57C309.888 130.989 308.86 131.183 307.829 131.135C306.797 131.077 305.795 130.773 304.907 130.248C304.189 129.814 303.568 129.239 303.082 128.558C302.752 128.107 302.492 127.61 302.31 127.083C302.227 126.895 302.183 126.693 302.18 126.489C302.484 127.162 302.856 127.802 303.291 128.4C303.786 129.021 304.392 129.546 305.08 129.947C305.926 130.432 306.875 130.711 307.85 130.763C309.964 130.949 311.594 129.625 313.073 129.539C313.642 129.466 314.22 129.571 314.725 129.839C315.079 130.04 315.202 130.24 315.187 130.255Z'
          fill='#455A64'
        />
        <path
          d='M280.4 143.2C280.812 143.255 281.226 143.287 281.641 143.293C282.775 143.255 283.863 142.838 284.729 142.112C285.312 141.612 285.759 140.974 286.028 140.257C286.313 139.416 286.471 138.538 286.496 137.651C286.554 136.706 286.54 135.696 286.684 134.651C286.79 133.543 287.216 132.489 287.91 131.615C288.278 131.169 288.748 130.818 289.281 130.591C289.804 130.381 290.372 130.305 290.933 130.369C292.015 130.484 292.96 130.878 293.891 130.971C295.561 131.174 297.251 130.792 298.667 129.89C299.614 129.27 300.411 128.448 300.997 127.484C301.408 126.768 301.552 126.367 301.581 126.381C301.572 126.49 301.545 126.596 301.502 126.696C301.396 126.991 301.268 127.279 301.12 127.555C300.569 128.578 299.777 129.453 298.811 130.104C297.354 131.081 295.595 131.51 293.848 131.314C292.867 131.214 291.907 130.835 290.904 130.734C290.403 130.676 289.895 130.742 289.425 130.928C288.95 131.137 288.53 131.453 288.199 131.851C287.548 132.677 287.149 133.671 287.052 134.715C286.908 135.725 286.915 136.727 286.843 137.687C286.806 138.606 286.629 139.513 286.316 140.379C286.016 141.143 285.519 141.815 284.873 142.326C283.958 143.072 282.804 143.471 281.62 143.451C281.306 143.446 280.994 143.405 280.689 143.329C280.501 143.25 280.393 143.2 280.4 143.2Z'
          fill='#455A64'
        />
        <path
          d='M288.43 143.658C288.42 144.001 288.323 144.336 288.148 144.632C287.443 146.146 286.173 147.329 284.606 147.933C284.008 148.148 283.618 148.19 283.604 148.14C284.629 147.676 285.586 147.074 286.446 146.35C287.223 145.543 287.89 144.638 288.43 143.658Z'
          fill='#455A64'
        />
        <path
          d='M327.523 143.085C327.505 142.635 327.425 142.19 327.285 141.761C327.11 141.266 326.838 140.811 326.485 140.422C326.025 139.907 325.434 139.524 324.775 139.312C323.947 139.136 323.092 139.136 322.264 139.312C321.775 139.388 321.279 139.41 320.785 139.377C320.242 139.327 319.725 139.126 319.292 138.797C318.412 138.081 317.95 137.05 317.294 136.169C316.992 135.7 316.569 135.32 316.069 135.068C315.569 134.816 315.011 134.702 314.451 134.737C313.291 134.837 312.142 135.038 311.017 135.338C309.524 135.603 307.984 135.426 306.591 134.829C305.198 134.232 304.013 133.241 303.183 131.98C302.493 130.904 302.089 129.673 302.007 128.4C301.979 128.06 301.979 127.717 302.007 127.376C302.011 127.257 302.033 127.139 302.072 127.026C302.072 127.026 302.072 127.52 302.137 128.386C302.278 129.607 302.708 130.777 303.392 131.801C304.207 132.993 305.354 133.924 306.692 134.48C308.031 135.037 309.503 135.196 310.931 134.937C312.075 134.625 313.247 134.418 314.43 134.322C315.051 134.29 315.67 134.423 316.222 134.709C316.774 134.995 317.239 135.422 317.568 135.947C318.246 136.878 318.7 137.894 319.487 138.517C319.883 138.777 320.329 138.954 320.797 139.036C321.264 139.119 321.744 139.105 322.207 138.997C323.076 138.831 323.972 138.853 324.832 139.062C325.441 139.273 325.992 139.624 326.439 140.085C326.886 140.547 327.218 141.106 327.408 141.718C327.507 142.048 327.556 142.39 327.552 142.735C327.559 142.852 327.549 142.97 327.523 143.085Z'
          fill='#455A64'
        />
        <path
          d='M322.719 147.718C322.637 147.849 322.526 147.959 322.393 148.039C322.26 148.119 322.109 148.166 321.954 148.176C321.591 148.222 321.223 148.162 320.894 148.004C320.47 147.774 320.122 147.429 319.891 147.009C319.623 146.518 319.399 146.006 319.22 145.477C319.036 144.896 318.728 144.361 318.318 143.909C317.808 143.478 317.169 143.227 316.5 143.193C315.779 143.121 315.021 143.1 314.249 143.057C312.775 143.044 311.312 142.79 309.921 142.305C308.757 141.895 307.766 141.108 307.107 140.071C306.674 139.369 306.472 138.551 306.53 137.73C306.549 137.51 306.595 137.294 306.667 137.085C306.725 136.942 306.761 136.871 306.768 136.878C306.776 136.885 306.689 137.186 306.682 137.737C306.676 138.517 306.902 139.282 307.331 139.935C307.987 140.892 308.939 141.611 310.044 141.983C311.41 142.444 312.842 142.686 314.285 142.699C315.057 142.742 315.815 142.763 316.558 142.842C317.304 142.887 318.013 143.179 318.571 143.673C319.009 144.153 319.332 144.726 319.516 145.348C319.676 145.867 319.879 146.372 320.122 146.859C320.316 147.248 320.619 147.574 320.995 147.797C321.284 147.952 321.611 148.026 321.94 148.011C322.221 147.984 322.49 147.883 322.719 147.718Z'
          fill='#455A64'
        />
        <path
          d='M255.764 184.263L252.835 180.683C251.06 178.012 253.189 176.616 253.405 176.029C253.682 175.11 254.157 174.262 254.797 173.544L254.141 172.441C253.321 171.312 252.581 170.128 251.926 168.897C251.674 168.181 252.914 167.63 253.369 168.231C254.408 169.606 256.197 171.919 256.976 172.721C258 173.78 260.482 176.63 260.85 174.103C261.218 171.575 260.605 170.422 260.554 169.699C260.504 168.976 261.947 168.217 262.221 169.699C262.329 170.336 262.675 171.396 262.877 172.943C262.964 173.659 262.928 174.539 263 175.227C263.094 176.036 264.096 178.771 264.197 179.344L255.764 184.263Z'
          fill='#FFB29D'
        />
        <path
          d='M282.363 169.756L272.696 193.728L264.803 178.041L254.812 183.955L264.558 203.731C265.344 205.294 266.571 206.597 268.091 207.479C269.61 208.362 271.356 208.786 273.114 208.7C274.437 208.619 275.722 208.227 276.862 207.556C278.003 206.885 278.966 205.954 279.672 204.84C283.228 199.22 288.791 185.738 288.791 185.738C288.798 186.425 282.363 169.756 282.363 169.756Z'
          fill='#455A64'
        />
        <path
          d='M317.633 189.024L322.51 168.095C317.46 163.269 309.733 162.868 309.733 162.868L307.216 164.544C305.703 165.55 303.911 166.064 302.091 166.012C300.27 165.961 298.511 165.348 297.058 164.258L295.204 162.868C289.231 163.584 284.888 165.575 282.363 169.778L285.003 190.628L288.076 200.652L282.074 222.411L322.235 224.108L314.855 198.783C314.538 199.327 317.633 189.038 317.633 189.038'
          fill='#455A64'
        />
        <path
          d='M318.535 192.769C318.472 192.427 318.388 192.09 318.282 191.759C318.109 191.115 317.864 190.177 317.633 189.002C317.011 185.941 316.769 182.815 316.911 179.694C316.971 177.984 317.213 176.285 317.633 174.625C317.969 173.277 318.592 172.015 319.458 170.923C320.053 170.189 320.789 169.578 321.622 169.126C322.214 168.818 322.582 168.718 322.567 168.69C322.553 168.661 322.473 168.69 322.3 168.747C322.052 168.816 321.811 168.907 321.579 169.019C320.711 169.446 319.941 170.049 319.321 170.787C318.406 171.887 317.745 173.173 317.387 174.554C316.961 176.232 316.719 177.95 316.666 179.68C316.505 182.814 316.76 185.956 317.424 189.024C317.69 190.198 317.965 191.129 318.145 191.773C318.246 192.088 318.34 192.332 318.397 192.489C318.455 192.647 318.52 192.776 318.535 192.769Z'
          fill='#263238'
        />
        <path
          d='M302.187 220.528C300.008 222.008 297.749 223.368 295.42 224.602C292.455 225.998 292.318 225.74 292.203 225.375C291.893 224.387 297.318 222.719 295.925 222.411C294.533 222.103 289.548 225.211 289.267 224.165C288.906 222.805 294.62 221.158 295.089 220.986C295.557 220.814 295.312 220.134 294.894 220.313C294.295 220.571 287.918 223.134 287.55 221.795C287.341 221.079 288.733 220.85 288.733 220.85C288.733 220.85 294.887 218.881 294.685 218.258C294.483 217.635 290.832 218.659 289.635 219.089C288.437 219.518 287.737 219.139 287.975 218.459C288.134 218 291.792 216.583 292.989 216.182C294.187 215.781 297.513 214.75 295.42 213.941C293.328 213.132 292.015 211.793 291.914 211.17C291.813 210.547 292.636 210.225 293.141 210.712C293.724 211.187 294.37 211.582 295.06 211.886C295.607 212.15 296.194 212.322 296.798 212.394C297.318 212.459 297.787 212.494 298.198 212.523L302.642 212.86L304.885 218.695L302.187 220.528Z'
          fill='#FFB29D'
        />
        <path
          d='M321.651 167.272C324.174 169.275 326.011 172.003 326.91 175.083C327.899 178.291 330.727 189.131 332.812 197.208C333.466 199.746 333.125 202.437 331.859 204.736C330.592 207.035 328.493 208.772 325.987 209.595L302.461 222.211L298.624 212.344L319.256 198.883L314.271 180.547C313.449 177.761 313.095 174.926 314.127 172.205C315.158 169.484 317.315 166.756 321.651 167.272Z'
          fill='#455A64'
        />
        <path
          d='M327.358 209.022L327.249 209.101L326.903 209.294L325.568 210.01L320.519 212.752L302.562 222.332L302.397 222.425L302.324 222.246C301.134 219.189 299.828 215.867 298.472 212.387L298.414 212.251L298.544 212.172L307.793 206.129L319.177 198.711L319.105 198.905C317.748 193.857 316.738 189.103 315.779 184.85C315.541 183.783 315.317 182.745 315.057 181.749C314.818 180.783 314.637 179.802 314.516 178.814C314.347 177.042 314.499 175.255 314.964 173.537C315.346 172.126 316.121 170.85 317.2 169.857C318.025 169.133 319.041 168.658 320.129 168.489C320.803 168.384 321.492 168.425 322.149 168.611C322.375 168.673 322.593 168.762 322.798 168.876C322.798 168.876 322.575 168.783 322.135 168.668C321.482 168.515 320.804 168.495 320.143 168.611C319.091 168.795 318.113 169.272 317.323 169.986C316.275 170.967 315.525 172.219 315.158 173.601C314.719 175.293 314.585 177.048 314.762 178.785C314.889 179.762 315.077 180.731 315.324 181.685C315.548 182.687 315.779 183.718 316.046 184.778C317.02 189.024 318.059 193.785 319.415 198.812L319.451 198.941L319.343 199.012L307.966 206.437L298.703 212.473L298.775 212.258C300.124 215.738 301.415 219.067 302.606 222.125L302.368 222.039L320.403 212.573L325.518 209.917L326.881 209.201L327.235 209.029L327.358 209.022Z'
          fill='#263238'
        />
        <path
          d='M288.791 174.811C288.847 175.123 288.862 175.441 288.834 175.756C288.792 176.62 288.646 177.476 288.401 178.305C288.035 179.521 287.604 180.716 287.11 181.885C286.49 183.303 286.007 184.774 285.667 186.282C285.4 187.795 285.4 189.343 285.667 190.857C285.875 192.103 286.172 193.333 286.554 194.537C286.879 195.568 287.167 196.399 287.362 196.972C287.48 197.266 287.569 197.57 287.629 197.881C287.46 197.614 287.324 197.328 287.225 197.029C286.987 196.463 286.662 195.647 286.302 194.623C285.878 193.415 285.552 192.174 285.328 190.914C285.046 189.359 285.046 187.766 285.328 186.21C285.657 184.681 286.141 183.189 286.771 181.757C287.326 180.432 287.817 179.236 288.134 178.177C288.469 177.08 288.689 175.953 288.791 174.811Z'
          fill='#263238'
        />
        <path
          d='M319.667 198.661C319.667 198.618 319.905 198.518 320.338 198.525C320.902 198.543 321.451 198.712 321.927 199.013C322.403 199.314 322.789 199.737 323.043 200.237C323.231 200.623 323.245 200.888 323.202 200.902C322.816 200.299 322.328 199.766 321.759 199.327C321.108 198.983 320.399 198.758 319.667 198.661Z'
          fill='#263238'
        />
        <path
          d='M301.408 181.943C301.421 181.91 301.438 181.878 301.459 181.85C301.512 181.762 301.572 181.678 301.639 181.599C301.893 181.299 302.208 181.055 302.562 180.883C303.138 180.596 303.796 180.518 304.424 180.661C304.84 180.758 305.23 180.946 305.564 181.211C305.899 181.476 306.169 181.812 306.357 182.193C306.584 182.661 306.685 183.179 306.651 183.697C306.617 184.215 306.449 184.716 306.162 185.15L304.25 187.971L299.843 194.415V194.466H299.785L299.28 194.659L295.06 196.241L294.807 196.334V196.069C294.807 194.372 294.807 192.769 294.807 191.279V191.222V191.186C296.712 188.451 298.328 186.174 299.489 184.47L300.903 182.58C301.053 182.351 301.222 182.136 301.408 181.936C301.293 182.187 301.153 182.427 300.99 182.652L299.72 184.585L295.146 191.372V191.279C295.146 192.769 295.146 194.372 295.146 196.069L294.901 195.898L299.078 194.33L299.583 194.143L299.489 194.208C301.069 191.924 302.562 189.754 303.926 187.764L305.859 184.964C306.124 184.575 306.283 184.125 306.321 183.657C306.358 183.189 306.274 182.72 306.076 182.294C305.91 181.941 305.668 181.629 305.366 181.382C305.064 181.134 304.71 180.956 304.33 180.862C303.747 180.715 303.132 180.768 302.584 181.012C302.133 181.241 301.733 181.558 301.408 181.943Z'
          fill='#E0E0E0'
        />
        <path
          d='M299.734 194.258C298.099 193.429 296.585 192.384 295.233 191.15C296.868 191.979 298.382 193.025 299.734 194.258Z'
          fill='#E0E0E0'
        />
        <path
          d='M296.654 195.418C296.553 195.461 296.308 195.088 295.933 194.745C295.557 194.401 295.11 194.215 295.132 194.108C295.154 194 295.687 194.05 296.171 194.458C296.654 194.867 296.748 195.389 296.654 195.418Z'
          fill='#E0E0E0'
        />
        <path
          d='M306.112 184.878C304.54 184.044 303.095 182.993 301.819 181.757C303.391 182.591 304.837 183.642 306.112 184.878Z'
          fill='#E0E0E0'
        />
        <path
          d='M297.484 192.518C297.701 192.011 297.977 191.53 298.306 191.086C298.854 190.241 299.633 189.081 300.506 187.821C301.379 186.561 302.187 185.415 302.779 184.599C303.081 184.143 303.416 183.71 303.782 183.303C303.904 183.382 302.57 185.494 300.817 188.029C299.944 189.296 299.128 190.427 298.522 191.236C298.228 191.703 297.88 192.133 297.484 192.518Z'
          fill='#E0E0E0'
        />
        <path
          d='M253.557 176.902C253.847 177.244 254.117 177.602 254.365 177.976C254.662 178.351 254.988 178.702 255.338 179.029C255.577 179.23 255.778 179.473 255.93 179.745C256.025 179.913 256.064 180.107 256.041 180.299C256.018 180.491 255.933 180.67 255.8 180.811C255.656 180.929 255.485 181.008 255.303 181.043C255.12 181.078 254.932 181.068 254.754 181.012C254.45 180.935 254.164 180.801 253.91 180.618C253.498 180.322 253.148 179.95 252.878 179.523C252.603 179.156 252.402 178.741 252.287 178.298C252.585 178.634 252.855 178.993 253.095 179.372C253.375 179.748 253.716 180.075 254.105 180.339C254.326 180.483 254.57 180.59 254.826 180.654C254.947 180.693 255.075 180.704 255.2 180.686C255.325 180.667 255.444 180.619 255.548 180.547C255.624 180.459 255.671 180.349 255.682 180.234C255.692 180.118 255.665 180.002 255.605 179.902C255.473 179.67 255.302 179.462 255.1 179.286C254.75 178.932 254.434 178.546 254.155 178.134C253.871 177.769 253.668 177.35 253.557 176.902Z'
          fill='#E8947E'
        />
        <path
          d='M254.855 174.826C255.317 175.217 255.735 175.657 256.103 176.136L257.366 177.611C257.597 177.897 257.849 178.162 258.087 178.52C258.219 178.714 258.298 178.939 258.318 179.172C258.327 179.433 258.237 179.689 258.065 179.888C257.912 180.07 257.711 180.207 257.485 180.284C257.258 180.361 257.015 180.375 256.781 180.325C256.37 180.242 255.991 180.043 255.692 179.752C255.24 179.278 254.823 178.773 254.444 178.241C254.049 177.79 253.708 177.295 253.427 176.766C253.877 177.162 254.288 177.601 254.653 178.076C255.051 178.575 255.475 179.053 255.923 179.508C256.18 179.743 256.497 179.904 256.839 179.974C257.009 180.011 257.187 180.001 257.352 179.946C257.517 179.89 257.664 179.791 257.777 179.659C257.885 179.53 257.944 179.368 257.944 179.2C257.944 179.033 257.885 178.871 257.777 178.742C257.575 178.441 257.308 178.141 257.092 177.854L255.894 176.329C255.494 175.867 255.145 175.363 254.855 174.826Z'
          fill='#E8947E'
        />
        <path
          d='M272.616 198.16C272.388 196.658 272.415 195.128 272.695 193.635C272.914 195.138 272.887 196.666 272.616 198.16Z'
          fill='#263238'
        />
        <path
          d='M297.534 253.306C291.929 253.135 294.569 244.972 294.706 236.122C294.793 230.552 298.522 225.096 302.584 221.258C304.649 219.449 307.083 218.101 309.719 217.306C316.637 214.965 324.147 214.714 331.361 215.924C331.361 215.895 333.042 254.423 297.534 253.306Z'
          fill='#407BFF'
        />
        <path
          d='M109.021 251.524C114.626 251.345 111.986 243.182 111.849 234.304C111.762 228.74 108.039 223.277 103.971 219.447C101.906 217.635 99.4724 216.285 96.8359 215.487C89.8475 213.259 82.411 212.786 75.1934 214.113C75.1934 214.113 73.5125 252.633 109.021 251.524Z'
          fill='#455A64'
        />
        <path
          d='M72.3726 325.544C72.3726 325.544 67.8277 321.606 59.5963 321.248V252.39H56.5447V321.326C48.6668 321.878 43.9993 325.544 43.9993 325.544H56.5447V325.601H59.5963V325.544H72.3726Z'
          fill='#263238'
        />
        <path
          d='M53.0746 176.73C49.7272 176.43 46.3149 176.172 43.0181 176.823C39.7212 177.475 36.5253 179.186 34.729 182.036C32.9327 184.885 32.911 188.48 32.911 191.816V234.304H71.1461L53.0746 176.73Z'
          fill='#455A64'
        />
        <path
          d='M32.9038 232.349C32.7451 240.719 33.481 247.528 39.3245 250.822C43.6962 253.285 48.9842 253.342 54.0125 253.335H75.7921C77.9996 253.335 80.3659 253.278 82.1694 252.018C84.1533 250.621 84.983 247.979 84.6367 245.574C84.1868 243.188 83.0345 240.988 81.3254 239.251C75.0346 232.32 65.0863 230.258 55.8377 228.576'
          fill='#455A64'
        />
        <path
          d='M116.047 321.412L115.903 313.837L101.604 314.016L101.698 325.107L102.585 325.15C106.532 325.315 122.677 325.73 125.303 324.899C128.232 323.976 116.047 321.412 116.047 321.412Z'
          fill='#407BFF'
        />
        <g opacity='0.4'>
          <path
            d='M122.006 322.916C122.006 322.916 126.277 324.076 125.757 324.785C125.238 325.494 108.905 325.68 101.698 325.143V324.821L120.744 324.756C120.744 324.756 121.198 322.916 122.006 322.916Z'
            fill='white'
          />
        </g>
        <path
          d='M101.489 324.778H101.742H102.463L105.067 324.863C107.231 324.914 110.298 324.942 113.652 324.906C117.007 324.871 120.037 324.77 122.23 324.67L124.827 324.527L125.548 324.484C125.63 324.494 125.712 324.494 125.794 324.484C125.712 324.479 125.63 324.479 125.548 324.484H124.827L122.223 324.57C120.058 324.641 116.999 324.72 113.645 324.756C110.29 324.792 107.268 324.756 105.067 324.756H102.47H101.511L101.489 324.778Z'
          fill='#263238'
        />
        <path
          d='M120.758 324.942C120.871 324.513 121.031 324.098 121.234 323.704C121.484 323.338 121.777 323.004 122.107 322.708C121.663 322.874 121.298 323.198 121.083 323.618C120.813 324.004 120.698 324.476 120.758 324.942Z'
          fill='#263238'
        />
        <path
          d='M116.516 322.866C116.559 322.866 116.754 322.644 116.963 322.314C117.108 322.126 117.208 321.908 117.259 321.677C117.216 321.677 117.014 321.899 116.812 322.229C116.61 322.558 116.473 322.844 116.516 322.866Z'
          fill='#263238'
        />
        <path
          d='M115.347 322.272C115.383 322.272 115.607 322.157 115.852 321.935C116.098 321.713 116.264 321.498 116.235 321.47C116.206 321.441 115.975 321.584 115.73 321.806C115.484 322.028 115.319 322.236 115.347 322.272Z'
          fill='#263238'
        />
        <path
          d='M114.604 320.94C114.835 321.022 115.082 321.052 115.326 321.026C115.57 321.051 115.816 321.021 116.047 320.94C115.815 320.862 115.57 320.833 115.326 320.854C115.082 320.832 114.836 320.861 114.604 320.94Z'
          fill='#263238'
        />
        <path
          d='M114.337 320.002C114.586 320.162 114.878 320.242 115.174 320.231C115.466 320.275 115.765 320.233 116.033 320.109C115.754 320.064 115.471 320.047 115.189 320.059C114.908 320.011 114.622 319.992 114.337 320.002Z'
          fill='#263238'
        />
        <path
          d='M116.985 321.62C117.29 321.674 117.603 321.674 117.908 321.62C118.24 321.576 118.566 321.499 118.882 321.391C119.074 321.332 119.26 321.256 119.438 321.162C119.544 321.095 119.623 320.994 119.661 320.875C119.677 320.807 119.671 320.736 119.646 320.671C119.62 320.606 119.575 320.55 119.517 320.51C119.29 320.404 119.042 320.35 118.791 320.351C118.54 320.352 118.292 320.409 118.066 320.516C117.839 320.624 117.64 320.78 117.482 320.973C117.323 321.166 117.21 321.392 117.151 321.634C117.151 321.792 117.151 321.885 117.151 321.885C117.236 321.6 117.373 321.333 117.555 321.097C117.76 320.856 118.031 320.677 118.334 320.582C118.694 320.459 119.088 320.482 119.431 320.646C119.582 320.754 119.524 320.918 119.366 321.019C119.202 321.109 119.03 321.186 118.853 321.248C118.546 321.356 118.23 321.439 117.908 321.498C117.338 321.591 116.985 321.591 116.985 321.62Z'
          fill='#263238'
        />
        <path
          d='M117.223 321.663C117.334 321.473 117.37 321.248 117.324 321.033C117.292 320.794 117.203 320.565 117.064 320.367C116.986 320.232 116.874 320.119 116.738 320.038C116.603 319.958 116.45 319.913 116.293 319.909C116.2 319.931 116.119 319.988 116.068 320.068C116.017 320.149 115.999 320.245 116.018 320.338C116.037 320.483 116.091 320.621 116.177 320.739C116.3 320.941 116.448 321.126 116.617 321.291C116.759 321.453 116.94 321.576 117.144 321.649C117.144 321.649 116.963 321.491 116.704 321.205C116.555 321.037 116.425 320.855 116.314 320.661C116.184 320.467 116.098 320.124 116.314 320.081C116.531 320.038 116.783 320.281 116.927 320.46C117.058 320.634 117.149 320.835 117.194 321.047C117.223 321.251 117.233 321.457 117.223 321.663Z'
          fill='#263238'
        />
        <path
          d='M101.72 320.897C102.073 320.878 102.427 320.878 102.78 320.897C103.623 320.979 104.426 321.296 105.095 321.812C105.764 322.328 106.272 323.021 106.561 323.811C106.784 324.42 106.813 324.828 106.842 324.828C106.86 324.733 106.86 324.636 106.842 324.541C106.812 324.281 106.756 324.025 106.676 323.775C106.414 322.943 105.903 322.21 105.211 321.672C104.519 321.135 103.679 320.82 102.802 320.768C102.541 320.74 102.277 320.74 102.016 320.768C101.908 320.788 101.807 320.832 101.72 320.897Z'
          fill='#263238'
        />
        <path
          d='M103.163 314.968C103.075 315.937 103.046 316.91 103.076 317.882C103.046 318.857 103.072 319.832 103.155 320.804C103.244 319.835 103.275 318.862 103.249 317.89C103.279 316.915 103.25 315.939 103.163 314.968Z'
          fill='#263238'
        />
        <path
          d='M108.667 323.525C109.352 323.67 110.052 323.73 110.752 323.704C111.455 323.73 112.158 323.665 112.844 323.51C112.844 323.46 111.906 323.51 110.752 323.51C109.598 323.51 108.667 323.474 108.667 323.525Z'
          fill='#263238'
        />
        <path
          d='M105.861 323.553C105.825 323.589 105.94 323.747 106.034 323.961C106.127 324.176 106.192 324.355 106.235 324.355C106.278 324.355 106.307 324.126 106.192 323.883C106.077 323.639 105.89 323.517 105.861 323.553Z'
          fill='#263238'
        />
        <path
          d='M104.786 322.257C104.786 322.293 104.851 322.436 105.002 322.587C105.154 322.737 105.298 322.823 105.334 322.794C105.37 322.766 105.269 322.608 105.118 322.465C104.966 322.322 104.822 322.2 104.786 322.257Z'
          fill='#263238'
        />
        <path
          d='M103.271 321.684C103.271 321.727 103.473 321.727 103.682 321.799C103.891 321.871 104.043 321.978 104.086 321.942C104.129 321.906 103.999 321.713 103.733 321.634C103.466 321.556 103.256 321.634 103.271 321.684Z'
          fill='#263238'
        />
        <path
          d='M102.189 321.412C102.189 321.455 102.261 321.527 102.376 321.57C102.491 321.613 102.606 321.62 102.621 321.57C102.635 321.52 102.556 321.455 102.434 321.412C102.311 321.369 102.21 321.369 102.189 321.412Z'
          fill='#263238'
        />
        <g opacity='0.3'>
          <path
            d='M101.749 320.51L116.033 320.109L115.989 318.398L101.641 318.67L101.749 320.51Z'
            fill='black'
          />
        </g>
        <path
          d='M93.676 321.484L93.6327 313.909H79.3559L79.3126 324.992L80.2 325.05C84.1389 325.264 100.277 325.88 102.917 325.085C105.825 324.176 93.676 321.484 93.676 321.484Z'
          fill='#407BFF'
        />
        <g opacity='0.4'>
          <path
            d='M99.6132 323.002C99.6132 323.002 103.87 324.219 103.343 324.914C102.816 325.608 86.4834 325.63 79.2837 324.971V324.656L98.3291 324.828C98.3291 324.828 98.7836 323.03 99.6132 323.002Z'
            fill='white'
          />
        </g>
        <path
          d='M79.0818 324.642H79.3271L80.0485 324.684L82.6456 324.799C84.8098 324.878 87.8759 324.949 91.2232 324.949C94.5706 324.949 97.615 324.892 99.8081 324.821L102.405 324.713L103.127 324.677C103.208 324.685 103.29 324.685 103.372 324.677H102.42L99.8225 324.727C97.6222 324.77 94.5923 324.813 91.2377 324.806C87.8831 324.799 84.8603 324.756 82.66 324.706L80.0629 324.649H79.3415C79.2554 324.637 79.1684 324.635 79.0818 324.642Z'
          fill='#263238'
        />
        <path
          d='M98.3436 325.064C98.4574 324.636 98.622 324.222 98.8342 323.832C99.0891 323.471 99.3844 323.14 99.7143 322.844C99.2859 323.033 98.9253 323.346 98.6805 323.742C98.4357 324.139 98.3182 324.6 98.3436 325.064Z'
          fill='#263238'
        />
        <path
          d='M94.1233 322.916C94.1666 322.916 94.3686 322.701 94.5778 322.372C94.787 322.042 94.9241 321.763 94.8808 321.742C94.8375 321.72 94.6427 321.957 94.4335 322.286C94.2845 322.471 94.1786 322.686 94.1233 322.916Z'
          fill='#263238'
        />
        <path
          d='M92.9691 322.315C92.9691 322.315 93.2288 322.2 93.4741 321.978C93.7194 321.756 93.8925 321.548 93.8636 321.513C93.8348 321.477 93.6039 321.627 93.3587 321.849C93.1134 322.071 92.9402 322.279 92.9691 322.315Z'
          fill='#263238'
        />
        <path
          d='M92.2404 320.968C92.4701 321.056 92.7168 321.09 92.9618 321.069C93.2051 321.089 93.4502 321.063 93.6832 320.99C93.4532 320.904 93.2063 320.872 92.9618 320.897C92.7189 320.87 92.4731 320.895 92.2404 320.968Z'
          fill='#263238'
        />
        <path
          d='M91.9879 320.052C92.2443 320.19 92.5272 320.273 92.8181 320.295C93.109 320.318 93.4014 320.279 93.676 320.181C93.3972 320.135 93.1145 320.116 92.832 320.124C92.5532 320.076 92.2708 320.052 91.9879 320.052Z'
          fill='#263238'
        />
        <path
          d='M94.6139 321.684C94.9174 321.732 95.2265 321.732 95.5301 321.684C95.863 321.642 96.1914 321.57 96.5112 321.47C96.7054 321.417 96.892 321.34 97.0667 321.24C97.1774 321.181 97.2602 321.081 97.2976 320.961C97.3118 320.894 97.3065 320.824 97.2822 320.759C97.258 320.694 97.2158 320.638 97.1605 320.596C96.934 320.488 96.686 320.43 96.4345 320.428C96.183 320.426 95.9341 320.479 95.7058 320.584C95.4774 320.689 95.2753 320.842 95.114 321.034C94.9526 321.225 94.836 321.45 94.7726 321.692C94.7365 321.849 94.7365 321.935 94.7726 321.935C94.8661 321.655 95.0077 321.394 95.191 321.162C95.3967 320.919 95.6704 320.742 95.9774 320.653C96.3381 320.533 96.7322 320.558 97.0739 320.725C97.2182 320.832 97.1605 321.004 96.9946 321.104C96.831 321.192 96.6595 321.264 96.4824 321.319C96.1744 321.425 95.8583 321.507 95.5373 321.563C94.9602 321.656 94.6139 321.656 94.6139 321.684Z'
          fill='#263238'
        />
        <path
          d='M94.8519 321.727C94.9624 321.537 95.0008 321.313 94.9602 321.097C94.9276 320.857 94.8413 320.627 94.7077 320.424C94.629 320.29 94.518 320.177 94.3845 320.096C94.251 320.015 94.0993 319.968 93.943 319.959C93.8965 319.968 93.8524 319.987 93.8131 320.013C93.7739 320.039 93.7402 320.073 93.7141 320.112C93.688 320.151 93.6699 320.195 93.6609 320.242C93.6519 320.288 93.6521 320.335 93.6616 320.381C93.6797 320.527 93.7315 320.667 93.8131 320.789C93.9316 320.992 94.0772 321.177 94.2459 321.341C94.3909 321.503 94.5709 321.63 94.7726 321.713C94.7726 321.713 94.5922 321.548 94.3397 321.262C94.1908 321.092 94.0602 320.907 93.9502 320.711C93.8275 320.517 93.741 320.174 93.9502 320.131C94.1594 320.088 94.4119 320.338 94.5562 320.517C94.6836 320.695 94.7721 320.898 94.8159 321.112C94.845 321.316 94.8571 321.522 94.8519 321.727Z'
          fill='#263238'
        />
        <path
          d='M79.356 320.768C79.356 320.768 79.7672 320.732 80.4164 320.804C81.2561 320.904 82.052 321.231 82.7178 321.749C83.373 322.281 83.8715 322.978 84.1606 323.768C84.377 324.377 84.3987 324.785 84.4275 324.785C84.4564 324.785 84.4275 324.684 84.4275 324.498C84.4036 324.238 84.3528 323.982 84.276 323.732C84.0231 322.896 83.5197 322.157 82.8328 321.612C82.1459 321.067 81.3081 320.743 80.4309 320.682C80.1671 320.654 79.9011 320.654 79.6373 320.682C79.5391 320.694 79.4438 320.723 79.356 320.768Z'
          fill='#263238'
        />
        <path
          d='M80.8634 314.854C80.7169 316.795 80.6905 318.744 80.7842 320.689C80.8788 319.721 80.9196 318.748 80.9066 317.775C80.9456 316.801 80.9312 315.826 80.8634 314.854Z'
          fill='#263238'
        />
        <path
          d='M86.2671 323.474C86.9498 323.634 87.6508 323.704 88.352 323.682C89.0536 323.717 89.7568 323.662 90.4441 323.517C90.4441 323.467 89.5062 323.517 88.3592 323.517C87.2121 323.517 86.2743 323.424 86.2671 323.474Z'
          fill='#263238'
        />
        <path
          d='M83.4608 323.467C83.4608 323.503 83.54 323.668 83.6336 323.883C83.7272 324.097 83.7849 324.283 83.8281 324.276C83.8713 324.269 83.9073 324.054 83.8281 323.811C83.7488 323.568 83.4968 323.439 83.4608 323.467Z'
          fill='#263238'
        />
        <path
          d='M82.4075 322.2C82.4075 322.236 82.4652 322.379 82.6167 322.529C82.7682 322.68 82.9125 322.773 82.9413 322.737C82.9702 322.701 82.8836 322.558 82.7393 322.408C82.595 322.257 82.4435 322.128 82.4075 322.2Z'
          fill='#263238'
        />
        <path
          d='M80.8997 321.57C80.8997 321.613 81.0945 321.613 81.3037 321.684C81.5129 321.756 81.6716 321.871 81.7077 321.842C81.7438 321.813 81.6211 321.613 81.3614 321.527C81.1017 321.441 80.8853 321.527 80.8997 321.57Z'
          fill='#263238'
        />
        <path
          d='M79.8248 321.283C79.8248 321.326 79.8896 321.405 80.0049 321.448C80.1201 321.491 80.2353 321.498 80.2497 321.448C80.2641 321.398 80.1849 321.334 80.0697 321.291C79.9545 321.248 79.8392 321.24 79.8248 321.283Z'
          fill='#263238'
        />
        <g opacity='0.3'>
          <path
            d='M79.392 320.381L93.6761 320.152L93.6544 318.448L79.3054 318.534L79.392 320.381Z'
            fill='black'
          />
        </g>
        <path
          d='M62.0419 158.558C59.8476 157.336 57.5157 156.374 55.0946 155.694C52.6488 155.011 50.0388 155.203 47.7217 156.238C46.0234 157.214 44.5217 158.494 43.2922 160.012C41.7989 161.644 40.2334 163.413 39.9376 165.604C39.842 166.581 39.97 167.567 40.3123 168.488C40.6546 169.409 41.2023 170.242 41.9143 170.923C43.3623 172.282 45.1908 173.173 47.159 173.48C50.3426 174.052 53.6275 173.47 56.4148 171.84C60.094 169.692 62.7849 165.747 66.9403 164.744'
          fill='#407BFF'
        />
        <g opacity='0.5'>
          <path
            d='M59.9498 158.064C57.7032 157.008 55.2689 156.402 52.7862 156.281C50.2924 156.165 47.8255 156.834 45.7379 158.193C44.8145 158.83 43.9272 159.833 44.1508 160.921C44.3744 162.009 45.4349 162.539 46.4665 162.74C48.9482 163.205 51.4299 162.088 53.6807 161L59.7118 158.136'
            fill='black'
          />
        </g>
        <g opacity='0.2'>
          <path
            d='M40.1757 164.68C40.0995 165.067 40.1044 165.465 40.1902 165.849C40.2761 166.234 40.4409 166.597 40.6745 166.916C40.9081 167.234 41.2055 167.501 41.5479 167.701C41.8903 167.9 42.2705 168.027 42.6646 168.074C43.6888 168.145 44.7068 167.867 45.5503 167.286C46.416 166.764 47.1879 166.105 48.0752 165.618C48.9336 165.082 49.9661 164.892 50.9609 165.088C47.9401 166.786 45.4604 169.292 43.8044 172.32C41.6402 171.038 40.3344 169.406 39.9593 167.1C39.81 166.289 39.8848 165.452 40.1757 164.68Z'
            fill='black'
          />
        </g>
        <path
          d='M81.8159 148.054C83.2155 147.653 83.9008 146.064 84.0235 144.625C84.1461 143.186 83.9152 141.711 84.3048 140.329C84.5862 139.341 85.1777 138.439 85.2571 137.415C85.3364 136.391 84.8026 135.267 84.7737 134.143C84.7304 132.603 85.6683 131.171 85.6899 129.625C85.7016 129.087 85.6011 128.552 85.3947 128.055C85.1883 127.557 84.8805 127.108 84.4907 126.734C84.1008 126.36 83.6373 126.071 83.1293 125.883C82.6212 125.696 82.0796 125.614 81.5384 125.645C80.9973 125.675 80.4682 125.816 79.9845 126.059C79.5009 126.301 79.073 126.641 78.7278 127.056C78.3826 127.47 78.1276 127.951 77.9787 128.469C77.8298 128.986 77.7902 129.528 77.8626 130.061'
          fill='#263238'
        />
        <path
          d='M60.4331 130.606V132.754L60.8659 164.651C60.9164 168.353 64.1628 171.26 68.1595 171.181C72.1922 171.095 75.4747 167.995 75.4819 164.25C75.4819 160.327 75.5829 156.195 75.5829 156.195C75.5829 156.195 80.6761 155.35 81.7366 148.749C82.256 145.47 82.09 140.085 81.8159 135.689C81.7569 134.75 81.5071 133.833 81.0815 132.992C80.6559 132.152 80.0633 131.405 79.3395 130.798C78.6156 130.19 77.7754 129.735 76.8695 129.458C75.9635 129.181 75.0106 129.089 74.0679 129.188L60.4331 130.606Z'
          fill='#D7A38F'
        />
        <path
          d='M80.4885 139.412C80.4905 139.633 80.4056 139.845 80.2519 140.004C80.0983 140.164 79.888 140.257 79.666 140.264C79.5578 140.273 79.4489 140.26 79.3457 140.227C79.2425 140.193 79.147 140.139 79.0651 140.069C78.9831 139.998 78.9161 139.912 78.8682 139.815C78.8203 139.718 78.7923 139.613 78.7859 139.505C78.7839 139.284 78.8685 139.071 79.022 138.911C79.1754 138.75 79.3857 138.655 79.6083 138.646C79.717 138.638 79.8261 138.652 79.9293 138.687C80.0326 138.721 80.1279 138.775 80.2098 138.847C80.2916 138.918 80.3584 139.005 80.4063 139.102C80.4541 139.199 80.4821 139.304 80.4885 139.412Z'
          fill='#263238'
        />
        <path
          d='M80.676 138.467C80.5678 138.575 79.9186 138.102 79.0024 138.102C78.0862 138.102 77.4152 138.56 77.3142 138.453C77.2132 138.345 77.372 138.202 77.6677 137.987C78.0645 137.716 78.5351 137.572 79.0168 137.572C79.496 137.573 79.9629 137.723 80.3514 138.002C80.6328 138.188 80.7265 138.417 80.676 138.467Z'
          fill='#263238'
        />
        <path
          d='M71.2976 139.412C71.3005 139.522 71.2815 139.631 71.2416 139.733C71.2017 139.836 71.1418 139.929 71.0654 140.008C70.9889 140.088 70.8974 140.151 70.7961 140.195C70.6948 140.239 70.5857 140.262 70.4752 140.264C70.3673 140.273 70.2588 140.26 70.156 140.227C70.0532 140.193 69.9583 140.139 69.8769 140.068C69.7954 139.997 69.7292 139.911 69.682 139.814C69.6349 139.718 69.6077 139.613 69.6023 139.505C69.5983 139.285 69.6814 139.072 69.8337 138.911C69.986 138.751 70.1955 138.655 70.4175 138.646C70.5263 138.637 70.6358 138.65 70.7395 138.684C70.8432 138.719 70.9389 138.773 71.021 138.844C71.1031 138.916 71.1699 139.003 71.2174 139.1C71.2649 139.198 71.2922 139.304 71.2976 139.412Z'
          fill='#263238'
        />
        <path
          d='M71.6511 138.632C71.5429 138.746 70.9297 138.274 69.9847 138.274C69.0396 138.274 68.3903 138.732 68.2893 138.618C68.1883 138.503 68.347 138.374 68.6428 138.159C69.0404 137.884 69.5145 137.739 69.9991 137.744C70.4761 137.745 70.9406 137.895 71.3265 138.174C71.6079 138.388 71.7016 138.589 71.6511 138.632Z'
          fill='#263238'
        />
        <path
          d='M74.9841 145.391C75.4662 145.241 75.9656 145.152 76.4703 145.126C76.7083 145.126 76.932 145.054 76.968 144.897C76.994 144.647 76.9383 144.396 76.8093 144.181C76.5929 143.622 76.362 143.028 76.0879 142.412C75.4437 140.913 74.9249 139.364 74.5369 137.78C75.2985 139.225 75.9426 140.729 76.463 142.276L77.1267 144.059C77.2686 144.343 77.3117 144.665 77.2494 144.976C77.2134 145.057 77.1599 145.129 77.0925 145.187C77.0252 145.245 76.9457 145.288 76.8598 145.312C76.7303 145.347 76.5971 145.366 76.463 145.369C75.9729 145.438 75.4761 145.445 74.9841 145.391Z'
          fill='#263238'
        />
        <path
          d='M75.619 156.174C72.4859 156.221 69.4014 155.403 66.7095 153.811C66.7095 153.811 68.8737 158.343 75.5252 157.728L75.619 156.174Z'
          fill='#85503B'
        />
        <path
          d='M74.5945 147.589C74.4194 147.37 74.1913 147.199 73.9314 147.091C73.6715 146.983 73.3883 146.943 73.1084 146.973C72.9064 146.993 72.7103 147.052 72.5319 147.148C72.3534 147.244 72.1962 147.374 72.0696 147.532C71.9457 147.684 71.8723 147.87 71.8594 148.065C71.8464 148.259 71.8947 148.454 71.9974 148.62C72.1458 148.783 72.3394 148.898 72.5538 148.952C72.7683 149.005 72.9939 148.995 73.2022 148.921C73.6366 148.763 74.0342 148.52 74.3709 148.205C74.4756 148.131 74.5661 148.039 74.6378 147.933C74.6716 147.883 74.6897 147.824 74.6897 147.764C74.6897 147.704 74.6716 147.646 74.6378 147.596'
          fill='#85503B'
        />
        <path
          d='M72.2788 146.021C72.4303 146.021 72.4303 147.002 73.2888 147.704C74.1473 148.405 75.2005 148.298 75.2077 148.42C75.215 148.541 74.9697 148.613 74.5224 148.627C73.939 148.634 73.372 148.436 72.9209 148.069C72.482 147.711 72.2021 147.197 72.1417 146.637C72.1417 146.257 72.2139 146.014 72.2788 146.021Z'
          fill='#263238'
        />
        <path
          d='M71.8892 135.066C71.7954 135.31 70.8792 135.195 69.8043 135.324C68.7294 135.453 67.8565 135.746 67.7122 135.524C67.6472 135.417 67.7987 135.181 68.1522 134.944C68.6177 134.643 69.15 134.459 69.7033 134.407C70.2539 134.34 70.813 134.403 71.3337 134.594C71.7305 134.751 71.9252 134.944 71.8892 135.066Z'
          fill='#263238'
        />
        <path
          d='M80.337 135.825C80.1783 136.033 79.5434 135.825 78.7787 135.825C78.014 135.825 77.3792 135.961 77.2277 135.746C77.1627 135.646 77.2277 135.439 77.5451 135.238C77.9209 134.999 78.3619 134.882 78.8076 134.902C79.266 134.9 79.7121 135.049 80.0773 135.324C80.3225 135.517 80.4091 135.725 80.337 135.825Z'
          fill='#263238'
        />
        <path
          d='M66.9547 125.715C67.1742 127.687 67.7561 129.601 68.6717 131.364C69.1388 132.241 69.8002 133.002 70.6062 133.589C71.4122 134.175 72.3417 134.573 73.3249 134.751C74.8615 134.952 76.3909 134.429 77.8554 133.921L82.0901 132.438C82.9392 132.195 83.7414 131.813 84.4635 131.307C85.152 130.697 85.6528 129.906 85.908 129.026C86.1633 128.145 86.1627 127.211 85.9064 126.331C85.5218 125.11 84.8211 124.01 83.8741 123.142C82.9272 122.274 81.7672 121.667 80.5102 121.383C77.9747 120.85 75.3329 121.151 72.9858 122.243C70.667 123.33 68.4873 124.688 66.493 126.288'
          fill='#263238'
        />
        <path
          d='M68.9315 125.83L67.6978 125.608C67.1642 124.814 66.4133 124.188 65.5336 123.804C65.675 123.606 65.7958 123.395 65.8943 123.173C66.2311 122.274 66.2079 121.281 65.8295 120.398C65.4511 119.515 64.7463 118.809 63.8599 118.426C63.258 118.278 62.6834 118.036 62.1574 117.71C61.5297 117.223 61.3349 116.364 60.8227 115.763C60.0292 114.839 58.6585 114.689 57.4321 114.61C56.6112 114.515 55.7803 114.554 54.972 114.725C54.5659 114.81 54.1868 114.992 53.8675 115.255C53.5482 115.518 53.2984 115.855 53.1396 116.235C52.8511 117.044 53.1396 118.011 52.7284 118.77C52.3172 119.529 51.4876 119.787 50.8672 120.309C49.4243 121.519 49.4965 123.811 50.3766 125.45C50.9647 126.513 51.8199 127.406 52.8591 128.044C53.8983 128.681 55.086 129.041 56.3067 129.088C56.5741 129.094 56.8416 129.082 57.1074 129.052C56.9272 129.53 56.6846 129.982 56.386 130.398C55.627 131.155 54.8013 131.842 53.9188 132.453C53.2745 133.024 52.759 133.724 52.4061 134.507C52.0531 135.29 51.8707 136.138 51.8707 136.996C51.8707 137.853 52.0531 138.701 52.4061 139.484C52.759 140.267 53.2745 140.967 53.9188 141.539C54.6627 142.047 55.3306 142.657 55.9027 143.35C56.4149 144.145 56.4726 145.133 56.7756 146.021C57.0237 146.734 57.4329 147.382 57.9719 147.914C58.5108 148.446 59.1653 148.848 59.8851 149.091C60.6049 149.333 61.3711 149.409 62.1251 149.313C62.879 149.216 63.6007 148.95 64.235 148.534C64.7228 148.248 65.1258 147.839 65.4032 147.349C65.6806 146.858 65.8226 146.304 65.8149 145.742C65.6851 144.453 64.4947 143.465 64.3721 142.162C64.2495 140.859 65.0935 139.756 65.0935 138.503C65.043 137.071 63.7661 135.782 64.0475 134.372C64.3288 132.961 65.8727 132.353 66.9692 131.472C67.7815 130.797 68.3932 129.915 68.7389 128.921C69.0846 127.927 69.1512 126.858 68.9315 125.83Z'
          fill='#263238'
        />
        <path
          d='M63.5785 124.677C63.5422 124.994 63.4256 125.297 63.2394 125.558C62.8454 126.213 62.3199 126.781 61.6956 127.226C61.0748 127.681 60.3644 128 59.6107 128.164C59.0408 128.279 58.6729 128.257 58.6656 128.207C59.6599 127.914 60.6072 127.483 61.4792 126.925C62.2768 126.272 62.9828 125.516 63.5785 124.677Z'
          fill='#407BFF'
        />
        <path
          d='M54.0125 155.107C54.1423 155.316 54.2844 155.516 54.4381 155.708C54.8933 156.194 55.5232 156.483 56.1912 156.51C56.6773 156.518 57.157 156.4 57.5824 156.166C58.0078 155.932 58.3639 155.592 58.6151 155.179C58.9331 154.702 59.1333 154.157 59.1995 153.589C59.2558 153.301 59.2405 153.004 59.155 152.723C59.0695 152.443 58.9164 152.187 58.7089 151.978C58.5122 151.8 58.2535 151.705 57.9875 151.713C57.8584 151.729 57.7347 151.774 57.6259 151.845C57.5171 151.916 57.4261 152.01 57.3598 152.121C57.0785 152.572 57.5474 153.102 58.038 153.145C58.2868 153.15 58.5339 153.104 58.7636 153.009C58.9934 152.914 59.2008 152.773 59.3726 152.594C59.7047 152.284 59.9634 151.905 60.1301 151.484C60.3732 150.864 60.4235 150.185 60.2744 149.537C60.1662 149.078 60.0291 148.821 60.058 148.821C60.2347 149.007 60.3591 149.237 60.4187 149.486C60.6324 150.167 60.6172 150.899 60.3754 151.57C60.2137 152.038 59.9445 152.462 59.589 152.809C59.388 153.028 59.1412 153.201 58.8659 153.316C58.5905 153.431 58.2932 153.485 57.9947 153.475C57.8238 153.458 57.658 153.408 57.5078 153.325C57.3576 153.243 57.2262 153.13 57.1218 152.995C57.0043 152.847 56.9334 152.669 56.918 152.482C56.9026 152.294 56.9434 152.107 57.0352 151.942C57.1283 151.777 57.259 151.637 57.4169 151.531C57.5747 151.425 57.7553 151.358 57.9442 151.334C58.1339 151.32 58.3245 151.344 58.5044 151.406C58.6842 151.467 58.8496 151.565 58.9903 151.692C59.2449 151.939 59.4341 152.245 59.541 152.582C59.648 152.919 59.6694 153.277 59.6035 153.625C59.5241 154.249 59.2966 154.845 58.9398 155.365C58.6555 155.823 58.2527 156.198 57.7731 156.45C57.2936 156.702 56.7548 156.822 56.2128 156.797C55.8539 156.77 55.5045 156.67 55.1866 156.502C54.8687 156.334 54.5893 156.103 54.366 155.823C54.2435 155.674 54.1483 155.504 54.0846 155.322C54.0125 155.179 53.998 155.114 54.0125 155.107Z'
          fill='#263238'
        />
        <path
          d='M53.3848 148.212C53.4794 148.533 53.6229 148.837 53.8105 149.114C54.0299 149.389 54.3186 149.601 54.6473 149.73C55.0543 149.876 55.4949 149.903 55.917 149.809C56.7082 149.617 57.4148 149.175 57.9298 148.548C58.3482 148.076 58.5718 147.739 58.6512 147.768C58.5701 148.119 58.4014 148.444 58.1606 148.713C57.6478 149.445 56.8744 149.957 55.9964 150.145C55.5135 150.25 55.0098 150.205 54.5535 150.016C54.1755 149.851 53.8545 149.58 53.6301 149.236C53.2333 148.642 53.3848 148.169 53.3848 148.212Z'
          fill='#263238'
        />
        <path
          d='M67.9936 125.436C67.9503 125.436 67.9359 125.329 67.9575 125.221L68.1667 125.379C68.0639 125.52 67.9125 125.619 67.7411 125.658C67.3299 125.758 67.0197 125.5 67.1063 125.415C67.1928 125.329 67.3948 125.364 67.6545 125.293C67.7678 125.266 67.8787 125.23 67.9864 125.185L68.5924 124.949L68.1956 125.343C68.109 125.422 68.0369 125.457 67.9936 125.436Z'
          fill='#263238'
        />
        <path
          d='M72.9569 115.505C72.9569 115.505 73.0218 115.555 73.1228 115.684C73.2524 115.875 73.3566 116.082 73.433 116.3C73.6158 116.81 73.6611 117.358 73.5642 117.89C73.4674 118.423 73.2318 118.921 72.881 119.335C72.5301 119.749 72.0762 120.065 71.5643 120.251C71.0524 120.437 70.5003 120.487 69.963 120.395C69.5668 120.359 69.1858 120.226 68.8546 120.007C68.5233 119.788 68.2522 119.491 68.0657 119.142C67.9776 118.934 67.9485 118.706 67.9816 118.483C68.0146 118.259 68.1086 118.049 68.2532 117.875C68.3936 117.697 68.5731 117.552 68.7781 117.453C68.9831 117.354 69.2083 117.302 69.4364 117.302C69.8753 117.333 70.2897 117.515 70.6093 117.815C70.9289 118.115 71.1339 118.516 71.1894 118.949C71.2826 119.723 71.0763 120.504 70.6123 121.133C70.2134 121.662 69.7534 122.142 69.2416 122.565C68.8055 122.953 68.3401 123.307 67.8492 123.625C67.1448 124.094 66.3672 124.444 65.5479 124.663C65.2528 124.759 64.9421 124.801 64.6317 124.784C65.7319 124.512 66.7731 124.044 67.705 123.403C68.1712 123.077 68.6147 122.721 69.0324 122.336C69.5191 121.912 69.9527 121.431 70.3237 120.904C70.7309 120.345 70.9112 119.655 70.8287 118.971C70.7829 118.625 70.6202 118.305 70.3672 118.063C70.1143 117.821 69.7861 117.671 69.4364 117.639C68.7149 117.639 68.109 118.355 68.4047 118.963C68.5654 119.256 68.7961 119.505 69.0767 119.689C69.3572 119.873 69.6789 119.985 70.0135 120.016C70.5968 120.113 71.1961 120.033 71.7329 119.787C72.2697 119.54 72.719 119.138 73.0218 118.634C73.4095 117.921 73.5051 117.088 73.2887 116.307C73.1948 116.033 73.084 115.765 72.9569 115.505Z'
          fill='#263238'
        />
        <path
          d='M46.9426 133.312C47.1881 133.378 47.429 133.459 47.664 133.555C48.3241 133.781 49.0241 133.869 49.7201 133.813C50.2233 133.774 50.7106 133.621 51.1446 133.365C51.5787 133.109 51.9477 132.758 52.2234 132.338C52.3815 132.1 52.4886 131.832 52.5383 131.552C52.5879 131.271 52.5789 130.983 52.512 130.706C52.475 130.574 52.4084 130.452 52.3172 130.349C52.226 130.246 52.1126 130.165 51.9853 130.111C51.8681 130.057 51.7377 130.038 51.6097 130.056C51.4817 130.074 51.3617 130.128 51.2639 130.212C51.1834 130.311 51.1309 130.429 51.1119 130.555C51.0929 130.681 51.108 130.81 51.1557 130.928C51.2633 131.192 51.4702 131.406 51.7328 131.522C52.2682 131.77 52.8651 131.857 53.4498 131.773C54.4651 131.606 55.4071 131.142 56.1551 130.441C56.6717 129.967 57.1539 129.457 57.5979 128.916C57.9226 128.529 58.1102 128.321 58.1318 128.343C58.0243 128.581 57.8835 128.803 57.7134 129.002C57.3064 129.589 56.8425 130.136 56.3283 130.634C55.5587 131.408 54.565 131.923 53.4859 132.109C52.8293 132.218 52.1548 132.125 51.5525 131.844C51.2097 131.685 50.9395 131.404 50.795 131.057C50.7166 130.868 50.6933 130.661 50.7277 130.46C50.7622 130.258 50.853 130.071 50.9898 129.918C51.1424 129.778 51.3338 129.686 51.5394 129.654C51.745 129.622 51.9555 129.652 52.144 129.739C52.3196 129.815 52.4756 129.929 52.6005 130.072C52.7255 130.216 52.816 130.386 52.8655 130.57C52.9486 130.896 52.9607 131.235 52.901 131.566C52.8413 131.897 52.7111 132.212 52.5192 132.489C52.2131 132.946 51.8029 133.326 51.3217 133.597C50.8404 133.868 50.3017 134.023 49.7489 134.049C49.019 134.085 48.2908 133.952 47.6207 133.663C47.3762 133.585 47.1469 133.466 46.9426 133.312Z'
          fill='#263238'
        />
        <path
          d='M58.5285 127.226C58.5285 127.226 58.2183 127.226 57.6628 127.29C56.8807 127.381 56.1049 127.52 55.3399 127.706C54.1815 128.006 52.9729 128.064 51.7905 127.878C50.3147 127.626 48.9841 126.844 48.0536 125.679C47.604 125.084 47.2937 124.396 47.1452 123.667C46.9967 122.937 47.0137 122.184 47.1951 121.462C47.35 120.874 47.6414 120.33 48.046 119.874C48.4506 119.418 48.9572 119.062 49.5253 118.834C50.2765 118.506 51.1159 118.433 51.9132 118.627C52.1284 118.678 52.335 118.76 52.5264 118.87C52.6562 118.942 52.7211 118.985 52.7139 118.999C52.7067 119.013 52.4254 118.87 51.8843 118.77C51.1207 118.641 50.3355 118.746 49.6335 119.071C49.1164 119.298 48.6585 119.639 48.2946 120.068C47.9308 120.497 47.6707 121.004 47.5341 121.548C47.3765 122.219 47.3684 122.915 47.5104 123.589C47.6525 124.264 47.9413 124.899 48.3566 125.45C49.2271 126.547 50.4714 127.291 51.8554 127.541C52.9953 127.736 54.163 127.702 55.2894 127.441C56.0689 127.28 56.8602 127.182 57.6556 127.147C57.949 127.121 58.2448 127.147 58.5285 127.226Z'
          fill='#263238'
        />
        <path
          d='M63.8887 123.567C64.5217 123.375 65.0984 123.033 65.5696 122.572C66.1196 122.014 66.4417 121.273 66.4737 120.493C66.5057 119.713 66.2453 118.948 65.7428 118.348C65.216 117.665 64.4616 117.19 63.6146 117.009C62.9164 116.869 62.1938 116.908 61.5153 117.123C60.891 117.356 60.3014 117.672 59.7622 118.061C59.7622 118.061 59.8632 117.882 60.1301 117.646C60.5108 117.31 60.9512 117.048 61.4287 116.873C62.1465 116.603 62.9249 116.534 63.6795 116.672C64.6168 116.845 65.4569 117.355 66.0385 118.104C66.3338 118.473 66.5528 118.897 66.6828 119.35C66.8128 119.804 66.8512 120.278 66.796 120.746C66.6841 121.508 66.3184 122.212 65.7572 122.744C65.3901 123.105 64.9355 123.367 64.437 123.503C64.2623 123.568 64.074 123.59 63.8887 123.567Z'
          fill='#263238'
        />
        <path
          d='M83.9585 138.252C83.9152 138.202 84.4202 137.794 85.1056 137.035C85.5031 136.606 85.8054 136.098 85.9929 135.546C86.1678 134.859 86.1199 134.135 85.8558 133.477C85.6106 132.761 85.2499 132.088 85.019 131.429C84.7792 130.844 84.6827 130.211 84.7377 129.582C84.7754 129.156 84.9329 128.749 85.1921 128.407C85.3869 128.171 85.5312 128.092 85.5456 128.107C85.23 128.547 85.0428 129.064 85.0046 129.603C84.9924 130.189 85.1081 130.77 85.3436 131.307C85.5817 131.93 85.9496 132.589 86.2165 133.362C86.5032 134.091 86.5412 134.894 86.3248 135.646C86.1085 136.24 85.758 136.776 85.3004 137.214C84.9177 137.636 84.4636 137.987 83.9585 138.252Z'
          fill='#263238'
        />
        <path
          d='M124.668 198.017L99.7288 197.688L88.6694 169.126C86.1084 164.83 81.7005 162.833 75.6406 162.109L73.7577 163.541C72.2819 164.645 70.4965 165.266 68.6493 165.317C66.802 165.369 64.9844 164.848 63.4487 163.828L60.8949 162.131C60.8949 162.131 53.9548 162.496 48.8616 166.62H48.7967C46.2389 168.655 44.3748 171.423 43.4582 174.546C42.4627 177.797 39.5914 188.809 37.4777 197C36.8103 199.577 37.1544 202.309 38.4403 204.643C39.7261 206.978 41.8576 208.74 44.4033 209.573L51.2856 211.836L47.9022 222.017L89.0662 221.473L83.3598 198.454L84.0379 196.771C85.0984 198.919 86.7721 202.177 87.8398 204.282C88.8575 206.276 90.4407 207.931 92.3942 209.042C94.3477 210.154 96.5863 210.675 98.8342 210.54L125.729 208.17L124.668 198.017ZM51.2279 198.676L53.4426 190.542C54.33 193.52 55.9315 198.941 55.6935 198.547L55.1163 200.294L51.2279 198.676Z'
          fill='#407BFF'
        />
        <path
          d='M71.5791 215.802C73.3552 217.007 75.195 218.116 77.0907 219.125C79.5146 220.263 79.6229 220.055 79.7166 219.755C79.9691 218.946 75.5468 217.607 76.6795 217.335C77.8121 217.063 81.8809 219.619 82.1117 218.767C82.4075 217.657 77.7832 216.318 77.3576 216.175C76.932 216.031 77.1845 215.459 77.5235 215.623C78.0141 215.838 83.2155 217.929 83.5113 216.841C83.6844 216.232 82.5518 216.06 82.5518 216.06C82.5518 216.06 77.5019 214.456 77.6895 213.948C77.877 213.44 80.8348 214.277 81.816 214.628C82.7971 214.979 83.367 214.671 83.1722 214.113C83.0424 213.74 80.0629 212.588 79.0818 212.258C78.1007 211.929 75.3881 211.077 77.0979 210.432C78.8076 209.788 79.8826 208.664 79.9836 208.17C80.0846 207.676 79.392 207.397 78.988 207.797C78.5111 208.182 77.9846 208.503 77.4225 208.75C76.9686 208.968 76.4808 209.108 75.9797 209.165C75.5541 209.222 75.1789 209.251 74.8399 209.272L71.2328 209.545L69.4076 214.306L71.5791 215.802Z'
          fill='#A3705D'
        />
        <path
          d='M44.1436 209.459H44.259L44.5908 209.559L45.875 209.981L50.7229 211.621L67.9215 217.549L67.7122 217.642C68.9819 214.456 70.3887 210.941 71.8604 207.239L71.9614 207.468L61.3205 203.086L51.1269 198.847L50.9826 198.783L51.0259 198.626C52.3966 193.563 53.4426 188.759 54.431 184.456C54.6762 183.375 54.9143 182.308 55.1524 181.313C55.4011 180.346 55.5938 179.366 55.7295 178.377C55.9166 176.61 55.7897 174.824 55.3544 173.1C54.9926 171.693 54.2432 170.414 53.1901 169.406C52.385 168.666 51.3839 168.169 50.3045 167.974C49.6294 167.855 48.9372 167.875 48.2701 168.031C47.8228 168.145 47.5991 168.26 47.5919 168.238C47.8023 168.124 48.0249 168.032 48.2556 167.966C48.9288 167.777 49.6359 167.738 50.3261 167.852C51.4382 168.032 52.4734 168.53 53.3055 169.284C54.3993 170.304 55.1818 171.609 55.5636 173.05C56.0266 174.801 56.1731 176.619 55.9964 178.42C55.8621 179.42 55.6743 180.412 55.4337 181.391L54.7123 184.535C53.7384 188.831 52.714 193.656 51.3505 198.74L51.2423 198.525L61.4359 202.757L72.0407 207.182L72.2067 207.246L72.1417 207.411C70.6556 211.113 69.2561 214.621 67.9575 217.807L67.8998 217.95L67.7555 217.9L50.6002 211.85L45.7812 210.117L44.5115 209.652L44.194 209.523L44.1436 209.459Z'
          fill='#263238'
        />
        <path
          d='M82.1478 174.246C82.2414 175.423 82.459 176.586 82.797 177.718C83.1217 178.764 83.6195 179.974 84.1822 181.298C84.8315 182.751 85.3342 184.265 85.6827 185.816C85.9717 187.395 85.9717 189.013 85.6827 190.592C85.4578 191.87 85.1272 193.127 84.6944 194.351C84.3336 195.396 83.9729 196.227 83.7637 196.793C83.6533 197.095 83.5157 197.388 83.3525 197.666C83.4073 197.349 83.4993 197.039 83.6267 196.742C83.8214 196.162 84.1172 195.31 84.4419 194.272C84.8327 193.05 85.1341 191.8 85.3436 190.535C85.6139 188.997 85.6139 187.425 85.3436 185.888C85.0029 184.36 84.5199 182.867 83.9008 181.427C83.3956 180.233 82.9573 179.011 82.5878 177.768C82.3397 176.927 82.192 176.059 82.1478 175.184C82.1083 174.872 82.1083 174.557 82.1478 174.246Z'
          fill='#263238'
        />
        <path
          d='M99.7287 197.688C99.3306 198.413 98.8329 199.081 98.2498 199.671C97.7376 200.317 97.1652 200.914 96.54 201.454C96.4246 201.375 97.0739 200.458 97.9829 199.427C98.4711 198.762 99.06 198.175 99.7287 197.688Z'
          fill='#263238'
        />
        <path
          d='M51.2207 198.676C50.4415 198.773 49.6764 198.961 48.941 199.234C48.4469 199.453 48.0064 199.775 47.6497 200.179C47.3827 200.501 47.2745 200.745 47.2168 200.731C47.1591 200.716 47.2168 200.43 47.4332 200.015C47.8502 199.433 48.4364 198.991 49.1134 198.749C49.7904 198.507 50.5257 198.477 51.2207 198.661V198.676Z'
          fill='#263238'
        />
        <path
          d='M54.2289 163.606C54.2289 163.67 53.3632 164.05 51.9998 164.795C50.2157 165.746 48.5682 166.931 47.1013 168.317C45.6497 169.713 44.5179 171.403 43.7828 173.272C43.2345 174.704 43.0975 175.671 43.0253 175.656C43.017 175.43 43.0364 175.204 43.083 174.983C43.1793 174.37 43.329 173.766 43.5303 173.179C44.2156 171.239 45.3469 169.483 46.8344 168.052C48.3263 166.636 50.0297 165.458 51.8843 164.558C52.6057 164.222 53.1684 163.964 53.5869 163.842C53.7861 163.729 54.0031 163.649 54.2289 163.606Z'
          fill='#263238'
        />
        <path
          d='M66.9908 168.826C66.5209 168.952 66.0298 168.982 65.548 168.912C64.347 168.799 63.1863 168.422 62.1501 167.809C61.1006 167.212 60.2005 166.387 59.5169 165.396C59.2138 165.004 58.9975 164.552 58.8821 164.071C58.9398 164.071 59.1923 164.544 59.7334 165.238C61.1546 167.08 63.2378 168.307 65.548 168.661C66.4281 168.783 66.9908 168.761 66.9908 168.826Z'
          fill='#263238'
        />
        <path
          d='M77.1411 163.642C76.7797 164.506 76.231 165.28 75.534 165.91C74.8371 166.54 74.0089 167.01 73.1084 167.286C73.0651 167.186 74.1761 166.649 75.2727 165.632C76.3692 164.616 77.0473 163.584 77.1411 163.642Z'
          fill='#263238'
        />
        <path
          opacity='0.2'
          d='M55.5491 180.267C55.5491 180.267 58.9326 195.096 58.8749 196.542C58.6706 198.2 58.3451 199.842 57.9009 201.454L55.1091 200.294L55.6862 198.547L53.3199 190.155L55.5491 180.267Z'
          fill='black'
        />
        <g opacity='0.2'>
          <path
            d='M85.964 189.024L89.7298 201.826C90.1111 203.454 90.7725 205.004 91.6849 206.408C93.6904 209.115 99.6205 209.523 102.953 210.189L98.0838 210.626C96.0754 210.59 94.1095 210.045 92.3733 209.042C90.637 208.04 89.1881 206.613 88.1643 204.898L84.0378 196.75L85.964 189.024Z'
            fill='black'
          />
        </g>
        <path
          d='M70.4825 174.833C70.2547 173.723 70.1507 172.592 70.1723 171.46C70.064 170.331 70.0834 169.192 70.23 168.067C70.4573 169.179 70.5637 170.312 70.5474 171.446C70.6517 172.574 70.6299 173.71 70.4825 174.833Z'
          fill='#FAFAFA'
        />
        <path
          d='M73.2238 173.587C72.6278 172.712 72.088 171.801 71.6078 170.859C71.0236 169.976 70.5006 169.055 70.0424 168.102C71.49 169.681 72.5754 171.552 73.2238 173.587Z'
          fill='#FAFAFA'
        />
        <path
          d='M70.0712 188.308C69.2871 187.784 68.5421 187.205 67.8421 186.575L67.7555 186.496L67.8348 186.411L67.8781 186.368C68.5068 185.64 69.1918 184.963 69.927 184.341C69.3542 185.103 68.7248 185.821 68.0441 186.489L68.0008 186.539V186.375C68.7428 186.962 69.4352 187.609 70.0712 188.308Z'
          fill='white'
        />
        <path
          d='M73.9885 184.27C74.755 184.813 75.4785 185.414 76.1528 186.067L76.2393 186.138L76.16 186.224L76.1167 186.267C75.4902 186.997 74.805 187.674 74.0679 188.294C74.6406 187.532 75.27 186.814 75.9508 186.146L75.9941 186.103V186.26C75.2754 185.648 74.6049 184.983 73.9885 184.27Z'
          fill='white'
        />
        <path
          d='M70.6556 189.103C71.3871 187.166 72.2649 185.287 73.2816 183.482C72.9446 184.463 72.5396 185.42 72.0696 186.346C71.6579 187.294 71.1856 188.215 70.6556 189.103Z'
          fill='white'
        />
        <path
          d='M79.4424 190.864C79.4424 190.864 79.4929 190.864 79.5506 190.807C79.6084 190.75 79.6084 190.628 79.6011 190.456V188.967C79.6011 187.635 79.6011 185.773 79.5579 183.496C79.5579 182.916 79.5579 182.315 79.5579 181.685V180.475C79.5624 180.459 79.5604 180.442 79.5523 180.427C79.5442 180.412 79.5307 180.401 79.5146 180.396H79.0457H74.6811H64.675C64.675 180.396 64.6245 180.396 64.6317 180.468V181.449C64.6317 181.885 64.6317 182.315 64.6317 182.745V185.279C64.6317 186.94 64.6317 188.544 64.6317 190.069V190.642C64.6295 190.656 64.6327 190.671 64.6407 190.683C64.6488 190.695 64.661 190.703 64.675 190.707H68.1667H71.9758L77.4874 190.757H78.9807H79.3703C79.4569 190.757 79.5074 190.757 79.5074 190.757H79.3703H78.9807H77.4874L71.9758 190.8H68.1667H64.639C64.5593 190.789 64.4864 190.75 64.4345 190.689C64.3825 190.628 64.3551 190.55 64.3576 190.47V189.897C64.3576 188.372 64.3576 186.769 64.3576 185.107V180.296C64.3547 180.249 64.3614 180.201 64.3774 180.157C64.3934 180.112 64.4183 180.071 64.4507 180.036C64.483 180.002 64.5221 179.974 64.5657 179.954C64.6092 179.935 64.6562 179.924 64.7039 179.924H74.7099H79.0745C79.257 179.91 79.4403 179.91 79.6228 179.924C79.6867 179.945 79.7417 179.987 79.7794 180.043C79.8172 180.098 79.8356 180.165 79.832 180.231V180.475C79.832 180.804 79.832 181.126 79.832 181.442C79.832 182.072 79.832 182.673 79.832 183.253C79.832 185.53 79.7959 187.391 79.7815 188.723V190.213C79.7815 190.37 79.7815 190.528 79.6877 190.585C79.5939 190.642 79.4424 190.864 79.4424 190.864Z'
          fill='white'
        />
        <path
          d='M79.6301 181.9C79.6301 181.957 76.225 182.007 72.0336 182.007C67.8421 182.007 64.4298 181.957 64.4298 181.9C64.4298 181.842 67.8277 181.785 72.0336 181.785C76.2394 181.785 79.6301 181.864 79.6301 181.9Z'
          fill='white'
        />
        <path
          d='M65.6483 181.148C65.6483 181.192 65.6308 181.234 65.5998 181.264C65.5687 181.295 65.5266 181.313 65.4827 181.313C65.4586 181.318 65.4336 181.317 65.4096 181.312C65.3856 181.306 65.3632 181.295 65.3441 181.279C65.3249 181.264 65.3094 181.245 65.2988 181.222C65.2882 181.2 65.2827 181.176 65.2827 181.152C65.2827 181.127 65.2882 181.103 65.2988 181.081C65.3094 181.059 65.3249 181.039 65.3441 181.024C65.3632 181.008 65.3856 180.997 65.4096 180.991C65.4336 180.986 65.4586 180.985 65.4827 180.99C65.5041 180.989 65.5255 180.993 65.5455 181C65.5656 181.008 65.5839 181.019 65.5994 181.034C65.6149 181.049 65.6272 181.066 65.6356 181.086C65.644 181.106 65.6483 181.127 65.6483 181.148Z'
          fill='white'
        />
        <path
          d='M66.2904 181.148C66.2904 181.192 66.2729 181.234 66.2419 181.264C66.2108 181.295 66.1687 181.313 66.1248 181.313C66.1006 181.318 66.0757 181.317 66.0517 181.312C66.0277 181.306 66.0053 181.295 65.9862 181.279C65.967 181.264 65.9515 181.245 65.9409 181.222C65.9303 181.2 65.9248 181.176 65.9248 181.152C65.9248 181.127 65.9303 181.103 65.9409 181.081C65.9515 181.059 65.967 181.039 65.9862 181.024C66.0053 181.008 66.0277 180.997 66.0517 180.991C66.0757 180.986 66.1006 180.985 66.1248 180.99C66.1675 180.99 66.2085 181.007 66.2394 181.036C66.2703 181.065 66.2885 181.106 66.2904 181.148Z'
          fill='white'
        />
        <path
          d='M66.9397 181.148C66.9411 181.181 66.9325 181.214 66.9148 181.242C66.8972 181.27 66.8714 181.292 66.8409 181.306C66.8103 181.319 66.7765 181.323 66.7437 181.317C66.7109 181.311 66.6807 181.295 66.6571 181.271C66.6336 181.248 66.6176 181.218 66.6115 181.185C66.6053 181.153 66.6092 181.119 66.6226 181.089C66.6361 181.058 66.6584 181.033 66.6867 181.015C66.715 180.998 66.748 180.989 66.7813 180.99C66.8233 180.99 66.8636 181.007 66.8933 181.037C66.923 181.066 66.9397 181.106 66.9397 181.148Z'
          fill='white'
        />
        <path
          d='M125.613 207.132L126.335 207.21C128.958 207.502 131.595 207.655 134.234 207.669C137.517 207.525 137.517 207.232 137.444 206.852C137.25 205.836 131.673 206.852 132.755 205.965C133.837 205.077 139.71 205.47 139.464 204.418C139.14 203.05 133.318 204.253 132.82 204.318C132.322 204.382 132.221 203.666 132.676 203.602C133.332 203.552 140.171 202.886 139.89 201.497C139.717 200.781 138.397 201.21 138.397 201.21C138.397 201.21 132.034 202.334 131.904 201.69C131.774 201.046 135.511 200.258 136.766 200.079C138.022 199.9 138.462 199.234 137.935 198.747C137.581 198.411 133.679 198.862 132.431 199.069C131.183 199.277 127.749 199.893 129.22 198.217C130.692 196.542 131.211 194.723 131.009 194.136C130.935 194.023 130.831 193.932 130.707 193.875C130.584 193.818 130.446 193.797 130.311 193.814C130.176 193.832 130.049 193.886 129.944 193.973C129.839 194.059 129.761 194.172 129.718 194.301C129.422 194.992 129.037 195.642 128.571 196.234C128.213 196.722 127.777 197.147 127.28 197.494C126.847 197.788 126.457 198.038 126.097 198.253L124.805 199.098L125.613 207.132Z'
          fill='#A3705D'
        />
        <path
          d='M68.9675 221.401C68.9675 221.401 65.9592 239.144 74.9842 244.156C84.0091 249.168 103.011 254.738 103.011 254.738L100.926 318.749H116.603L122.287 249.419C122.384 248.244 122.205 247.064 121.763 245.97C121.321 244.877 120.629 243.9 119.741 243.118L89.6361 221.43L68.9675 221.401Z'
          fill='#263238'
        />
        <path
          d='M48.1041 221.401C48.1041 221.401 44.3023 237.54 53.3272 242.523C62.3521 247.507 78.8293 253.979 78.8293 253.979L78.4614 318.706H96.5834L100.457 249.376C100.554 248.201 100.375 247.021 99.9327 245.927C99.4907 244.834 98.7985 243.857 97.9108 243.075L72.0336 221.387L48.1041 221.401Z'
          fill='#263238'
        />
        <path
          d='M99.7287 263.76C99.7081 263.574 99.7081 263.387 99.7287 263.202C99.7287 262.808 99.7865 262.278 99.8297 261.619C99.9235 260.244 100.075 258.247 100.19 255.784C100.362 252.909 100.319 250.025 100.061 247.156C99.9636 245.471 99.4049 243.845 98.4446 242.452C97.3421 240.997 96.095 239.656 94.7221 238.449C89.059 233.437 83.5618 229.349 79.6301 226.335L74.9697 222.755L73.7144 221.759C73.5654 221.649 73.4254 221.527 73.296 221.394C73.4627 221.48 73.6198 221.584 73.7649 221.702L75.0563 222.64L79.7888 226.12C83.7638 229.084 89.2826 233.158 94.9818 238.17C96.3725 239.396 97.6343 240.758 98.7476 242.237C99.2885 242.952 99.6897 243.76 99.9307 244.621C100.157 245.435 100.317 246.266 100.407 247.106C100.65 249.995 100.667 252.899 100.457 255.791C100.306 258.261 100.111 260.252 99.974 261.626L99.8009 263.202C99.7878 263.389 99.7637 263.575 99.7287 263.76Z'
          fill='#455A64'
        />
        <path
          d='M61.4287 221.373C61.4393 221.555 61.4148 221.737 61.3566 221.91C61.2373 222.389 61.063 222.852 60.8372 223.292C60.1035 224.783 58.8831 225.984 57.3744 226.7C55.8486 227.391 54.1196 227.498 52.5192 227.001C52.0412 226.854 51.5833 226.65 51.1557 226.392C50.86 226.206 50.7157 226.077 50.7301 226.055C51.3358 226.33 51.9573 226.569 52.5914 226.771C54.1321 227.17 55.7638 227.034 57.2156 226.385C58.6501 225.692 59.8343 224.577 60.6063 223.191C61.2051 222.11 61.3638 221.351 61.4287 221.373Z'
          fill='#455A64'
        />
        <path
          d='M83.1866 247.951C82.0279 250.098 80.6551 252.125 79.089 254.001C80.2409 251.849 81.6141 249.822 83.1866 247.951Z'
          fill='#455A64'
        />
        <path
          d='M84.817 251.316C84 251.94 83.1083 252.462 82.1622 252.87C81.273 253.388 80.3233 253.797 79.3342 254.087C80.1546 253.469 81.0458 252.95 81.989 252.54C82.8789 252.021 83.8284 251.61 84.817 251.316Z'
          fill='#455A64'
        />
        <path
          d='M109.352 250.299C109.352 250.299 109.352 250.22 109.432 250.106L109.518 250.185C108.773 250.987 107.983 251.747 107.152 252.462C106.319 253.271 105.403 253.991 104.418 254.61C105.154 253.707 105.986 252.886 106.9 252.161C107.705 251.419 108.553 250.723 109.439 250.077L109.526 250.163C109.425 250.271 109.374 250.306 109.352 250.299Z'
          fill='#455A64'
        />
        <path
          d='M111.221 253.464C110.253 253.913 109.237 254.25 108.191 254.466C107.174 254.804 106.121 255.02 105.053 255.111C106.024 254.664 107.043 254.327 108.09 254.108C109.104 253.772 110.155 253.555 111.221 253.464Z'
          fill='#455A64'
        />
        <path
          d='M128.838 192.432L130.591 210.497H139.457L141.585 192.432H128.838Z'
          fill='#455A64'
        />
        <path
          d='M128.838 190.098C128.29 190.773 127.994 191.615 128.001 192.482H142.429C142.499 192.048 142.46 191.603 142.315 191.187C142.17 190.771 141.925 190.397 141.6 190.098H128.838Z'
          fill='#263238'
        />
        <path
          d='M130.476 188.444L129.992 190.227L140.864 190.313L140.15 188.423L130.476 188.444Z'
          fill='#263238'
        />
        <path
          d='M128.65 196.95C128.65 197.122 129.711 206.043 129.711 206.043H140.532L141.571 196.95H128.65Z'
          fill='#407BFF'
        />
        <path
          d='M68.3326 251.524C73.938 251.345 71.2976 243.182 71.1606 234.304C71.074 228.74 67.3443 223.277 63.2827 219.447C61.2176 217.635 58.7845 216.285 56.1479 215.487C49.1595 213.259 41.723 212.786 34.5054 214.113C34.5054 214.113 32.8245 252.633 68.3326 251.524Z'
          fill='#455A64'
        />
        <path
          d='M219.722 94.2614C220.696 94.216 221.664 94.4285 222.528 94.8771C222.927 95.068 223.362 95.173 223.805 95.185H224.945V80.865C224.945 80.2953 224.717 79.749 224.311 79.3462C223.905 78.9433 223.355 78.717 222.781 78.717H208.655V77.7719C208.66 77.3338 208.766 76.9027 208.966 76.5117C209.498 75.423 209.672 74.1957 209.463 73.0033C209.221 71.776 208.595 70.6558 207.674 69.8022C206.753 68.9486 205.584 68.4051 204.334 68.2491C198.151 67.3398 197.798 70.5904 197.798 73.9771C197.798 74.781 197.968 75.576 198.296 76.3113C198.464 76.6778 198.553 77.0755 198.555 77.4783V78.6884H184.798C184.224 78.6884 183.674 78.9147 183.268 79.3175C182.862 79.7203 182.634 80.2667 182.634 80.8364V118.591C182.635 118.821 182.681 119.049 182.771 119.262C182.861 119.474 182.992 119.667 183.157 119.829C183.321 119.992 183.517 120.12 183.732 120.207C183.946 120.295 184.176 120.339 184.408 120.338H223.185C223.417 120.339 223.647 120.295 223.862 120.207C224.076 120.12 224.272 119.992 224.437 119.829C224.601 119.667 224.732 119.474 224.822 119.262C224.912 119.049 224.958 118.821 224.959 118.591V104.987H223.387C223.024 104.989 222.664 105.057 222.326 105.188C221.639 105.465 220.904 105.609 220.162 105.61C219.365 105.619 218.574 105.466 217.839 105.16C217.104 104.854 216.439 104.402 215.887 103.832C215.334 103.262 214.904 102.586 214.625 101.845C214.345 101.104 214.221 100.314 214.261 99.524C214.351 98.1307 214.96 96.8206 215.969 95.8481C216.979 94.8755 218.316 94.31 219.722 94.2614Z'
          fill='#407BFF'
        />
        <g opacity='0.3'>
          <path
            d='M219.722 94.2614C220.696 94.216 221.664 94.4285 222.528 94.8771C222.927 95.0681 223.362 95.173 223.805 95.185H224.945V80.865C224.945 80.2953 224.717 79.749 224.311 79.3462C223.905 78.9433 223.355 78.717 222.781 78.717H208.655V77.7719C208.66 77.3338 208.766 76.9027 208.966 76.5118C209.498 75.423 209.672 74.1957 209.463 73.0033C209.212 71.757 208.568 70.6224 207.624 69.7638C206.679 68.9052 205.484 68.3671 204.211 68.2276C197.279 67.4687 197.798 70.5904 197.798 74.0057C197.798 74.8097 197.968 75.6047 198.296 76.3399C198.464 76.7065 198.553 77.1042 198.555 77.507V78.717H184.798C184.224 78.717 183.674 78.9433 183.268 79.3462C182.862 79.749 182.634 80.2953 182.634 80.865V118.62C182.635 118.85 182.681 119.078 182.771 119.29C182.861 119.503 182.992 119.696 183.157 119.858C183.321 120.02 183.517 120.149 183.732 120.236C183.946 120.323 184.176 120.368 184.408 120.367H223.185C223.417 120.368 223.647 120.323 223.862 120.236C224.076 120.149 224.272 120.02 224.437 119.858C224.601 119.696 224.732 119.503 224.822 119.29C224.912 119.078 224.958 118.85 224.959 118.62V105.016H223.387C223.024 105.018 222.664 105.086 222.326 105.216C221.639 105.494 220.904 105.637 220.162 105.639C219.362 105.648 218.569 105.494 217.832 105.186C217.095 104.879 216.429 104.424 215.876 103.851C215.322 103.278 214.894 102.598 214.616 101.854C214.338 101.11 214.217 100.317 214.261 99.524C214.351 98.1308 214.96 96.8206 215.969 95.8481C216.979 94.8755 218.316 94.31 219.722 94.2614Z'
            fill='black'
          />
        </g>
        <path
          d='M217.283 94.2614C218.257 94.216 219.226 94.4285 220.09 94.8771C220.489 95.068 220.924 95.173 221.367 95.185H222.499V80.865C222.499 80.2953 222.271 79.749 221.865 79.3461C221.46 78.9433 220.909 78.717 220.335 78.717H206.217V77.7719C206.223 77.3348 206.327 76.9045 206.52 76.5117C207.059 75.425 207.234 74.1955 207.018 73.0033C206.749 71.5801 205.957 70.3068 204.795 69.431C203.633 68.5551 202.185 68.1393 200.731 68.2643C199.278 68.3893 197.923 69.0461 196.93 70.1072C195.937 71.1683 195.378 72.5578 195.36 74.0057C195.357 74.81 195.526 75.6057 195.857 76.3399C196.026 76.7065 196.114 77.1041 196.117 77.507V78.717H182.36C181.786 78.717 181.235 78.9433 180.829 79.3461C180.423 79.749 180.195 80.2953 180.195 80.865V118.62C180.196 118.85 180.243 119.078 180.333 119.29C180.422 119.503 180.553 119.696 180.718 119.858C180.883 120.02 181.078 120.149 181.293 120.236C181.508 120.323 181.738 120.368 181.97 120.367H220.753C221.221 120.367 221.669 120.183 222.001 119.855C222.332 119.528 222.519 119.084 222.521 118.62V105.016H220.955C220.593 105.017 220.233 105.085 219.895 105.216C218.98 105.588 217.985 105.724 217.003 105.612C216.021 105.501 215.083 105.144 214.277 104.576C213.471 104.008 212.823 103.247 212.392 102.364C211.962 101.481 211.763 100.504 211.815 99.524C211.907 98.1301 212.518 96.8199 213.528 95.8475C214.539 94.8751 215.877 94.3098 217.283 94.2614Z'
          fill='#407BFF'
        />
        <path
          d='M226.597 105.037V118.698C226.597 119.268 226.825 119.814 227.231 120.217C227.637 120.62 228.187 120.846 228.761 120.846H267.032C267.502 120.846 267.952 120.662 268.285 120.333C268.617 120.004 268.805 119.558 268.807 119.092V80.6144C268.807 80.1473 268.62 79.6993 268.287 79.3689C267.954 79.0386 267.503 78.8531 267.032 78.8531H253.217V80.4139C253.218 80.7726 253.289 81.1277 253.426 81.4593C253.705 82.1418 253.85 82.8709 253.852 83.6073C253.853 84.4797 253.656 85.3412 253.276 86.128C252.896 86.9149 252.343 87.6071 251.658 88.1536C250.973 88.7001 250.173 89.087 249.317 89.2856C248.461 89.4843 247.571 89.4896 246.712 89.3014C245.854 89.1131 245.049 88.736 244.357 88.1978C243.666 87.6596 243.104 86.9742 242.715 86.192C242.325 85.4097 242.118 84.5508 242.108 83.6784C242.098 82.806 242.285 81.9424 242.656 81.1514C242.84 80.7689 242.939 80.3511 242.944 79.9271V78.8316H228.768C228.194 78.8316 227.644 79.0579 227.238 79.4607C226.832 79.8635 226.604 80.4099 226.604 80.9796V105.037H226.597Z'
          fill='#263238'
        />
        <path
          d='M237.959 84.0441C237.888 83.0591 238.074 82.0725 238.5 81.1801C238.683 80.7971 238.779 80.3793 238.782 79.9557V78.8602H224.62C224.046 78.8602 223.496 79.0865 223.09 79.4894C222.684 79.8922 222.456 80.4385 222.456 81.0082V95.0132H221.475C221.03 95.0107 220.59 94.9078 220.191 94.7125C219.303 94.2899 218.322 94.0957 217.339 94.1478C216.356 94.1999 215.401 94.4965 214.564 95.0105C213.726 95.5244 213.032 96.2391 212.546 97.0888C212.06 97.9386 211.796 98.8961 211.78 99.8732C211.764 100.85 211.996 101.816 212.455 102.681C212.913 103.546 213.583 104.282 214.403 104.823C215.224 105.364 216.168 105.691 217.149 105.775C218.13 105.858 219.116 105.696 220.018 105.302C220.389 105.132 220.792 105.041 221.201 105.037H222.427V118.698C222.427 119.268 222.655 119.814 223.061 120.217C223.467 120.62 224.018 120.846 224.591 120.846H262.863C263.331 120.846 263.781 120.662 264.112 120.333C264.444 120.004 264.63 119.558 264.63 119.092V80.6144C264.631 80.3835 264.586 80.1546 264.498 79.9409C264.409 79.7273 264.279 79.533 264.115 79.3694C263.951 79.2057 263.756 79.0759 263.541 78.9873C263.326 78.8987 263.095 78.8531 262.863 78.8531H249.062V80.4139C249.063 80.7719 249.131 81.1264 249.264 81.4593C249.634 82.3621 249.769 83.3427 249.657 84.3109C249.545 85.2791 249.19 86.2039 248.624 87.0002C248.058 87.7965 247.299 88.4389 246.418 88.8682C245.536 89.2975 244.56 89.5 243.579 89.457C242.139 89.3897 240.773 88.8024 239.738 87.8056C238.703 86.8088 238.07 85.4711 237.959 84.0441Z'
          fill='#455A64'
        />
        <path
          d='M436.537 325.551C436.537 325.651 335.394 325.737 210.661 325.737C85.9281 325.737 -15.229 325.651 -15.229 325.551C-15.229 325.451 85.892 325.365 210.661 325.365C335.43 325.365 436.537 325.443 436.537 325.551Z'
          fill='#263238'
        />
        <path
          d='M425.636 306.792C427.85 305.763 430.338 305.465 432.735 305.94C434.046 306.055 435.28 306.602 436.241 307.493C437.114 308.453 437.28 310.143 436.277 310.966C435.627 311.403 434.844 311.601 434.062 311.524C432.187 311.524 430.109 311.174 428.608 312.298C427.75 312.935 427.166 313.995 426.163 314.31C425.651 314.428 425.114 314.377 424.634 314.165C424.153 313.953 423.755 313.592 423.501 313.135C423.012 312.202 422.904 311.117 423.198 310.107C423.319 309.405 423.603 308.741 424.026 308.166C424.449 307.59 425 307.12 425.636 306.792Z'
          fill='#407BFF'
        />
        <path
          d='M420.665 297.319C421.986 299.188 424.554 298.572 425.715 297.426C426.877 296.281 427.403 294.627 427.88 293.052C428.926 289.472 429.979 285.655 429.2 281.982C429.008 280.867 428.508 279.826 427.757 278.975C427.38 278.555 426.898 278.24 426.36 278.063C425.822 277.886 425.246 277.852 424.691 277.965C423.392 278.302 422.592 279.562 422.007 280.758C420.299 284.292 419.402 288.158 419.381 292.078C419.381 293.896 419.591 295.808 420.629 297.319'
          fill='#407BFF'
        />
        <path
          d='M417.044 300.004C417.72 299.124 418.171 298.094 418.357 297.002C418.543 295.911 418.459 294.791 418.112 293.739C417.385 291.637 416.09 289.774 414.368 288.355C412.415 286.627 410.14 285.294 407.673 284.431C406.717 284.046 405.688 283.873 404.657 283.923C404.144 283.952 403.645 284.105 403.205 284.37C402.765 284.635 402.396 285.002 402.132 285.441C401.411 286.772 402.132 288.39 402.854 289.737C404.146 292.06 405.617 294.282 407.254 296.381C408.575 298.071 410.082 299.732 412.095 300.498C414.108 301.264 416.186 301.164 417.253 299.782'
          fill='#407BFF'
        />
        <path
          d='M420.586 325.551C420.654 325.271 420.692 324.986 420.701 324.699C420.766 324.076 420.839 323.303 420.932 322.379C421.048 321.235 421.224 320.097 421.459 318.971C421.767 317.601 422.185 316.258 422.707 314.954C423.216 313.65 423.911 312.425 424.77 311.317C425.472 310.42 426.363 309.686 427.382 309.169C428.073 308.817 428.815 308.575 429.582 308.453C429.871 308.439 430.159 308.398 430.441 308.331C430.15 308.302 429.858 308.302 429.568 308.331C428.773 308.392 427.996 308.593 427.274 308.925C426.201 309.422 425.258 310.157 424.518 311.073C423.614 312.196 422.885 313.446 422.354 314.782C421.824 316.105 421.411 317.47 421.12 318.863C420.897 320.004 420.743 321.156 420.658 322.315C420.586 323.295 420.557 324.097 420.543 324.649C420.522 324.95 420.536 325.253 420.586 325.551Z'
          fill='#263238'
        />
        <path
          d='M420.738 323.761C420.756 323.63 420.756 323.498 420.738 323.367V322.243C420.738 321.262 420.738 319.844 420.788 318.09C420.875 314.596 421.178 310.83 421.762 305.517C422.318 300.505 423.068 294.713 423.854 290.932C424.02 290.073 424.193 289.3 424.352 288.627C424.511 287.954 424.633 287.359 424.756 286.887C424.879 286.414 424.958 286.078 425.023 285.791C425.059 285.663 425.084 285.531 425.095 285.398C425.035 285.516 424.989 285.641 424.958 285.77C424.871 286.056 424.763 286.414 424.626 286.851C424.489 287.288 424.33 287.904 424.157 288.577C423.984 289.25 423.789 290.009 423.609 290.882C422.644 295.351 421.921 299.868 421.445 304.414C420.853 309.734 420.586 314.582 420.564 318.09C420.564 319.844 420.564 321.262 420.629 322.243C420.629 322.701 420.68 323.073 420.694 323.374C420.692 323.504 420.707 323.634 420.738 323.761Z'
          fill='#263238'
        />
        <path
          d='M420.759 314.417C420.794 314.024 420.794 313.629 420.759 313.236C420.716 312.484 420.615 311.388 420.399 310.042C420.135 308.476 419.786 306.924 419.352 305.395C418.842 303.513 418.186 301.672 417.39 299.889C416.026 296.6 414.07 293.585 411.619 290.989C411.193 290.544 410.74 290.126 410.263 289.736C410.053 289.565 409.873 289.393 409.686 289.257L409.159 288.892C408.844 288.657 408.511 288.446 408.163 288.262C409.314 289.148 410.408 290.105 411.439 291.126C413.806 293.747 415.707 296.749 417.058 300.004C417.839 301.776 418.497 303.599 419.028 305.46C419.479 306.975 419.852 308.511 420.146 310.064C420.391 311.395 420.528 312.477 420.615 313.228C420.635 313.628 420.683 314.025 420.759 314.417Z'
          fill='#263238'
        />
      </g>
      <defs>
        <clipPath id='clip0_1281_5470'>
          <rect width='448' height='358' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TeamVector;
