import React from 'react';

const LocationIcon = () => {
  return (
    <svg
      width='30'
      height='30'
      viewBox='0 0 30 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M15 15C15.5156 15 15.957 14.8164 16.3242 14.4492C16.6914 14.082 16.875 13.6406 16.875 13.125C16.875 12.6094 16.6914 12.1679 16.3242 11.8008C15.957 11.4336 15.5156 11.25 15 11.25C14.4844 11.25 14.043 11.4336 13.6758 11.8008C13.3086 12.1679 13.125 12.6094 13.125 13.125C13.125 13.6406 13.3086 14.082 13.6758 14.4492C14.043 14.8164 14.4844 15 15 15ZM15 24.375C12.4844 22.2344 10.6055 20.2461 9.3633 18.4101C8.12111 16.5742 7.50002 14.875 7.50002 13.3125C7.50002 10.9687 8.25392 9.10154 9.76173 7.71092C11.2695 6.32029 13.0156 5.62498 15 5.62498C16.9844 5.62498 18.7305 6.32029 20.2383 7.71092C21.7461 9.10154 22.5 10.9687 22.5 13.3125C22.5 14.875 21.8789 16.5742 20.6367 18.4101C19.3945 20.2461 17.5156 22.2344 15 24.375Z'
        fill='#CCCDD1'
      />
      <rect
        x='0.4'
        y='0.4'
        width='29.2'
        height='29.2'
        rx='14.6'
        stroke='#CCCDD1'
        strokeWidth='0.8'
      />
    </svg>
  );
};

export default LocationIcon;
