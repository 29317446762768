import React from 'react';
import './AbhisharCard.css';

function AbhisharCard2({ image, heading, version, link }) {
  const openAbhishar = () => {
    window.open(link, '_blank');
  };

  return (
    <div className='abhisharCard'>
      <img src={image} alt='abhishar' className='abhisharCoverImage' />

      <div className='abhisharCardContent'>
        <div className='abhisharCardText abhisharVersion'>
          Abhishar {version}
        </div>

        <div className='abhisharCardText abhisharCardHeading'>{heading}</div>
        <button onClick={openAbhishar} className='abhisharReadLink'>
          Read →
        </button>
      </div>
    </div>
  );
}

export default AbhisharCard2;
