import React, { useEffect } from 'react';
import ContactUsInnerBox from '../../components/ContactUsInnerBox/ContactUsInnerBox';
import './contactScreen.css';

function ContactScreen() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className='contact-us-page'>
      <div className='google-map-button secondary-font'>
        <a
          href='https://goo.gl/maps/dHcmT7yUY4gAoNiJ6'
          target='_blank'
          rel='noreferrer'
        >
          look at google maps
        </a>
      </div>
      <div>
        <ContactUsInnerBox />
      </div>
    </div>
  );
}

export default ContactScreen;
