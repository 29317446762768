import React, { useEffect, useState } from 'react';
import './teamScreen.css';
import TeamVector from '../../assets/svgIcons/teamvector';
import TeamMembers from '../../components/TeamMembers/TeamMembers';

function TeamScreen() {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 900);
  const [isFoldView, setIsFoldView] = useState(window.innerWidth < 350);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 900);
      setIsFoldView(window.innerWidth < 350);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <>
      <div className='team-page-container'>
        <div className='team-container flex-centre'>
          <div className='team-page-text-container'>
            <div className='team-header-text heading-font'>
              Meet Our Core Team
            </div>
            <div className='team-text secondary-font'>
              The core team of AASF plays a pivotal role in students&apos;
              technical journeys by offering hIQs, sessions, and other
              managerial events to impart practical knowledge, while also
              fostering collaboration through contests and hackathons. We help
              students refine their technical skills, stay updated with industry
              trends, and encourage innovative thinking, thereby equipping them
              to excel in their chosen technical fields.
            </div>
          </div>
          <div className='vector-container'>
            {/* {isMobileView ? (
              <TeamVector width={328} height={262} />
            ) : (
              // <TeamVector width={48} height={38} />
              <TeamVector width={488} height={358} />
            )} */}
            {isMobileView && !isFoldView && (
              <TeamVector width={328} height={262} />
            )}
            {isFoldView && <TeamVector width={250} height={242} />}
            {!isMobileView && !isFoldView && (
              <TeamVector width={488} height={358} />
            )}
          </div>
        </div>
      </div>
      <TeamMembers />
    </>
  );
}

export default TeamScreen;
