import React from 'react';
import './homeScreen.css';
import '../.././index.css';
import ToTop from '../../components/ToTop';
import AASFSection from '../../components/AASFSectionHome/AASFSectionHome';
import AbhisharSection from '../../components/AbhisharSectionHome/AbhisharSectionHome';
import EventsSection from '../../components/EventsSectionHome/EventsSectionHome';
import HeroSection from '../../components/HeroSection/HeroSection';

function HomeScreen() {
  return (
    <div>
      <ToTop />
      <HeroSection />
      <AASFSection />
      <EventsSection />
      <AbhisharSection />
    </div>
  );
}

export default HomeScreen;
