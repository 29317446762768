import React from 'react';
import './ContactUsForm.css';

import { useState } from 'react';

function ContactUsForm() {
  const [name, setName] = useState('');
  const [message, setMessage] = useState('');

  const mailTo =
    'mailto:aasf.iiitmg@gmail.com?subject=' + name + '&body=' + message;

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  return (
    <div className='contact-us-form-container'>
      <form className='contact-us-form' onSubmit={handleSubmit}>
        <div className='contact-us-title'>
          <div className='contact-us-title-heading'>Get in touch</div>
          <div className='contact-us-title-motto primary-font'>
            We&apos;re always there to help you!
          </div>
        </div>
        <input
          type='text'
          id='name'
          name='name'
          placeholder='Enter your name'
          onChange={(event) => setName(event.target.value)}
        ></input>

        <input
          type='text'
          id='email'
          name='email'
          placeholder='Enter your email'
        ></input>

        <textarea
          id='message'
          name='message'
          placeholder='Message'
          onChange={(event) => setMessage(event.target.value)}
        ></textarea>

        <div className='contact-form-footer'>
          <div className='contact-submit'>
            <a href={mailTo}>
              <div className='send-message-button'>Send</div>
            </a>
          </div>
        </div>
      </form>
    </div>
  );
}

export default ContactUsForm;
