import React from 'react';
import './HeroSection.css';
import { Link } from 'react-router-dom';
import react from '../../assets/home-icons/react.png';
import js from '../../assets/home-icons/js.png';
import github from '../../assets/home-icons/github.png';
import solidity from '../../assets/home-icons/solidity-icon.png';
import firebase from '../../assets/home-icons/firebase.png';
import linux from '../../assets/home-icons/linux.png';
import html from '../../assets/home-icons/html.png';
import c from '../../assets/home-icons/c.png';
import css from '../../assets/home-icons/css.png';
import figma from '../../assets/home-icons/figma.png';
import excel from '../../assets/home-icons/excel.png';
import node from '../../assets/home-icons/node.png';
import bootstrap from '../../assets/home-icons/bootstrap.png';
import vscode from '../../assets/home-icons/vscode.png';
import next from '../../assets/home-icons/next.png';
import python from '../../assets/home-icons/python.png';
import photoshop from '../../assets/home-icons/photoshop.png';
import netlify from '../../assets/home-icons/netlify.png';
import npm from '../../assets/home-icons/npm.png';
import webpack from '../../assets/home-icons/webpack.png';

function HeroSection() {
  return (
    <div className='home-container'>
      <div className='home-left-container'>
        <div className='home-text-container '>
          <div className='home-header-text-container'>
            <div className='home-college-name'>ABV - IIITM Gwalior</div>

            <div className='home-aasf-title'>
              Abhigyan Abhikaushalam Students&apos; Forum
            </div>
          </div>
          <div className='home-about-aasf'>
            AASF works to conjugate knowledge and skills into a single bunch.
            The forum also aims to inculcate in the students the spirit of
            excellence in every field along with promoting innovative ideas.
          </div>
        </div>

        <div className='home-button-container'>
          <Link exact to='/events'>
            <div className='events-button'>Explore Events</div>
          </Link>
          <Link exact to='/contact'>
            <div className='contact-us-button btn-skew'>Contact Us</div>
          </Link>
        </div>
      </div>
      <div className='home-right-container'>
        <ul className='home-icon-container row-1'>
          {' '}
          <li className='icon-holder'>
            {' '}
            <img className='skills-icon' src={firebase} alt='' />
          </li>
          <li className='icon-holder'>
            {' '}
            <img className='skills-icon' src={linux} alt='' />
          </li>
        </ul>
        <ul className='home-icon-container'>
          <li className='icon-holder'>
            <img className='skills-icon' src={react} alt='' />
          </li>
          <li className='icon-holder'>
            {' '}
            <img className='skills-icon' src={js} alt='' />
          </li>
          <li className='icon-holder'>
            <img className='skills-icon' src={github} alt='' />
          </li>
          <li className='icon-holder'>
            <img className='skills-icon' src={solidity} alt='' />
          </li>
          <li className='icon-holder'>
            <img className='skills-icon' src={html} alt='' />
          </li>
        </ul>
        <ul className='home-icon-container'>
          <li className='icon-holder'>
            {' '}
            <img className='skills-icon' src={bootstrap} alt='' />
          </li>
          <li className='icon-holder'>
            {' '}
            <img className='skills-icon' src={c} alt='' />
          </li>
          <li className='icon-holder'>
            {' '}
            <img className='skills-icon' src={figma} alt='' />
          </li>
          <li className='icon-holder'>
            {' '}
            <img className='skills-icon' src={next} alt='' />
          </li>
          <li className='icon-holder'>
            {' '}
            <img className='skills-icon' src={netlify} alt='' />
          </li>
        </ul>
        <ul className='home-icon-container'>
          <li className='icon-holder'>
            {' '}
            <img className='skills-icon' src={webpack} alt='' />
          </li>
          <li className='icon-holder'>
            {' '}
            <img className='skills-icon' src={css} alt='' />
          </li>
          <li className='icon-holder'>
            {' '}
            <img className='skills-icon' src={npm} alt='' />
          </li>
          <li className='icon-holder'>
            {' '}
            <img className='skills-icon' src={photoshop} alt='' />
          </li>
          <li className='icon-holder'>
            {' '}
            <img className='skills-icon' src={vscode} alt='' />
          </li>
        </ul>
        <ul className='home-icon-container'>
          <li className='icon-holder'>
            {' '}
            <img className='skills-icon' src={node} alt='' />
          </li>
          <li className='icon-holder'>
            {' '}
            <img className='skills-icon' src={python} alt='' />
          </li>
          <li className='icon-holder'>
            {' '}
            <img className='skills-icon' src={excel} alt='' />
          </li>
        </ul>
      </div>
    </div>
  );
}

export default HeroSection;
