import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import JAMs from '../../assets/Images/oratoryMobile.png';
import JAM from '../../assets/Images/JAM.jpg';
import UI from '../../assets/Images/UI02.jpg';
import Medium from '../../assets/Images/medium.jpg';
import MediumS from '../../assets/Images/mediumS.jpeg';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './EventHeader.css';

const EventHeader = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Carousel
      autoPlay
      infiniteLoop
      showArrows={false}
      interval={3000}
      showStatus={false}
      showIndicators={false}
    >
      <div>
        <div className='event-header-container'>
          <div className='eventFirst'>
            <h1 className='vertical-font'>Oratory</h1>
          </div>
          <div className='eventSecond'>
            <h1 className='vertical-font'>Literary</h1>
          </div>
          <div className='eventThird'>
            <h1 className='vertical-font'>Managerial</h1>
          </div>
          <div className='imageDiv'>
            <div className='eventForth active'>
              <h1 className='vertical-font'>Technical</h1>
            </div>
            <div className='eventPic'>
              <img src={screenWidth > 600 ? UI : UI} alt='' />
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className='event-header-container'>
          <div className='eventFirst'>
            <h1 className='vertical-font'>Oratory</h1>
          </div>
          <div className='eventSecond'>
            <h1 className='vertical-font'>Literary</h1>
          </div>
          <div className='imageDiv'>
            <div className='eventThird active'>
              <h1 className='vertical-font'>Managerial</h1>
            </div>
            <div className='eventPic'>
              <img src={screenWidth > 600 ? UI : UI} alt='' />
            </div>
          </div>
          <div className='eventForth'>
            <h1 className='vertical-font'>Technical</h1>
          </div>
        </div>
      </div>

      <div>
        <div className='event-header-container'>
          <div className='eventFirst'>
            <h1 className='vertical-font'>Oratory</h1>
          </div>
          <div className='imageDiv'>
            <div className='eventSecond active'>
              <h1 className='vertical-font'>Literary</h1>
            </div>

            <div className='eventPic'>
              <img src={screenWidth > 600 ? Medium : MediumS} alt='' />
            </div>
          </div>

          <div className='eventThird'>
            <h1 className='vertical-font'>Managerial</h1>
          </div>
          <div className='eventForth'>
            <h1 className='vertical-font'>Technical</h1>
          </div>
        </div>
      </div>

      <div>
        <div className='event-header-container'>
          <div className='imageDiv'>
            <div className='eventFirst active'>
              <h1 className='vertical-font'>Oratory</h1>
            </div>
            <div className='eventPic'>
              <img src={screenWidth > 600 ? JAM : JAMs} alt='' />
            </div>
          </div>

          <div className='eventSecond'>
            <h1 className='vertical-font'>Literary</h1>
          </div>
          <div className='eventThird'>
            <h1 className='vertical-font'>Managerial</h1>
          </div>
          <div className='eventForth'>
            <h1 className='vertical-font'>Technical</h1>
          </div>
        </div>
      </div>
    </Carousel>
  );
};

export default EventHeader;
