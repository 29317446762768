import React, { useState } from 'react';
import './FlagshipEventCard.css';

function NewEventCard({ thumbnail, name, tags, eventDetails }) {
  const [showFullContent, setShowFullContent] = useState(false);

  const toggleContent = () => {
    setShowFullContent(!showFullContent);
  };

  const maxWordsToShow = 10;

  return (
    <div className='flagship-event-card'>
      <div className='flagship-event-image-container'>
        <img className='flagship-event-image' src={thumbnail} alt='' />
      </div>

      <div className='flagship-event-details-container'>
        <div className='flagship-event-details-header'>{name}</div>
        <div className='flagship-event-type-container'>
          {Array.isArray(tags) ? (
            tags.map((tag) => (
              <div
                className={
                  tag === 'Technical'
                    ? 'flagship-event-type'
                    : tag === 'Literary'
                      ? 'flagship-event-type-2'
                      : tag === 'Managerial'
                        ? 'flagship-event-type-3'
                        : ''
                }
                key={tag}
              >
                {tag}
              </div>
            ))
          ) : (
            <div className='flagship-event-type'>{tags}</div>
          )}
        </div>
        {window.innerWidth > 550 ? (
          <div className='flagship-event-details'>{eventDetails}</div>
        ) : showFullContent ? (
          <div className='flagship-event-details'>{eventDetails}</div>
        ) : (
          <p className='flagship-event-details'>
            {eventDetails.split(' ').slice(0, maxWordsToShow).join(' ')}
            {eventDetails.split(' ').length > maxWordsToShow && '...'}
          </p>
        )}
        {window.innerWidth < 550 && (
          <button onClick={toggleContent} className='read-more-button'>
            {showFullContent ? 'Read Less' : 'Read More >>'}
          </button>
        )}
      </div>
    </div>
  );
}

export default NewEventCard;
