import React from 'react';
import './marquee.css';

const Marquee = () => {
  const marqueeItems = [
    'You can submit your articles for Abhishar v13.0 via mail at aasf@iiitm.ac.in',
    'Participate and make your Open Source contributions at git-init-foss.vercel.app',
    'You can submit your entries for Lifescape via mail at aasf@iiitm.ac.in',
  ];

  return (
    <div className='marquee-heading'>
      <marquee behavior='scroll' direction='left' width='100%'>
        {marqueeItems.map((item, index) => (
          <span className='marquee-items' key={index}>
            {item}
          </span>
        ))}
      </marquee>
    </div>
  );
};

export default Marquee;
