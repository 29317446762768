import React from 'react';

const MailIcon = () => {
  return (
    <svg
      width='30'
      height='30'
      viewBox='0 0 30 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g filter='url(#filter0_d_1258_499)' transform='translate(-4)'>
        <path
          d='M12.3334 21.6667C11.875 21.6667 11.4827 21.5035 11.1563 21.1771C10.8299 20.8507 10.6667 20.4584 10.6667 20V10C10.6667 9.54168 10.8299 9.14932 11.1563 8.82293C11.4827 8.49655 11.875 8.33335 12.3334 8.33335H25.6667C26.125 8.33335 26.5174 8.49655 26.8438 8.82293C27.1702 9.14932 27.3334 9.54168 27.3334 10V20C27.3334 20.4584 27.1702 20.8507 26.8438 21.1771C26.5174 21.5035 26.125 21.6667 25.6667 21.6667H12.3334ZM19 15.8334L25.6667 11.6667V10L19 14.1667L12.3334 10V11.6667L19 15.8334Z'
          fill='#CCCDD1'
        />
        <rect
          x='4.4'
          y='0.4'
          width='29.2'
          height='29.2'
          rx='14.6'
          stroke='#CCCDD1'
          strokeWidth='0.8'
          shapeRendering='crispEdges'
        />
      </g>
      <defs>
        <filter
          id='filter0_d_1258_499'
          x='0'
          y='0'
          width='38'
          height='38'
          filterUnits='userSpaceOnUse'
          colorInterpolation-filters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='4' />
          <feGaussianBlur stdDeviation='2' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_1258_499'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_1258_499'
            result='shape'
          />
        </filter>
      </defs>
    </svg>
  );
};

export default MailIcon;
