import React from 'react';

const CloseVector = () => {
  return (
    <svg
      width='12'
      height='12'
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        id='Vector'
        d='M13.259 12.3468C13.319 12.4067 13.3665 12.4778 13.3989 12.5561C13.4313 12.6344 13.448 12.7183 13.448 12.803C13.448 12.8877 13.4313 12.9716 13.3989 13.0499C13.3665 13.1281 13.319 13.1993 13.259 13.2592C13.1991 13.3191 13.128 13.3666 13.0498 13.399C12.9715 13.4314 12.8876 13.4481 12.8029 13.4481C12.7182 13.4481 12.6343 13.4314 12.556 13.399C12.4777 13.3666 12.4066 13.3191 12.3467 13.2592L7 7.91166L1.65329 13.2592C1.53231 13.3802 1.36822 13.4481 1.19712 13.4481C1.02603 13.4481 0.861938 13.3802 0.740954 13.2592C0.61997 13.1382 0.552002 12.9741 0.552002 12.803C0.552002 12.6319 0.61997 12.4678 0.740954 12.3468L6.08846 7.00012L0.740954 1.65342C0.61997 1.53243 0.552002 1.36834 0.552002 1.19725C0.552002 1.02615 0.61997 0.86206 0.740954 0.741076C0.861938 0.620092 1.02603 0.552124 1.19712 0.552124C1.36822 0.552124 1.53231 0.620092 1.65329 0.741076L7 6.08859L12.3467 0.741076C12.4677 0.620092 12.6318 0.552124 12.8029 0.552124C12.974 0.552124 13.1381 0.620092 13.259 0.741076C13.38 0.86206 13.448 1.02615 13.448 1.19725C13.448 1.36834 13.38 1.53243 13.259 1.65342L7.91153 7.00012L13.259 12.3468Z'
        fill='white'
      />
    </svg>
  );
};

export default CloseVector;
