import React from 'react';
import Construction from '../../components/construction/construction';

function BlogsScreen() {
  return (
    <div>
      <Construction />
    </div>
  );
}

export default BlogsScreen;
