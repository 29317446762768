import React from 'react';

const CallIcon = () => {
  return (
    <svg
      width='30'
      height='30'
      viewBox='0 0 30 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M21.625 22.5C19.8333 22.5 18.0868 22.1007 16.3854 21.3021C14.684 20.5035 13.1771 19.4479 11.8646 18.1354C10.5521 16.8229 9.49654 15.316 8.69793 13.6146C7.89932 11.9132 7.50002 10.1667 7.50002 8.375C7.50002 8.125 7.58335 7.91667 7.75002 7.75C7.91668 7.58333 8.12502 7.5 8.37502 7.5H11.75C11.9445 7.5 12.1181 7.5625 12.2708 7.6875C12.4236 7.8125 12.5139 7.97222 12.5417 8.16667L13.0833 11.0833C13.1111 11.2778 13.1077 11.4549 13.0729 11.6146C13.0382 11.7743 12.9583 11.9167 12.8333 12.0417L10.8333 14.0833C11.4167 15.0833 12.1458 16.0208 13.0208 16.8958C13.8958 17.7708 14.8611 18.5278 15.9167 19.1667L17.875 17.2083C18 17.0833 18.1632 16.9896 18.3646 16.9271C18.566 16.8646 18.7639 16.8472 18.9583 16.875L21.8333 17.4583C22.0278 17.5 22.1875 17.5938 22.3125 17.7396C22.4375 17.8854 22.5 18.0556 22.5 18.25V21.625C22.5 21.875 22.4167 22.0833 22.25 22.25C22.0833 22.4167 21.875 22.5 21.625 22.5Z'
        fill='#CCCDD1'
      />
      <rect
        x='0.4'
        y='0.4'
        width='29.2'
        height='29.2'
        rx='14.6'
        stroke='#CCCDD1'
        strokeWidth='0.8'
      />
    </svg>
  );
};

export default CallIcon;
