import React from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import AASFLogo from '../../assets/svgIcons/aasflogo';
import * as FaIcons from 'react-icons/fa';
import './footer.css';

function Footer() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const handleWindowResize = () => {
    setWindowWidth(window.innerWidth);
  };

  return (
    <div>
      <div className='footer'>
        <div className='footer-container secondary-font'>
          <div className='footer-address-container'>
            <div className='footer-address-Image'>
              {windowWidth >= 600 ? (
                <AASFLogo width={87} height={100} />
              ) : (
                <AASFLogo width={58} height={55.23} />
              )}
            </div>
            <div className='footer-address-text'>
              <div className='text'>Address: E-116,</div>
              <div className='text'>ABV-IIITM Gwalior 474015,</div>
              <div className='text'> Madhya Pradesh</div>
            </div>
          </div>
          <div className='footer-menu-container'>
            <div className='footer-menu'>
              <div className='footer-menu-heading'>
                <b>Explore</b>
              </div>
              <div className='footer-menu-items'>
                <Link exact to='/'>
                  Home
                </Link>
                <Link exact to='/abhishar'>
                  Abhishar
                </Link>
                <Link exact to='/events'>
                  Events
                </Link>
                <Link exact to='/team'>
                  Team
                </Link>
              </div>
            </div>

            <div className='footer-menu'>
              <div className='footer-menu-heading'>
                <b>Help</b>
              </div>
              <div className='footer-menu-items'>
                <a href='mailto:aasf@iiitm.ac.in'>aasf@iiitm.ac.in</a>
                <a href='mailto:aasf.iiitmg@gmail.com'>aasf.iiitmg@gmail.com</a>
                <Link exact to='/contact'>
                  Contact us
                </Link>
              </div>
            </div>

            <div className='footer-menu'>
              <div className='footer-menu-heading'>
                <b>Socials</b>
              </div>
              <div className='footer-socialmedia-icons'>
                <a href='https://www.linkedin.com/company/aasf-iiitmg/posts/'>
                  <FaIcons.FaLinkedinIn></FaIcons.FaLinkedinIn>
                </a>
                <a href='https://www.instagram.com/aasf_iiitmg/'>
                  <FaIcons.FaInstagram></FaIcons.FaInstagram>
                </a>
                <a href='https://www.facebook.com/aasfiiitm/'>
                  <FaIcons.FaFacebook></FaIcons.FaFacebook>
                </a>
                <a href='https://medium.com/tech-iiitg'>
                  <FaIcons.FaMedium></FaIcons.FaMedium>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
